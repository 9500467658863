<div
  class="tw-w-11/12 tw-text-white tw-bg-gradient-to-br tw-from-purple-600/75 dark:tw-to-blue-600/75 tw-to-blue-600/85  tw-rounded-lg tw-shadow lg:tw-max-w-5xl tw-p-6 sm:tw-p-8 tw-mt-4 tw-mb-8">
  <label
    for="prompt"
    class="tw-text-white tw-mb-2 tw-font-medium tw-text-base tw-flex tw-items-center tw-gap-2">
    <span
      class="tw-inline-flex tw-items-center tw-rounded-md tw-leading-4 tw-text-sm tw-font-semibold tw-text-blue-300 tw-ring-1 tw-ring-inset tw-ring-blue-300 dark:tw-bg-transparent dark:tw-text-primary_purple-500 dark:tw-ring-primtw-text-primary_purple-500/50 tw-px-2 tw-py-1">
      beta
    </span>
    Use the prompt to generate people filters:
  </label>
  <div class="tw-flex tw-items-center tw-gap-4 tw-flex-wrap md:tw-flex-nowrap tw-py-2">
    <textarea
      matInput
      [(ngModel)]="prompt"
      id="prompt"
      rows="1"
      class="tw-block tw-p-2.5 tw-w-full tw-text-sm tw-text-gray-900 tw-bg-white tw-rounded-lg tw-border tw-border-gray-300 dark:tw-bg-gray-800 dark:tw-text-white"
      placeholder="E.g., find all engineers who worked at Google..."></textarea>

    <div class="tw-flex tw-gap-2">
      <button
        mat-stroked-button
        color="primary"
        class="gradient-stroked-btns  tw-border tw-border-solid tw-border-white tw-text-white tw-shrink-0"
        [class.spinner]="prompting"
        [disabled]="prompting"
        (click)="chat()">
        Ask
        <mat-icon class="tw-text-base tw-rotate-[-30deg] tw-pb-[7px]">send</mat-icon>
      </button>
      <button
        mat-stroked-button
        class="gradient-stroked-btns tw-shrink-0 tw-border-white tw-text-white"
        (click)="chatFeedback()"
        [ngClass]="{ 'tw-opacity-50': !assessOption, 'tw-opacity-100': assessOption }"
        (click)="chatFeedback()">
        Assess Results
        <mat-icon class="tw-text-base">thumbs_up_down</mat-icon>
      </button>
    </div>
  </div>
</div>
