import { Component, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import {
  AdministrativeService,
  CreateEmployeeRoleRequestInner,
  DeleteEmployeeRolesRequest,
} from 'ldt-identity-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { AddEmployeeRoleDialogComponent } from '../add-role-dialog/add-role-dialog.component';

@Component({
  selector: 'app-service-role-renderer',
  templateUrl: './service-role-renderer.component.html',
  styleUrls: ['./service-role-renderer.component.scss'],
})
export class ServiceRoleRendererComponent implements OnInit, ICellRendererAngularComp {
  roles: any[];
  colors: any = {
    1: 'warn',
    2: 'accent',
    3: 'none',
  };
  employeeId: string;
  params: any;

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.roles = params.data.serviceRoles;
    this.employeeId = params.data.id;
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams) {
    this.roles = params.data.serviceRoles;
    return true;
  }

  constructor(
    private adminService: AdministrativeService,
    private dialog: MatDialog,
    private notify: NotificationService
  ) {}

  ngOnInit(): void {}

  removeRole(role: any) {
    let body: DeleteEmployeeRolesRequest = {
      employeeId: this.employeeId,
      serviceId: role.serviceId,
    };
    this.adminService.deleteEmployeeRoles(body).subscribe(
      () => {
        this.notify.success('Role removed');
        this.params.updateEmployees();
      },
      () => {
        this.notify.error('Error removing role');
      }
    );
  }

  showCreateDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    const dialogRef = this.dialog.open(AddEmployeeRoleDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      let body: CreateEmployeeRoleRequestInner = {
        employeeId: this.employeeId,
        serviceId: data.serviceId,
        role: data.roleId,
      };
      this.adminService.createEmployeeRole([body]).subscribe(
        () => {
          this.notify.success('Role granted');
          this.params.updateEmployees();
        },
        () => {
          this.notify.error('Error granting service role');
        }
      );
    });
  }
}
