import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import {
  AdminService,
  ListAllReportDeliveriesRequest,
  ReportDelivery,
} from 'ldt-data-deliveries-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';

@Component({
  selector: 'app-report-deliveries',
  templateUrl: './report-deliveries.component.html',
  styleUrls: ['./report-deliveries.component.scss'],
})
export class ReportDeliveriesComponent implements OnInit {
  refreshing: boolean = false;
  reportDeliveries: ReportDelivery[] = [];
  public colDefs: ColDef[] = [
    { field: 'id', maxWidth: 60 },
    { field: 'org_id', maxWidth: 110, filter: 'agSetColumnFilter' },
    { field: 's3_url', headerName: 'Delivered To' },
    { field: 'created_at', maxWidth: 230 },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    floatingFilter: true,
    resizable: true,
    flex: 1,
    filter: 'agTextColumnFilter',
  };

  constructor(
    private reportDeliveriesService: AdminService,
    private notify: NotificationService,
    public darkModeService: DarkModeService
  ) {}

  ngOnInit(): void {
    this.getReportDeliveries();
  }

  getReportDeliveries(): void {
    this.refreshing = true;
    let req: ListAllReportDeliveriesRequest = {
      size: 1000,
    };
    this.reportDeliveriesService.listAllReportDeliveries(req).subscribe({
      next: (data) => {
        this.reportDeliveries = data.items || [];
        this.refreshing = false;
        if (data.pagination_token) {
          this.notify.warning('Only first 1000 destinations are displayed, but there are more!');
        }
      },
      error: (error) => {
        this.notify.error('Error fetching report deliveries');
        this.refreshing = false;
      },
    });
  }
}
