<div class="container" *ngIf="rowData; else elseBlock">
  <div *ngFor="let c of v1Data">
    {{c.id}}
  </div>

  <ag-grid-angular
    style="height: 100%; width: 100%"
    [ngClass]="{ 'ag-theme-alpine': !(darkModeService.darkMode$ | async), 'ag-theme-alpine-dark': darkModeService.darkMode$ | async }"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [sideBar]="sideBar"
    [defaultColDef]="defaultColDef">
  </ag-grid-angular>
</div>
<ng-template #elseBlock> Loading... </ng-template>
