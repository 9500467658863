<div
  [ngClass]="'job-fields-' + view"
  *ngIf="(jobsList && jobsList.length > 0) && (view == 'normal' || !selectedJob)">
  <table mat-table [dataSource]="jobsDatasource">
    <ng-container matColumnDef="company_name">
      <th mat-header-cell *matHeaderCellDef>Company</th>
      <td mat-cell *matCellDef="let element" class="tw-leading-[22px]">
        <a
          *ngIf="element.company_linkedin; else valueOnly"
          href="https://linkedin.com/company/{{element.company_linkedin}}"
          target="_blank">
          {{element.company_name}}
        </a>
        <ng-template #valueOnly>{{element.company_name || element.company?.name}}</ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Title</th>
      <td mat-cell *matCellDef="let element">{{element.title}}</td>
    </ng-container>

    <ng-container matColumnDef="started_at">
      <th date-col mat-header-cell *matHeaderCellDef>Started</th>
      <td mat-cell *matCellDef="let element">
        {{element.started_at | date: element.metadata?.started_at_year_only ? 'yyyy' : 'MMM yyyy': 'UTC'}}
        {{element.metadata?.started_at_inferred || element.metadata?.StartedAtInferred ? '*' : ''}}
      </td>
    </ng-container>

    <ng-container matColumnDef="ended_at">
      <th date-col mat-header-cell *matHeaderCellDef>Ended</th>
      <td mat-cell *matCellDef="let element">
        {{element.ended_at | date: element.metadata?.ended_at_year_only ? 'yyyy' : 'MMM yyyy': 'UTC'}}
        {{element.metadata?.ended_at_inferred || element.metadata?.EndedAtInferred ? '*' : ''}}
      </td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th
        date-col
        mat-header-cell
        *matHeaderCellDef
        matTooltip="Was this job created in the last 24 hours?">
        New?
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.newly_created" matTooltip="Created in the last 24 hours">
          grade
        </mat-icon>
        <mat-icon
          *ngIf="element.email && element.email_status =='valid'"
          matTooltip="Valid email exists">
          mark_email_read
        </mat-icon>
        <mat-icon
          *ngIf="element.email && element.email_status =='catch-all'"
          matTooltip="Catch-all email exists">
          mail
        </mat-icon>
        <mat-icon
          *ngIf="element.email && element.email_status =='best-guess'"
          matTooltip="Best-guess email exists">
          mark_email_unread
        </mat-icon>
        <mat-icon *ngIf="element.direct_dial_number" matTooltip="Direct dial exists">call</mat-icon>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="jobColumns"
      [ngStyle]="{'display': view === 'compact' ? 'none' : 'contents'}"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: jobColumns;"
      (click)="selectedJob=row"
      [class.selected-row]="row == selectedJob"></tr>
  </table>
  <div *ngIf="view !== 'compact'">(* = inferred)</div>
  <div *ngIf="view === 'compact' && jobsList && jobsList.length > 3">
    and {{jobsList.length - 3}} more...
  </div>
</div>
<div class="detailsPanel" *ngIf="selectedJob">
  <div class="jobDetails">
    <div class="jobDetailColumn">
      <li><strong>Location:</strong> {{selectedJob.location}}</li>
      <li><strong>Email:</strong> {{selectedJob.email}}</li>
      <li><strong>Email Status:</strong> {{selectedJob.email_status}}</li>
      <li><strong>Direct Dial:</strong> {{selectedJob.direct_dial_number}}</li>
    </div>
    <div class="jobDetailColumn">
      <li><strong>Title:</strong> {{selectedJob.title}}</li>
      <li><strong>Level:</strong> {{selectedJob.level}}</li>
      <li><strong>Function:</strong> {{selectedJob.function}}</li>
    </div>
    <div class="jobDetailColumn">
      <li>
        <strong>Company:</strong>&nbsp;
        <a
          *ngIf="selectedJob.company_linkedin || selectedJob.company?.linkedin; else jobCompanyValueOnly"
          href="https://linkedin.com/company/{{selectedJob.company_linkedin || selectedJob.company?.linkedin}}"
          target="_blank">
          {{selectedJob.company_name || selectedJob.company.name}}
        </a>
        <ng-template #jobCompanyValueOnly>
          {{selectedJob.company_name || selectedJob.company.name}}
        </ng-template>
      </li>
      <li *ngIf="selectedJob.company_lookup">
        <strong>Lookup:</strong> {{selectedJob.company_lookup}}
      </li>
    </div>
  </div>
  <a class="backLink" (click)="selectedJob=null">Back</a>
</div>
