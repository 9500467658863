<div class="statContainer" *ngIf="!loading; else elseBlock">
  <mat-card>
    <mat-card-title>Total People</mat-card-title>
    <mat-card-content class="bannerNumber">
      {{total_hits | number}}
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Have Email</mat-card-title>
    <mat-card-content>
      <span class="bannerNumber">{{exists_position_email | number}}</span>
      ({{100 * exists_position_email / total_hits | number:'1.0-0'}}%)
      <div class="smallChart">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="top_email_status_chart_options"
          oneToOne="true"></highcharts-chart>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Have Title</mat-card-title>
    <mat-card-content>
      <span class="bannerNumber">{{exists_position_title | number}}</span>
      ({{100 * exists_position_title / total_hits | number:'1.0-0'}}%)
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Have Country</mat-card-title>
    <mat-card-content>
      <span class="bannerNumber">{{exists_country | number}}</span>
      ({{100 * exists_country / total_hits | number:'1.0-0'}}%)
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Have Location</mat-card-title>
    <mat-card-content>
      <span class="bannerNumber">{{exists_location | number}}</span>
      ({{100 * exists_location / total_hits | number:'1.0-0'}}%)
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Have Education</mat-card-title>
    <mat-card-content>
      <span class="bannerNumber">{{exists_education | number}}</span>
      ({{100 * exists_education / total_hits | number:'1.0-0'}}%)
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Have Start Date</mat-card-title>
    <mat-card-content>
      <span class="bannerNumber">{{exists_startdate | number}}</span>
      ({{100 * exists_startdate / total_hits | number:'1.0-0'}}%)
      <div class="subNumber">Inferred: {{inferred_startdate | number}}</div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Have Cell Phone</mat-card-title>
    <mat-card-content>
      <span class="bannerNumber">{{exists_cell_phone | number}}</span>
      ({{100 * exists_cell_phone / total_hits | number:'1.0-0'}}%)
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Have Direct Dial</mat-card-title>
    <mat-card-content>
      <span class="bannerNumber">{{exists_direct_dial | number}}</span>
      ({{100 * exists_direct_dial / total_hits | number:'1.0-0'}}%)
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Housefile N</mat-card-title>
    <mat-card-content>
      <span class="bannerNumber">{{exists_contact_info | number}}</span>
      ({{100 * exists_contact_info / total_hits | number:'1.0-0'}}%)
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>New Jobs</mat-card-title>
    <mat-card-subtitle>Detected in last 30 + Started in last 90</mat-card-subtitle>
    <mat-card-content>
      <span class="bannerNumber">{{new_jobs | number}}</span>
      <div class="subNumber">
        Missing Email:
        {{new_jobs_missing_email | number}}
        ({{100 * new_jobs_missing_email / new_jobs | number:'1.0-0'}}%)
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div class="statContainer2" *ngIf="!loading">
  <mat-card>
    <mat-card-title>Top Locations</mat-card-title>
    <mat-card-content>
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="top_location_chart_options"
        oneToOne="true"></highcharts-chart>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Top Countries</mat-card-title>
    <mat-card-content>
      (logarithmic scale)
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="top_country_chart_options"
        oneToOne="true"></highcharts-chart>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Top Schools</mat-card-title>
    <mat-card-content>
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="top_schools_chart_options"
        oneToOne="true"></highcharts-chart>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Level Distribution</mat-card-title>
    <mat-card-content>
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="top_level_chart_options"
        oneToOne="true"></highcharts-chart>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Function Distribution</mat-card-title>
    <mat-card-content>
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="top_function_chart_options"
        oneToOne="true"></highcharts-chart>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Company Types</mat-card-title>
    <mat-card-content>
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="top_company_types_options"
        oneToOne="true"></highcharts-chart>
    </mat-card-content>
  </mat-card>
</div>
<div *ngIf="!loading"></div>
<div class="statContainer3" *ngIf="!loading">
  <mat-card>
    <mat-card-title>Persons Inserted Into Live Data</mat-card-title>
    <mat-card-content>
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="created_chart_options"
        oneToOne="true"></highcharts-chart>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Last Successful Signal</mat-card-title>
    <mat-card-content>
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="success_chart_options"
        oneToOne="true"></highcharts-chart>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Job Changes Detected</mat-card-title>
    <mat-card-content>
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="jobchanged_chart_options"
        oneToOne="true"></highcharts-chart>
    </mat-card-content>
  </mat-card>
</div>
<ng-template #elseBlock>
  <div class="loading-container">
    <div class="loading">
      <mat-spinner diameter="60"></mat-spinner>
      <p class="loading-message">Loading stats...</p>
    </div>
  </div>
</ng-template>
