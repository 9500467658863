import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QuickBuild } from '../../quick-build.service';
import { ChatFeedbackDialogComponent } from '../chat-feedback-dialog/chat-feedback-dialog.component';
import {
  ChatDefaultResponse,
  ChatRequest,
  ChatService,
  ErrorRequest,
  FeedbackRequest,
} from 'ldt-chat-api';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { QuickBuildSchema } from '../../quick-build-validators';

export interface ChatToQueryResult {
  id: string;
  prompt: string;
  quickBuild: QuickBuild;
}

@Component({
  selector: 'app-people-chat-to-filters',
  templateUrl: './chat-to-filters.component.html',
  styleUrls: ['./chat-to-filters.component.scss'],
})
export class ChatToFiltersComponent {
  @Input() orgId: string;
  @Output() quickBuild = new EventEmitter<ChatToQueryResult>();

  prompt: string;
  prompting: boolean = false;
  assessOption: boolean = false; // If there was ever a prompt
  lastResponse: ChatDefaultResponse;

  constructor(
    private chatService: ChatService,
    private notify: NotificationService,
    private dialog: MatDialog
  ) {}

  chat() {
    if (!this.prompt || this.prompt.trim().length === 0) return;

    this.prompting = true;
    this.assessOption = false;
    const req: ChatRequest = {
      prompt: this.prompt,
    };

    this.chatService.chat(this.orgId, req).subscribe({
      next: (qb) => {
        if (!qb.response) {
          this.notify.error('No response from chat API');
          return;
        }

        try {
          const parsedParams = JSON.parse(qb.response);
          const result = QuickBuildSchema.safeParse(parsedParams);

          if (!result.success) {
            console.error('Invalid quickbuild schema:', result.error);

            const req: ErrorRequest = {
              type: 'schema',
              message: result.error?.message || '',
            };
            this.chatService.error(this.orgId, qb.id || 'UNKNOWN', req).subscribe();

            throw new Error('Invalid quickbuild schema');
          }

          this.quickBuild.emit({
            id: qb.id || 'UNKNOWN',
            prompt: req.prompt || '',
            quickBuild: parsedParams as QuickBuild,
          });
          this.prompting = false;
          this.assessOption = true;

          this.lastResponse = qb;
        } catch (err: unknown) {
          this.prompting = false;
          console.error('Error parsing quickbuild:', err);
          this.notify.error('Invalid response from chat. Try changing your prompt.');

          const message = err instanceof Error ? err.message : String(err);

          const req: ErrorRequest = {
            type: 'parse',
            message: message,
          };
          this.chatService.error(this.orgId, qb.id || 'UNKNOWN', req).subscribe();
        }
      },
      error: (err) => {
        this.prompting = false;
        console.error('Error chatting with LDT:', err);
        if ('message' in err) {
          if (err.message.includes('Invalid quickbuild schema')) {
            this.notify.error(
              'Invalid response from chat. Try changing your prompt. This error was already reported, no need to assess.'
            );
            return;
          }
          this.notify.error('Error connecting with chat. Try again.');
          return;
        }
        this.notify.error('Error connecting with chat.');
      },
    });
  }

  chatFeedback() {
    const dialogRef = this.dialog.open(ChatFeedbackDialogComponent, {
      width: '500px',
      data: {}, // Add any data you want to pass to the modal
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('Form submitted:', result);
        const req: FeedbackRequest = {
          accuracy: result.accuracy,
          feedback: result.feedback,
        };

        this.chatService.feedback(this.orgId, this.lastResponse.id || 'UNKNOWN', req).subscribe({
          next: () => {
            this.notify.success('Feedback submitted');
          },
          error: () => {
            this.notify.error('Error submitting feedback');
          },
        });
      }
    });
  }
}
