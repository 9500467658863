import { Component } from '@angular/core';
import { DarkModeService } from '../dark-mode/dark-mode.service';

@Component({
  selector: 'app-side-img-decoration',
  templateUrl: './side-img-decoration.component.html',
  styles: [],
  host: {
    class:
      'tw-relative tw-hidden tw-w-0 tw-flex-1 lgx:tw-flex lgx:tw-flex-col lgx:tw-justify-center lgx:tw-py-10 lgx:tw-px-12 xl:tw-px-16',
  },
})
export class SideImgDecorationComponent {
  constructor(public darkModeService: DarkModeService) {}
}
