<form [formGroup]="form">
  <mat-form-field appearance="outline" floatLabel="always" class="job-level-dropdown tw-w-full">
    <mat-label class="tw-text-gray-800 dark:tw-text-white">
      Job Level{{multiSelect ? 's': ''}}
    </mat-label>
    <mat-select
      formControlName="selectedLevels"
      [multiple]="multiSelect"
      [value]="preSelectedJobLevels"
      placeholder="All"
      (closed)="onDropdownClosed()">
      <mat-select-trigger>
        {{ getCollapsedStringValue() }}
      </mat-select-trigger>
      <div *ngIf="multiSelect" class="tw-flex tw-justify-between tw-mb-2 tw-px-1 xl:tw-px-2">
        <button
          mat-button
          (click)="selectAll()"
          [disabled]="areAllSelected()"
          class="tw-text-gray-800 dark:tw-text-white">
          Select All
        </button>
        <button
          mat-button
          (click)="unselectAll()"
          [disabled]="areNoneSelected()"
          class="tw-text-gray-800 dark:tw-text-white">
          Unselect All
        </button>
      </div>

      <div class="xl:tw-w-full xl:tw-columns-2">
        <mat-option
          *ngFor="let item of availableJobLevels"
          [value]="item"
          class="tw-text-sm tw-border-0 tw-border-solid tw-border-gray-300 tw-border-b">
          {{ item }}
        </mat-option>
      </div>
    </mat-select>
  </mat-form-field>
</form>
