import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactImportsService, Ledger, LedgersService } from 'ldt-ledger-service-api';
import { BehaviorSubject, merge, Observable, Subject, timer } from 'rxjs';
import { delay, map, retryWhen, share, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../auth/service/auth.service';
import { NotificationService } from '../shared/notification-service/notification.service';
import { LedgerContactsComponent } from './ledger-contacts/ledger-contacts.component';
import { LedgerImportComponent } from './ledger-import/ledger-import.component';
import { LedgerOverviewComponent } from './ledger-overview/ledger-overview.component';
import { NgLedgerService } from './ledger.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.scss'],
})
export class LedgerComponent implements OnInit, OnDestroy {
  // Get the imports component so we can trigger a refresh of imports grid after an upload
  @ViewChild(LedgerImportComponent) importComponent: LedgerImportComponent;
  @ViewChild(LedgerContactsComponent) contactsComponent: LedgerContactsComponent;
  @ViewChild(LedgerOverviewComponent) overviewComponent: LedgerOverviewComponent;
  currentTabIndex = 0;
  ledger: Ledger;
  orgId: string;
  $ledgerApiV2: Observable<boolean>;
  useV2Import: boolean = false;

  @Input() placeholder: string = 'Description';

  // TODO make this async - may not be necessary since the component is reloaded for every ledger
  canEdit: boolean = false;

  importsInProgress$: BehaviorSubject<any> = new BehaviorSubject<any>({
    importing: false,
    processing: false,
  });
  private stopPolling = new Subject<void>();
  private lastPoll = false;
  private manualTrigger = new Subject<void>();
  atContactLimit: boolean = false;

  showSpace: boolean = false;

  closeSpace() {
    this.showSpace = false;
  }

  showUploader() {
    this.showSpace = true;
  }

  constructor(
    private ledgerService: LedgersService,
    private importService: ContactImportsService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private notify: NotificationService,
    private ngLedgerService: NgLedgerService
  ) {
    const thisOrg = this.auth.getSelectedOrg();
    if (!thisOrg) {
      // In theory this should never happen cause if there are no orgs the OrgsGuard will redirect to a diff page
      this.router.navigateByUrl('/main');
    } else {
      let settings: any = thisOrg.settings;
      let limit = settings?.ledger?.contactLimit;
      // TODO limit
    }
    this.canEdit = this.auth.userHasRole('editor');
  }

  isAdmin: Observable<boolean>;
  hasImports: boolean = false;
  $canAccessV2: Observable<boolean>;

  async ngOnInit() {
    this.ledger = this.route.snapshot.data.userdata;
    this.orgId = this.auth.getSelectedOrgIdValue;
    this.isAdmin = this.auth.$isAdmin;
    this.$canAccessV2 = this.ngLedgerService.$canAccessV2;
    this.$ledgerApiV2 = this.ngLedgerService.getApiVersion();
    this.$ledgerApiV2.subscribe({
      next: (v2: boolean) => {
        const url = this.router.url;
        if (v2) {
          if (url.endsWith('overview') || url.endsWith('details')) {
            this.router.navigateByUrl(url + '-v2');
          }
        } else {
          if (url.endsWith('-v2')) {
            this.router.navigateByUrl(url.replace('-v2', ''));
          }
        }
      },
    });

    this.isAdmin.subscribe({
      next: (isAdmin) => {
        if (isAdmin) {
          this.useV2Import = true;
        }
      },
    });

    // Check every 30s to see if there are active imports. If so, show a notification and
    //  update the contacts and imports lists while imports are running. Also do one last
    //  update when it flips from true -> false, and have a manual trigger when a user does an import
    //  Might want to change this to return the imports[] instead of a boolean if we want to do more with them
    merge(timer(1, 60000), this.manualTrigger.asObservable())
      .pipe(
        switchMap(() => this.importService.getImportJobs(this.ledger.id, this.orgId)),
        tap((res) => {
          this.hasImports = res.imports.length > 0;
        }),
        map((res) => {
          return {
            importing: res.imports.some((i) => i.status === 'importing'),
            processing: res.imports.some((i) => i.status === 'processing'),
          };
        }),
        retryWhen((errors) => errors.pipe(delay(30000), take(10))),
        share(),
        takeUntil(this.stopPolling),
        tap((x) => {
          this.importsInProgress$.next(x);
          if (x.importing || x.processing || this.lastPoll) {
            this.triggerContactsRefresh();
            this.triggerImportRefresh();
            this.updateLedger();
          }
          this.lastPoll = x.importing || x.processing;
        })
      )
      .subscribe();

    this.route.queryParams.subscribe((params) => {
      if (params['showAddContacts']) {
        this.showSpace = true;
      }
    });
  }

  ngAfterViewInit() {}

  triggerImportRefresh(): void {
    if (this.importComponent) {
      this.importComponent.refreshData();
    }
  }

  triggerContactsRefresh(): void {
    if (this.contactsComponent) {
      this.contactsComponent.refreshData(true);
    }
    if (this.overviewComponent) {
      this.overviewComponent.refreshData();
    }
  }

  updateLedgerSettings(orgSettings: Ledger) {
    this.ledger = orgSettings;
  }

  onLedgerNameChange(newName: any) {
    let updateBody = { name: newName.textContent, description: this.ledger.description };
    this.ledgerService.updateLedger(this.ledger.id, this.orgId, updateBody).subscribe(
      (res) => {
        if (res) {
          this.updateLedger();
        }
      },
      () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
      }
    );
  }

  onLedgerDescriptionChange(newDesc: any) {
    let updateBody = { description: newDesc.textContent, name: this.ledger.name };
    this.ledgerService.updateLedger(this.ledger.id, this.orgId, updateBody).subscribe(
      (res) => {
        if (res) {
          this.updateLedger();
        }
      },
      () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
      }
    );
  }

  ignoreCR(e: any) {
    if (e.keyCode === 13) {
      return false;
    }
    return true;
  }

  updateLedger() {
    this.ledgerService.getLedger(this.ledger.id, this.orgId).subscribe(
      (res) => {
        this.ledger = res;
      },
      () => {
        // Do nothing yet
      }
    );
  }

  uploadComplete() {
    timer(1000).subscribe(() => {
      this.manualTrigger.next();
    });
  }

  setLedgerVersion(evt: any) {
    this.ngLedgerService.setApiVersion(evt.checked);
  }

  ngOnDestroy() {
    // Stop the imports polling
    this.stopPolling.next();
  }
}
