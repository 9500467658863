<div class="managed-jobs-container">
  <div class="actionContainer">
    <button mat-raised-button color="primary" (click)="showCreateDialog()">
      Create Managed Job
    </button>
    <button
      [class.spinner]="refreshing"
      [disabled]="refreshing"
      mat-raised-button
      (click)="updateJobs()">
      Refresh
    </button>
    <span class="managerStatus"
      ><span class="mgrLabel">Managed Job Manager: {{status}}</span>
      <button *ngIf="status == 'running'" mat-raised-button (click)="pauseManagedJobs()">
        Pause
      </button>
      <button *ngIf="status == 'paused'" mat-raised-button (click)="resumeManagedJobs()">
        Resume
      </button>
    </span>
  </div>
  <div class="container" width="100%">
    <ag-grid-angular
      style="height: 100%;"
      [ngClass]="{ 'ag-theme-alpine': !(darkModeService.darkMode$ | async), 'ag-theme-alpine-dark': darkModeService.darkMode$ | async }"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      rowDragManaged="true"
      (rowDragEnd)="onRowDragEnd($event)"
      [components]="components"
      [defaultColDef]="defaultColDef"
      [rowClassRules]="rowClassRules"
      enableCellTextSelection="true"
      ensureDomOrder="true"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
