<div class="tw-mb-4">
  <div class="section-header">Settings</div>
  <form [formGroup]="form" class="tw-px-0 tw-flex-wrap">
    <mat-card class="tw-w-[43%] xl:tw-w-[20%]">
      <mat-card-header>
        <mat-card-title>Ledgers</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="tw-flex tw-items-center tw-mb-2">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeLedgerCapability" />
          <mat-label class="tw-mr-1">Enabled</mat-label>
          <mat-slide-toggle
            formControlName="ledgerCapability"
            id="ledgerCapability"></mat-slide-toggle>
        </div>
        <div class="tw-mt-2">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeContactLimit" />
          <mat-form-field appearance="outline">
            <mat-label class="tw-mr-1">Ledger Contact Limit</mat-label>
            <input matInput formControlName="contactLimit" id="contactLimit" placeholder="None" />
          </mat-form-field>
        </div>

        <div>
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeLedgerReinit" />
          <mat-label class="tw-mr-1">Reinit Ledger</mat-label>
          <mat-slide-toggle formControlName="ledgerReinit" id="ledgerReinit"></mat-slide-toggle>
        </div>
        <div>
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeLedgerUiApiVersion" />
          <mat-label class="tw-mr-1">API Version</mat-label>
          V1
          <mat-slide-toggle
            formControlName="ledgerUiApiVersion"
            id="ledgerUiApiVersion"></mat-slide-toggle>
          V2
        </div>
        <div>
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeLedgerUiApiVersionSelector" />
          <mat-label class="tw-mr-1">Version Picker</mat-label>
          No
          <mat-slide-toggle
            formControlName="ledgerUiApiVersionSelector"
            id="ledgerUiApiVersionSelector"></mat-slide-toggle>
          Yes
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="tw-w-[43%] xl:tw-w-[20%]">
      <mat-card-header>
        <mat-card-title>Moneyball</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div>
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeMoneyballCapability" />
          <mat-label class="tw-mr-1">Enabled</mat-label>
          <mat-slide-toggle
            formControlName="moneyballCapability"
            id="moneyballCapability"></mat-slide-toggle>
        </div>
        <div class="tw-pt-4">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeMoneyballExpiration" />
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="useStripeForMoneyballExpiration" />
          <mat-form-field class="tw-mr-1">
            <input
              class="tw-w-2/3"
              formControlName="moneyballExpiration"
              matInput
              [matDatepicker]="picker"
              [min]="minExpirationDate"
              placeholder="Expiration Date" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <button
            matSuffix
            mat-flat-button
            aria-label="Clear"
            (click)="clearExpiration()"
            class="tw-p-0 hover:tw-bg-gray-300">
            Clear
          </button>
        </div>
        <!-- Subscription Type -->
        <div class="tw-flex tw-items-start tw-gap-4 tw-mt-2">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeMoneyballSubscriptionType" />
          <mat-label class="tw-mr-1">Subscription Type</mat-label>
          <mat-radio-group
            formControlName="moneyballSubscriptionType"
            class="tw-flex tw-flex-col tw-gap-2">
            <mat-radio-button *ngFor="let type of subscriptionTypes" [value]="type.value">
              {{ type.label }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="tw-mt-2">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeMoneyballEntitledSeats" />
          <mat-form-field appearance="outline">
            <mat-label class="tw-mr-1">Moneyball Seats</mat-label>
            <input
              matInput
              formControlName="moneyballEntitledSeats"
              id="moneyballEntitledSeats"
              placeholder="None" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="tw-w-[43%] xl:tw-w-[20%]">
      <mat-card-header>
        <mat-card-title>Jobs</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div>
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeJobsCapability" />
          <mat-label class="tw-mr-1">Enabled</mat-label>
          <mat-slide-toggle formControlName="jobsCapability" id="jobsCapability"></mat-slide-toggle>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="tw-w-[43%] xl:tw-w-[20%]">
      <mat-card-header>
        <mat-card-title>People</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div>
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludePeopleCapability" />
          <mat-label class="tw-mr-1">Enabled</mat-label>
          <mat-slide-toggle
            formControlName="peopleCapability"
            id="peopleCapability"></mat-slide-toggle>
        </div>

        <div class="tw-mt-2 tw-font-bold"> 
          Features: 
        </div>

        <!-- feature explore -->
        <div class="tw-mt-2">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludePeopleFeatureExplore" />
          <mat-label class="tw-mr-1" for="peopleFeatureExplore">
            <span class="badge-selected-items tw-bg-gray-600">Explore</span>
          </mat-label>
          No
          <mat-slide-toggle
            formControlName="peopleFeatureExplore"
            id="peopleFeatureExplore"></mat-slide-toggle>
          Yes
        </div>

        <!-- feature chat -->
        <div class="tw-mt-2 ">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludePeopleFeatureChatToQuery" />
          <mat-label class="tw-mr-1" for="peopleFeatureChatToQuery">
            -> <span class="badge-selected-items tw-bg-gray-600">Chat Filters</span>
          </mat-label>
          No
          <mat-slide-toggle
            formControlName="peopleFeatureChatToQuery"
            id="peopleFeatureChatToQuery"></mat-slide-toggle>
          Yes
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</div>
