<div
  class="builds-container tw-flex tw-items-center tw-flex-wrap tw-my-3"
  [ngClass]="{'tw-justify-end': !showQuickBuilds}">
  <div *ngIf="showQuickBuilds" class=" tw-max-w-full tw-mt-4 tw-mb-7">
    <div class="tw-flex tw-items-center tw-gap-2 tw-pb-4">
      <h2 class="tw-text-sm tw-font-semibold tw-my-0">Quick Builds</h2>
      <button
        matTooltip="Quick Builds are pre-configured filters that you can apply to your search"
        matTooltipPosition="above"
        class="tw-mr-1">
        <mat-icon
          class="tw-text-[18px] tw-w-[18px] tw-h-[18px] tw-leading-[21px] tw-cursor-pointer dark:tw-text-white">
          info_outline
        </mat-icon>
      </button>
      <button
        class="tw-font-medium tw-text-link-color-dynamic-darker tw-flex tw-items-center tw-text-xs tw-border tw-border-transparent tw-border-dotted hover:tw-rounded-sm focus:tw-rounded-sm hover:tw-text-primary_purple-600 hover:tw-border-primary_purple-600 focus:tw-text-primary_purple-600 focus:tw-border-primary_purple-600 tw-ml-auto"
        (click)="toggleQuickBuilds()">
        <mat-icon class="tw-text-[18px] tw-w-[18px] tw-h-[18px] tw-mr-1"> visibility_off </mat-icon>
        <span>Hide Quick Builds</span>
      </button>
    </div>
    <div class="tw-columns-1 md:tw-columns-2 xl:tw-columns-3">
      <button
        *ngFor="let quickBuild of quickBuilds"
        mat-stroked-button
        class="compact-btn quick-build-btns tw-text-dark-light-dynamic tw-mr-3 hover:tw-opacity-80 tw-mb-2"
        (click)="setQuickBuildFilters(quickBuild.id)">
        <span
          class="tw-max-w-64 tw-inline-block tw-truncate tw-overflow-hidden tw-whitespace-nowrap tw-pr-2">
          {{quickBuild.name}}
        </span>
        <mat-icon
          class="tw-text-[14px] tw-w-[14px] tw-h-[14px] tw-transform tw-rotate-[-30deg] tw-ml-1 tw-mb-1 tw-text-link-color-dynamic"
          style="--tw-translate-x: 0; --tw-translate-y: 0; --tw-skew-x: 0; --tw-skew-y: 0; --tw-scale-x: 1; --tw-scale-y: 1;">
          send
        </mat-icon>
        <mat-icon
          class="tw-text-[18px] tw-w-[18px] tw-h-[18px] tw-ml-1 tw-mb-1 hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700"
          (click)="deleteQuickBuild(quickBuild.id); $event.stopPropagation()"
          matTooltip="Delete this Quick Build"
          matTooltipPosition="above">
          close
        </mat-icon>
      </button>
    </div>
  </div>

  <button
    *ngIf="!showQuickBuilds"
    class="tw-font-medium tw-text-link-color-dynamic-darker tw-flex tw-items-center tw-text-sm tw-border tw-border-transparent tw-border-dotted hover:tw-border-primary_purple-600 hover:tw-rounded-sm focus:tw-rounded-sm focus:tw-border-primary_purple-600"
    (click)="toggleQuickBuilds()">
    <mat-icon class="tw-text-[18px] tw-w-[18px] tw-h-[18px] tw-mr-1"> visibility </mat-icon>
    <span class="hover:tw-underline">Show Quick Builds</span>
  </button>
</div>
