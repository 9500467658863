import { Component } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import {
  AdminService,
  ListScheduledReportsRequest,
  ScheduledReport,
} from 'ldt-data-deliveries-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';

@Component({
  selector: 'app-scheduled-reports',
  templateUrl: './scheduled-reports.component.html',
  styleUrls: ['./scheduled-reports.component.scss'],
})
export class ScheduledReportsComponent {
  refreshing: boolean = false;
  scheduledReports: ScheduledReport[] = [];
  public colDefs: ColDef[] = [
    { field: 'id', maxWidth: 60 },
    { field: 'org_id', maxWidth: 110, filter: 'agSetColumnFilter' },
    { field: 'name' },
    { field: 'frequency', filter: 'agSetColumnFilter' },
    { field: 'report_definition.source.name', headerName: 'Source', filter: 'agSetColumnFilter' },
    { field: 'trigger_date_fields', headerName: 'Triggers', filter: 'agSetColumnFilter' },
    {
      field: 'destination.destination_type.display_name',
      headerName: 'Dest Type',
      filter: 'agSetColumnFilter',
    },
    { field: 'created_at', maxWidth: 230 },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    flex: 1,
  };

  constructor(
    private scheduledReportService: AdminService,
    private notify: NotificationService,
    public darkModeService: DarkModeService
  ) {}

  ngOnInit(): void {
    this.getScheduledReports();
  }

  getScheduledReports(): void {
    this.refreshing = true;
    let req: ListScheduledReportsRequest = {
      size: 1000,
    };
    this.scheduledReportService.listAllScheduledReports(req).subscribe({
      next: (data) => {
        this.scheduledReports = data.items;
        this.refreshing = false;
        if (data.pagination_token) {
          this.notify.warning('Only first 1000 destinations are displayed, but there are more!');
        }
      },
      error: (error) => {
        this.notify.error('Failed to load scheduled reports');
        this.refreshing = false;
      },
    });
  }
}
