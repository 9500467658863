import { Component } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { ResolverService, ResolverValue, UpdateManualVersionRequest } from 'ldt-resolver-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { ResolverLocationValueDialogComponent } from './location-value-dialog/location-value-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { timer } from 'rxjs';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';

@Component({
  selector: 'app-resolver',
  templateUrl: './resolver.component.html',
  styleUrls: ['./resolver.component.scss'],
})
export class ResolverComponent {
  refreshing: boolean = false;
  resolverValues: ResolverValue[] = [];
  public colDefs: ColDef[] = [
    { field: 'input', sort: 'asc' },
    { field: 'value.msa', headerName: 'MSA (US only)' },
    { field: 'value.locality', headerName: 'Locality (city, town, etc)' },
    { field: 'value.region_abbreviation', headerName: 'Region (state, etc)' },
    { field: 'value.country_code', headerName: 'Country' },
    { field: 'metadata.version', headerName: 'Version' },
    { field: 'metadata.resolver_id', headerName: 'Resolver ID' },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    flex: 1,
  };
  constructor(
    private resolver: ResolverService,
    private notify: NotificationService,
    private dialog: MatDialog,
    public darkModeService: DarkModeService
  ) {}

  ngOnInit(): void {
    this.getResolverValues();
  }

  getResolverValues(): void {
    this.refreshing = true;
    this.resolver.listResolverValuesByField('location').subscribe({
      next: (data) => {
        this.resolverValues = data.resolver_values || [];
        this.refreshing = false;
      },
      error: (error) => {
        this.notify.error('Error fetching resolver values');
        this.refreshing = false;
      },
    });
  }

  createResolverValue(data: ResolverValue | null = null): void {
    const dialogRef = this.dialog.open(ResolverLocationValueDialogComponent, {
      width: '800px',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;

      if (!result.raw) {
        this.notify.error('Raw value is required');
        return;
      }
      // Handle the submitted location data
      const req: UpdateManualVersionRequest = {
        input: result.raw,
        value: result,
      };
      this.resolver.updateManualVersion('location', req).subscribe({
        next: () => {
          this.notify.success('Resolver value updated. It may take a few minutes to see here...');

          // Wait a couple seconds since the message needs to get all the way through to OS
          timer(3000).subscribe(() => {
            this.getResolverValues();
          });
        },
        error: (error) => {
          this.notify.error('Error updating resolver value: ' + error);
        },
      });
    });
  }

  getContextMenuItems = (params: any) => {
    const result = [
      {
        name: 'Edit Location',
        icon: '<span class="material-icons tw-w-4 tw-h-4 tw-text-sm">edit</span>',
        action: () => {
          this.createResolverValue(params.node.data.value);
        },
      },
      {
        name: 'Duplicate Location',
        icon: '<span class="material-icons tw-w-4 tw-h-4 tw-text-sm">content_copy</span>',
        action: () => {
          const dupeData = { ...params.node.data.value };
          delete dupeData.raw;
          this.createResolverValue(dupeData);
        },
      },
    ];
    return result;
  };
}
