import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HeapService } from 'src/app/api-client/heap.service';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import Hotjar from '@hotjar/browser';
import { AuthService } from '../service/auth.service';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import {
  CreateUserRequest,
  Invitation,
  InvitationsService,
  UsersService,
} from 'ldt-identity-service-api';
declare const window: any;
@Component({
  selector: 'app-log-in-sign-up',
  templateUrl: './log-in-sign-up.component.html',
  styleUrls: ['./log-in-sign-up.component.scss'],
})
export class LogInSignUpComponent implements OnInit {
  currentEmail = '';
  loginform: UntypedFormGroup;
  loginInvalid = false;
  signUpSuccess = false;
  signUpEmailError = false;
  validationError = false;
  isValidating = false;

  isInvite: boolean = false;
  inviteError: boolean = false;
  inviteErrorMessage: string = '';
  inviteToken: string;
  inviteType: string = 'personal';
  invitation: Invitation;

  specialCharacterCriteraMet: boolean;
  uppercaseLetterCriteriaMet: boolean;
  lowercaseLetterCriteriaMet: boolean;
  numberCriteriaMet: boolean;
  lengthCriteriaMet: boolean;
  unvalidated: '';
  pValidation = [
    {
      func: this.hasLength,
      text: 'Between 8 and 64 characters',
      valid: true,
    },
  ];
  form = this.fb.group(
    {
      fullNameFormControl: new UntypedFormControl('', [Validators.required]),
      emailFormControl: new UntypedFormControl('', {
        validators: [Validators.required, Validators.email],
      }),
      passwordFormControl: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(64),
      ]),
      confirmPasswordFormControl: new UntypedFormControl('', []),
    },
    { validators: this.checkPasswords }
  );
  googleButtonValue = 'Sign up with Google';
  isActionLogin = false;
  isGoogleSignup = false;
  showPassword: boolean = false;
  showPasswordRequirements = false;
  showEmailRequirements: boolean = false;
  error: string | undefined = undefined;
  passwordMatchError = false;
  defaultValue = '0';
  loading: boolean = false;
  isDarkMode = false;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private notify: NotificationService,
    private heapService: HeapService,
    private userService: UsersService,
    private inviteService: InvitationsService,
    private recaptcha: ReCaptchaV3Service
  ) {
    // Handle redirects from the invite page
    if (window.history.state && window.history.state.inviteToken) {
      this.inviteToken = window.history.state.inviteToken;
      if (window.history.state.type) {
        this.inviteType = window.history.state.type;
      }
    }

    if (this.inviteType === 'moneyball_onboarding') {
      this.authService.setRedirectUrl('/main');
    }
  }

  ngOnInit() {
    this.formInit();
    this.route.data.subscribe((data) => {
      if (data.isLogin) {
        this.googleButtonValue = 'Log in with Google';
        this.isActionLogin = true;

        if (this.form) this.form.addControl('remember', this.fb.control(false));
      }
    });
    this.loginInvalid = false;

    if (this.inviteToken) {
      this.isActionLogin = false;
      this.inviteService.getInvitationByToken(this.inviteToken).subscribe({
        next: (r: Invitation) => {
          this.invitation = r;
          this.form.patchValue({ fullNameFormControl: r.inviteeName });
          this.form.patchValue({ emailFormControl: r.inviteeEmail });
          this.isInvite = true;
        },
        error: (err: any) => {
          this.inviteError = true;

          if (err.status === 404) {
            this.inviteErrorMessage =
              'Invalid invitation token provided.\nPlease contact your administrator or support@livedatatechnologies.com for help.';
          } else if (err.status === 409) {
            this.inviteErrorMessage =
              'Invitation has expired.\nPlease ask your administrator to send a new invitation.';
          } else {
            this.inviteErrorMessage =
              'Unknown error occurred.\nPlease contact your administrator or support@livedatatechnologies.com for help.';
          }
        },
      });
    }
  }

  update(event: any) {
    this.unvalidated = event.target.value;
  }
  formInit() {
    this.loginform = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required]],
    });
  }

  checkPasswords(group: UntypedFormGroup) {
    // here we have the 'passwords' group
    const pass = group.get('passwordFormControl')!.value;
    const confirmPass = group.get('confirmPasswordFormControl')!.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  hasLength(pass: string) {
    return pass.length >= 8 && pass.length <= 64;
  }

  checkingPassword(event: any) {
    const password = event.target.value;
    this.validatePassword(password);
  }

  validatePassword(password: string) {
    this.pValidation.forEach((v) => {
      v.valid = v.func(password);
    });
  }

  checkEmail(group: UntypedFormGroup) {
    const email = group.get('emailFormControl')!.value;
    const notValidEmails = ['@yahoo.com', '@aol.com', '@gmail.com', '@hotmail.com'];
    if (!notValidEmails.some((el) => email.includes(el)) && this.signUpEmailError) {
      this.signUpEmailError = false;
    }
    return notValidEmails.some((el) => email.includes(el));
  }
  onSubmit(event: any) {
    event.preventDefault();
    if (this.isActionLogin) {
      if (!this.loginform) return;

      if (this.isGoogleSignup) {
        // this.configureGoogleLogin();
        window.location.href =
          'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=436968108608-edemh8qtvronocf2q5ekfmj22vc9ugge.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A5000%2Fapi%2Fv1%2Fsignup%2Fcallback&scope=openid+email+profile&access_type=offline';
        // window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=436968108608-edemh8qtvronocf2q5ekfmj22vc9ugge.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fgotlivedata.io%2Fapi%2Fv1%2Fsignup%2Fcallback&scope=openid+email+profile&access_type=offline';
      } else {
        this.logIn();
      }
    } else {
      if (!this.form) return;

      if (this.isGoogleSignup) {
        window.location.href =
          'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=436968108608-edemh8qtvronocf2q5ekfmj22vc9ugge.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A5000%2Fapi%2Fv1%2Fsignup%2Fcallback&scope=openid+email+profile&access_type=offline';
        // window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=436968108608-edemh8qtvronocf2q5ekfmj22vc9ugge.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fgotlivedata.io%2Fapi%2Fv1%2Fsignup%2Fcallback&scope=openid+email+profile&access_type=offline';
      } else {
        this.lengthCriteriaMet = this.hasLength(this.form.value.passwordFormControl);
        if (this.lengthCriteriaMet) {
          this.signUp();
        } else {
          this.validationError = true;
        }
      }
    }
  }

  onClick() {
    this.isGoogleSignup = true;
  }

  navigateToSignup() {
    this.router.navigateByUrl('onboarding');
  }

  navigateToLogin() {
    this.isActionLogin = true;
  }

  async forgotPassword() {
    this.router.navigateByUrl('reset-password');
  }

  async logIn() {
    this.loading = true;
    this.error = undefined;

    try {
      if (!this.loginform) return;

      const { email, password } = this.loginform.value;

      this.authService.loginV2(email.trim(), password).subscribe({
        next: () => {
          this.heapService.identify(email.trim());
          this.heapService.addUserProperties({
            is_employee: this.authService.email
              ? this.authService.email.endsWith('livedatatechnologies.com')
              : false,
          });
          window['Intercom']('boot', {
            app_id: environment.intercomAppId,
            email: email.trim(),
            name: this.authService.getUserValue.name || '',
            user_id: this.authService.getUserValue.id,
            companies: this.authService.getOrgsValue.map((o) => {
              return {
                company_id: o.id,
                created_at: o.createdAt,
                name: o.name,
              };
            }),
          });
          Sentry.setUser({
            email: this.authService.getUserValue.email,
            id: this.authService.getUserValue.id,
          });
          Hotjar.identify(this.authService.getUserValue.id, {
            is_employee: this.authService.email
              ? this.authService.email.endsWith('livedatatechnologies.com')
              : false,
            created_at: this.authService.getUserValue.createdAt,
            email: this.authService.getUserValue.email,
            source: this.authService.getUserValue.source || 'Unknown',
          });

          // Navigate based on redirect URL
          const redirectUrl = this.authService.$redirectUrl || '/main';
          this.router.navigateByUrl(redirectUrl);

          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.notify.error('Incorrect username or password. Please try again.');
        },
      });
    } catch (e) {
      this.loading = false;
      this.loginInvalid = true;
    }
  }

  async signUp() {
    try {
      this.error = undefined;

      if (!this.form) return;

      const {
        emailFormControl,
        fullNameFormControl,
        passwordFormControl,
        confirmPasswordFormControl,
      } = this.form.value;
      if (
        !emailFormControl ||
        !fullNameFormControl ||
        !passwordFormControl ||
        !confirmPasswordFormControl
      ) {
        return;
      }
      if (passwordFormControl !== confirmPasswordFormControl) {
        this.passwordMatchError = true;
        return;
      }

      this.recaptcha.execute('create_account').subscribe({
        next: (token) => {
          let body: CreateUserRequest = {
            email: emailFormControl.trim(),
            password: passwordFormControl,
            name: fullNameFormControl.trim(),
            recaptchaToken: token,
          };

          // This will auto-accept the invite
          if (this.inviteToken) {
            body.inviteToken = this.inviteToken;
          }

          this.userService.createUser(body).subscribe({
            next: () => {
              this.notify.success(
                'User created successfully. Welcome to the Live Data revolution.'
              );
              this.loginform.patchValue({ email: emailFormControl, password: passwordFormControl });
              this.logIn();
            },
            error: (err: HttpErrorResponse) => {
              if (err.status) {
                switch (err.status) {
                  case 409:
                    this.notify.error(
                      'That email address is already taken. Perhaps you need to reset your password?'
                    );
                    break;
                  case 401:
                    this.notify.error(
                      'Error creating user. Make sure your browser allows recaptcha and your invitation matches your email address.'
                    );
                    break;
                  case 400:
                    // Surface this to Sentry. Future improvement: https://app.clickup.com/t/8684v9uw7
                    if (err.message && err.message.includes('invitation token')) {
                      Sentry.captureException(err);
                    }
                    this.notify.error(
                      'Sorry, there was a problem creating your account. Please try again later or contact support@livedatatechnologies.com for help. Code: lcu-400'
                    );
                    break;
                  case 500:
                    this.notify.error(
                      'Sorry, there was a problem creating your account. Please try again later or contact support@livedatatechnologies.com for help. Code: lcu-500'
                    );
                    break;
                }
              } else {
                this.notify.error(
                  'Sorry, there was a problem creating your account.  Please try again later or contact support@livedatatechnologies.com for help. Code: lcu-900'
                );
              }
            },
          });
        },
      });
    } catch (e: any) {
      if (e instanceof HttpErrorResponse) {
        const err = e as HttpErrorResponse;
        this.error = err.error;
      } else this.error = e.message;
    }
  }

  onPasswordConfirmChange(val: string) {
    const { password, confirmPassword } = this.form!.value;

    // After the number of characters in the password, start checking for equality.
    if (val.length >= password.length) {
      if (confirmPassword !== password) {
        this.passwordMatchError = true;
      } else {
        this.passwordMatchError = false;
      }
    }
  }
}
