<div class="content-title t">
  Ledgers
  <button
    mat-raised-button
    color="primary"
    class="button tw-ml-auto"
    (click)="showAddLedgerDialog()">
    <div class="tw-flex tw-items-center tw-gap-2"><mat-icon>add</mat-icon>Add Ledger</div>
  </button>
</div>

<div class="insightsPanel mat-card-shadow tw-bg-white dark:tw-border-gray-500 dark:tw-bg-gray-800">
  <div *ngIf="!chartsLoading && chartDefinitions.length > 0" class="chartsContainer">
    <highcharts-chart
      *ngFor="let def of selectedCharts"
      [Highcharts]="Highcharts"
      [options]="def.chartOptions"
      [(update)]="updateFlag"></highcharts-chart>
  </div>
  <div *ngIf="!chartsLoading && chartDefinitions.length == 0" class="chartsContainer">
    <div class="sampleImage sampleImage1">
      <div class="sampleImageTitle">Pending data...</div>
    </div>
    <div class="sampleImage sampleImage2">
      <div class="sampleImageTitle">Pending data...</div>
    </div>
    <div class="sampleImage sampleImage3">
      <div class="sampleImageTitle">Pending data...</div>
    </div>
  </div>
  <div *ngIf="chartsLoading" class="loading">
    <mat-spinner diameter="60"></mat-spinner>
    <p class="loading-message">Loading summary data...</p>
  </div>
</div>

<div *ngIf="!ledgersLoading; else elseBlock" class="content">
  <div *ngIf="ledgers.length > 0; else noLedgers">
    <div class="viewPicker">
      <button
        mat-icon-button
        (click)="changeView('list')"
        aria-label="list view"
        class="hover:tw-bg-gray-500 tw-transition-all">
        <mat-icon>menu</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="changeView('card')"
        aria-label="list view"
        class="hover:tw-bg-gray-500 tw-transition-all">
        <mat-icon>dataset</mat-icon>
      </button>
    </div>
    <div class="ledgerCardContainer" *ngIf="ledgerView=='card'">
      <div class="ledgerCard" *ngFor="let ledger of ledgers">
        <mat-card>
          <mat-card-header (click)="goToLedger(ledger.id)">
            <mat-card-title>{{ledger.name}}</mat-card-title>
            <mat-card-subtitle>{{ledger.description}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content (click)="goToLedger(ledger.id)">
            <div class="headlineRow">
              <div class="iconContainer">
                <mat-icon>group_outline</mat-icon>
              </div>
              <div class="headlineNumber">{{ledger.size | number}}</div>
              <div class="headlineText">Total contacts</div>
            </div>
            <div class="cardChart">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="ledgerCharts[ledger.id]"
                [(update)]="updateFlag"></highcharts-chart>
            </div>
          </mat-card-content>
          <mat-card-actions *ngIf="$canEdit" align="end">
            <button
              [class.button-spinner]="ledgersDeleting.includes(ledger.id)"
              [disabled]="ledgersDeleting.includes(ledger.id)"
              mat-button
              (click)="deleteLedger(ledger)">
              Delete
            </button>
            <button
              [disabled]="atContactLimit"
              mat-stroked-button
              color="primary"
              (click)="goToLedger(ledger.id, true)">
              Add Contacts
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>

    <div *ngIf="ledgerView=='list'" class="ledgerListContainer">
      <ag-grid-angular
        style="height: 100%;"
        [ngClass]="{ 'ag-theme-alpine': !(darkModeService.darkMode$ | async), 'ag-theme-alpine-dark': darkModeService.darkMode$ | async }"
        [rowData]="ledgers"
        [columnDefs]="columnDefs"
        accentedSort="true"
        [components]="components"
        [defaultColDef]="defaultColDef"
        (rowClicked)="onRowClicked($event)"
        enableCellTextSelection="true"
        ensureDomOrder="true"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>

  <ng-template #noLedgers>
    <div class="no-ledger-container">
      <button
        mat-raised-button
        class="button add-ledger-button add-first-ledger-button"
        (click)="showAddLedgerDialog()">
        <div class="add-ledger-button-text">
          <mat-icon>add</mat-icon>Add a Ledger to get started
        </div>
      </button>
    </div>
  </ng-template>
</div>
<ng-template #elseBlock>
  <div class="loading-container">
    <div class="loading">
      <mat-spinner diameter="60"></mat-spinner>
      <p class="loading-message">Loading your ledgers...</p>
    </div>
  </div>
</ng-template>
