<mat-card class="result">
  <mat-card-content>
    <h2 class="mx-header">Ledger Maintenance Mode</h2>

    <section class="mx-selector">
      <mat-slide-toggle
        class="example-margin"
        color="warn"
        [(ngModel)]="mxMode"
        (change)="mxToggleChange()">
        Ledger Maintenance Mode
      </mat-slide-toggle>
    </section>
  </mat-card-content>
</mat-card>
