import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-people-chat-feedback-dialog',
  templateUrl: './chat-feedback-dialog.component.html',
  styleUrls: ['./chat-feedback-dialog.component.scss'],
})
export class ChatFeedbackDialogComponent {
  feedbackForm: FormGroup;

  accuracyOptions = [
    { value: 0, label: 'Not sure' },
    { value: 1, label: 'Not correct at all' },
    { value: 2, label: 'Partially correct' },
    { value: 3, label: 'Mostly correct' },
    { value: 4, label: 'Entirely correct' },
  ];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ChatFeedbackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.feedbackForm = this.fb.group({
      queryCorrect: [false],
      queryClose: [false],
      feedback: [''],
      accuracy: [0],
    });

    this.feedbackForm.get('queryCorrect')?.valueChanges.subscribe((value) => {
      if (value) {
        this.feedbackForm.patchValue({
          queryClose: true,
        });
      }
    });
  }

  onSubmit() {
    if (this.feedbackForm.valid) {
      this.dialogRef.close(this.feedbackForm.value);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
