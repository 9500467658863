import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-config-info',
  template: `
  <div class="tw-flex tw-items-center tw-text-gray-800 dark:tw-text-gray-100 tw-mb-2">
    <p class="tw-flex tw-flex-col tw-w-full">
      <span class="tw-font-semibold tw-mr-2">{{itemName}}: </span>
      <span class="tw-inline-block code-font-direct tw-break-words tw-text-wrap">
        {{itemValue}}
      </span>
    </p>
    <button 
      mat-icon-button 
      (click)="copyToClipboard(itemValue)"
      matTooltip="Copy {{itemName}}"
      matTooltipPosition="above"
      class="tw-text-gray-500 hover:tw-text-gray-800 dark:tw-text-white dark:hover:tw-text-gray-200 tw-ml-2 tw-p-0 tw-mb-[-18px]">
        <mat-icon>content_copy</mat-icon>
    </button>
  </div>
  `,
  styles: [],
})
export class ConfigInfoComponent {
  @Input() itemName: string = '' || 'Item Name';
  @Input() itemValue: string = '' || 'Item Value';

  constructor(
    private clipboard: Clipboard,
    private notify: NotificationService
  ) {}

  copyToClipboard(value: string) {
    this.clipboard.copy(value);
    this.notify.success(`Copied ${this.itemName} to clipboard!`);
  }
}
