import { Component } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { AdminService, Destination, ListAllDestinationsRequest } from 'ldt-data-deliveries-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';

@Component({
  selector: 'app-destinations',
  templateUrl: './destinations.component.html',
  styleUrls: ['./destinations.component.scss'],
})
export class DestinationsComponent {
  refreshing: boolean = false;

  constructor(
    private destinationsService: AdminService,
    private notify: NotificationService,
    public darkModeService: DarkModeService
  ) {}

  ngOnInit(): void {
    this.getDestinations();
  }

  /// AG-GRID to display all destination types --------------
  public destinations: Destination[];
  public colDefs: ColDef[] = [
    { field: 'id', maxWidth: 60 },
    { field: 'org_id', maxWidth: 110 },
    { field: 'name' },
    {
      field: 'destination_type.display_name',
      headerName: 'Type',
      maxWidth: 80,
      filter: 'agSetColumnFilter',
    },
    { field: 'status', filter: 'agSetColumnFilter' },
    { field: 'created_at', maxWidth: 230 },
    { field: 'deleted_at', maxWidth: 230 },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    flex: 1,
  };

  getDestinations(): void {
    this.refreshing = true;
    let req: ListAllDestinationsRequest = {
      size: 1000,
    };
    this.destinationsService.listAllDestinations(req).subscribe({
      next: (data) => {
        this.destinations = data.items;
        this.refreshing = false;
        if (data.pagination_token) {
          this.notify.warning('Only first 1000 destinations are displayed, but there are more!');
        }
      },
      error: (error) => {
        this.notify.error('Error fetching destinations');
        this.refreshing = false;
      },
    });
  }
}
