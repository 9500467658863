import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-identity-org-create-dialog',
  templateUrl: './identity-org-create-dialog.component.html',
  styleUrls: ['./identity-org-create-dialog.component.scss'],
})
export class IdentityOrgCreateDialogComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<IdentityOrgCreateDialogComponent>
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      orgName: ['', [Validators.required, Validators.minLength(2)]],
      userEmail: ['', [Validators.email]],
      contactLimit: ['500', []],
      moneyballCapability: [false, []],
      ledgerCapability: [true, []],
      jobsCapability: [false, []],
      peopleCapability: [false, []],
    });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }
}
