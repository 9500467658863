import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InvitationsService, Org } from 'ldt-identity-service-api';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/service/auth.service';
import { NotificationService } from '../shared/notification-service/notification.service';
import { InvitationsCreateDialogComponent } from './invitations-create-dialog/invitations-create-dialog.component';
import { TeamInvitationsComponent } from './team-invitations/team-invitations.component';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
})
export class TeamComponent implements OnInit {
  @ViewChild(TeamInvitationsComponent, { static: true }) inviteComponent: TeamInvitationsComponent;

  org: Org;
  canAdmin: boolean = false;

  constructor(
    private router: Router,
    private invService: InvitationsService,
    public dialog: MatDialog,
    private auth: AuthService,
    private notifyService: NotificationService,
    private clipboard: ClipboardService
  ) {
    const thisOrg = this.auth.getSelectedOrg();
    if (!thisOrg) {
      // In theory this should never happen cause if there are no orgs the OrgsGuard will redirect to a diff page
      this.router.navigateByUrl('/main');
    } else {
      this.org = thisOrg;
    }
    this.canAdmin = this.auth.userHasRole('admin');
  }

  async ngOnInit() {}

  // handle the invite dialog form submission
  showInviteDialog(evt?: MouseEvent) {
    evt?.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    const dialogRef = this.dialog.open(InvitationsCreateDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.emails && Array.isArray(data.emails)) {
        // Confirm it's an array
        data.emails.forEach((email: string) => {
          const body = {
            inviteeEmail: email.trim(),
            inviteeRole: data.role,
          };

          this.invService.createInvitation(this.org.id, body).subscribe({
            next: (r) => {
              this.notifyService.success(email + ' successfully invited.');
              if (this.inviteComponent) {
                this.inviteComponent.refreshOrgInvitations();
              }
            },
            error: (err) => {
              if (err.status && err.status === 409) {
                this.notifyService.error('User already has access to this org');
              } else {
                this.notifyService.error('Failed to invite ' + email + '. Please try again later.');
              }
            },
          });
        });
      }
    });
  }

  onIdClick() {
    this.clipboard.copyFromContent(this.org.id);
    this.notifyService.success('Copied Org ID to clipboard');
  }
}
