<a routerLink="/admin/identity" class="tw-text-blue-600 hover:tw-text-blue-800 hover:tw-underline">
  Back to Identity Admin
</a>
<div *ngIf="org">
  <div class="content-title">{{org.name}} ({{org.id}})</div>
  <div class="content-description tw-mt-3">
    <strong>Created:</strong> {{org.createdAt | date:'medium'}}
  </div>
  <hr />
  <app-org-settings-editor [initialSettings]="org.settings" #settingsForm>
  </app-org-settings-editor>
  <div class="tw-flex tw-flex-row tw-gap-4">
    <button mat-raised-button color="primary" type="button" (click)="toggleOrgActive()">
      {{org.status === 'active' ? 'Deactivate' : 'Activate'}} Organization
    </button>
    <button mat-raised-button color="primary" type="button" (click)="updateOrgSettings()">
      Save Settings
    </button>
    <button mat-raised-button color="primary" type="button" (click)="observeOrg()">Observe</button>
  </div>
</div>

<hr />
<div class="section-header">Users</div>
<div class="actionContainer">
  <button mat-raised-button (click)="showInviteDialog()">Invite users</button>
  <button mat-raised-button color="warn" [disabled]="!rowsSelected" (click)="removeSelectedUsers()">
    Remove Selected Users
  </button>
</div>
<div class="container" width="100%">
  <ag-grid-angular
    style="height: 100%;"
    [ngClass]="{ 'ag-theme-balham': !(darkModeService.darkMode$ | async), 'ag-theme-balham-dark': darkModeService.darkMode$ | async }"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    accentedSort="true"
    [components]="components"
    [rowSelection]="rowSelection"
    [defaultColDef]="defaultColDef"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="onSelectionChanged()">
  </ag-grid-angular>
</div>

<div class="tw-mt-4">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Manual Add </mat-panel-title>
        <mat-panel-description> For advanced users only </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="tw-flex tw-gap-4 tw-items-center">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Email addresses</mat-label>
          <input matInput placeholder="Emails to add" [(ngModel)]="emails" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Role</mat-label>
          <mat-select [(ngModel)]="role">
            <mat-option name="role" *ngFor="let role of roles" [value]="role">
              {{role}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button [disabled]="!emails && !role" (click)="addUsers()">
          Add users
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
