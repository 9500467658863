import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColDef, ColGroupDef, GridApi, GridReadyEvent, IGetRowsParams } from 'ag-grid-community';
import { Ledger } from 'ldt-ledger-service-api';
import { BehaviorSubject, Observable, Subject, take, takeUntil, timer } from 'rxjs';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { LedgerJobCellRendererComponent } from '../ledger-contacts/ledger-job-cell-renderer/ledger-job-cell-renderer.component';
import {
  LedgerContactsService as LedgerContactsServiceV2,
  Contact as ContactV2,
  ContactSearchFilter,
  SearchLedgerContactsRequest,
  SearchLedgerContacts200Response,
  LedgersService,
} from 'ldt-ledger-service-api-v2';

import { NgLedgerService } from '../ledger.service';
import { GetLedgerById200Response } from 'ldt-ledger-service-api-v2/model/getLedgerById200Response';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';

@Component({
  selector: 'app-ledger-overview-v2',
  templateUrl: './ledger-overview-v2.component.html',
  styleUrls: ['./ledger-overview-v2.component.scss'],
  providers: [DatePipe],
})
export class LedgerOverviewV2Component implements OnInit {
  @Input() ledger: Ledger;
  @Input() orgId: string;
  @Input() importsInProgress$: BehaviorSubject<any>;

  changeCount: number;
  verifiedCount: number;
  contactCount: number;
  $ledgerApiV2: Observable<boolean>;
  apiV2: boolean = false;

  private stopPolling = new Subject<void>();

  constructor(
    private notify: NotificationService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private ngLedgerService: NgLedgerService,
    private ledgerContactsV2: LedgerContactsServiceV2,
    private ledgersServiceV2: LedgersService,
    public darkModeService: DarkModeService
  ) {}

  ngOnInit(): void {
    // Get the ledger from the resolver and org ID from the route
    this.ledger = this.route.parent?.snapshot.data.userdata;
    let routeOrg = this.route.parent?.snapshot.paramMap.get('orgId');
    if (routeOrg) {
      this.orgId = routeOrg;
    }

    this.refreshData();
  }

  refreshData() {
    this.ledgersServiceV2.getLedgerById(this.orgId, this.ledger.id).subscribe({
      next: (res: GetLedgerById200Response) => {
        this.contactCount = res.contact_counts.total;
        this.changeCount = res.contact_counts.new_jobs;
        this.verifiedCount = res.contact_counts.verified;
      },
      error: (err) => {
        this.contactCount = this.changeCount = this.verifiedCount = 0;
        if (err.status === 404) {
          timer(10000)
            .pipe(takeUntil(this.stopPolling), take(1))
            .subscribe(() => {
              this.refreshData();
            });
          return;
        }

        this.notify.error('Oops. There was an error getting the data. Please try again later.');
      },
    });

    if (this.gridApi) {
      this.gridApi.purgeInfiniteCache();
    }
  }

  setLedgerVersion(evt: any) {
    this.ngLedgerService.setApiVersion(evt.checked);
  }

  paginationToken: string | undefined;
  getRows(params: IGetRowsParams): any {
    this.gridApi.showLoadingOverlay();

    const size = (params.endRow || 0) - (params.startRow || 0);

    // If the grid is back at the top, reset the pagination token
    if (params.startRow === 0) {
      delete this.paginationToken;
    }

    var sortBy = undefined;
    if (params.sortModel.length > 0) {
      sortBy = params.sortModel[0].colId;
      if (params.sortModel[0].sort == 'desc') {
        sortBy = '-' + sortBy;
      }
    }

    let searchRequest: SearchLedgerContactsRequest = {
      filters: [
        {
          field: 'last_company_changed_at',
          match_type: ContactSearchFilter.MatchTypeEnum.Exists,
          type: ContactSearchFilter.TypeEnum.Must,
        },
      ],
      size: size,
      pagination_token: this.paginationToken,
      sort_by: sortBy,
    };

    this.ledgerContactsV2
      .searchLedgerContacts(this.ledger.id, this.orgId, searchRequest)
      .subscribe({
        next: (res: SearchLedgerContacts200Response) => {
          this.paginationToken = res.pagination_token || undefined;
          params.successCallback(res.contacts as ContactV2[], res.count);
          this.gridApi.hideOverlay();
        },
        error: () => {
          params.failCallback();
          this.notify.error('Oops. There was an error getting the data.');
        },
      });
  }

  ngOnDestroy() {
    this.stopPolling.next();
  }

  ///////////////////////////////
  //////// A G GRID /////////////
  ///////////////////////////////

  private gridApi: GridApi;
  rowModelType: any = 'infinite';
  infiniteInitialRowCount = 100;
  cacheBlockSize = 500;
  rowSelection = 'none';
  components = {
    loadingRenderer: function (params: any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
      }
    },
  };
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    params.api.setDatasource(this);
  }

  defaultColDef = {
    sortable: false,
    filter: false,
    floatingFilter: false,
    resizable: true,
    flex: 1,
    minWidth: 100,
    enablePivot: false,
    menuTabs: [],
  };
  columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'last_company_changed_at',
      headerName: 'Company Change Detected',
      sort: 'desc',
      maxWidth: 210,
      menuTabs: [],
      filter: false,
      valueFormatter: (params: any) => {
        return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || '';
      },
      headerTooltip:
        'The last time (in UTC) Live Data updated this persons job on this ledger. A blank value indicates there has been no change from the imported data. The filter shows those that were changed on or after the provided date.',
    },
    {
      headerName: 'Person',
      field: 'name',
      cellRenderer: LedgerJobCellRendererComponent,
      maxWidth: 275,
      autoHeight: true,
    },
    {
      headerName: 'Current Job',
      field: 'current_position.company.name',
      cellRenderer: LedgerJobCellRendererComponent,
      autoHeight: true,
    },
    {
      headerName: 'Imported Job',
      field: 'imported.company',
      cellRenderer: LedgerJobCellRendererComponent,
      autoHeight: true,
    },
  ];
  rowData: ContactV2[];
  rowCount: any = null;
  rowsSelected = false;
  tooltipShowDelay = 200;
}
