<div class="dialog-header tw-flex tw-items-center tw-gap-3 tw-px-6 tw-pt-5">
  <h2 class="tw-text-2xl tw-leading-relaxed tw-text-dark-light-dynamic tw-my-0">Feedback Form</h2>
  <mat-icon class="tw-text-dark-light-dynamic">rate_review</mat-icon>
</div>

<mat-dialog-content id="feedback-dialog-content">
  <h3 class="tw-text-dark-light-dynamic">Help train our workforce AI model</h3>
  <form [formGroup]="feedbackForm">
    <div class="tw-flex tw-flex-col tw-gap-1">
      <label id="accuracy-label"> How accurate were the filters created from your prompt? </label>
      <mat-radio-group
        formControlName="accuracy"
        aria-labelledby="accuracy-label"
        class="tw-flex tw-flex-col tw-gap-3 tw-py-3">
        <mat-radio-button
          *ngFor="let acc of accuracyOptions"
          [value]="acc.value"
          class="example-radio-button">
          {{acc.label}}
        </mat-radio-button>
      </mat-radio-group>

      <mat-form-field appearance="outline" class="full-width tw-mt-2">
        <mat-label>Feedback</mat-label>
        <textarea matInput formControlName="feedback" rows="3"></textarea>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="tw-px-6 tw-pt-0 tw-pb-5">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onSubmit()">Submit</button>
</mat-dialog-actions>
