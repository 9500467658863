import { Component, OnInit, HostBinding } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as Highcharts from 'highcharts';
import {
  CompaniesService,
  CreateStatsReportRequest,
  CreateEmployeeDemoReport200ResponseInner,
  CreateEmployeeDemoReportRequest,
  FindCompanyRequest,
  FindCompany200Response,
  Company,
  CreateArrivalDepartureReportRequest,
  CreateArrivalDepartureReport200ResponseInner,
  CreateEmployeeTenureReportRequest,
  CreateEmployeeTenureReport200ResponseInner,
  DownloadEmployeeDemoReportRequest,
} from 'ldt-moneyball-api';
import { QueryDwRequest, SearchService } from 'ldt-dw-reader-service-api';
import { forkJoin, Observable, timer } from 'rxjs';
import HC_sankey from 'highcharts/modules/sankey';
import HC_map from 'highcharts/modules/map';
import HC_exportData from 'highcharts/modules/export-data';
import HC_exporting from 'highcharts/modules/exporting';
import HC_offlineExport from 'highcharts/modules/offline-exporting';
import HC_treemap from 'highcharts/modules/treemap';
import { NotificationService } from '../shared/notification-service/notification.service';
HC_sankey(Highcharts);
HC_map(Highcharts);
HC_exportData(Highcharts);
HC_treemap(Highcharts);
HC_exporting(Highcharts);
HC_offlineExport(Highcharts);
var us_map = require('@highcharts/map-collection/countries/us/us-all.topo.json');
import * as dayjs from 'dayjs';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { MoneyballService } from './moneyball.service';
import { ActivatedRoute, Router } from '@angular/router';
import { compressToEncodedURIComponent } from 'lz-string';
import { AuthService } from '../auth/service/auth.service';
import {
  BattleUrlSettings,
  serializeBattleUrlSettings,
} from '../moneyball-battle/moneyball-battle.component';
import { SelectedCompany } from '../shared/company-search/company-search.component';
import { CompanyInfoService } from '../shared/company-search/company-info-service.service';
import { DarkModeService } from '../shared/dark-mode/dark-mode.service';
import { lightTheme, darkTheme } from '../shared/highcharts-themes';

declare global {
  interface String {
    toHexA(opacity: number): String;
  }

  interface Date {
    asMonthAndYear(): any;
  }
}

String.prototype.toHexA = function (opacity: number = 1): String {
  let alpha = Math.round(opacity * 255).toString(16);
  return this + alpha;
};

Date.prototype.asMonthAndYear = function (): any {
  return this.toLocaleDateString('en-us', { year: 'numeric', month: 'short', timeZone: 'UTC' });
};

interface SearchItem {
  type: string;
  company: Company;
}

@Component({
  selector: 'app-moneyball',
  templateUrl: './moneyball.component.html',
  styleUrls: ['./moneyball.component.scss'],
})
export class MoneyballComponent implements OnInit {
  @HostBinding('class') className = 'moneyball';
  // The first list is sorted by frequency in the DW and is used when picking
  //  colors for charts. The 2nd list is sorted for display
  jobLevels = ['Staff', 'Manager', 'C-Team', 'Director', 'VP', 'Consultant', 'Other'];
  sortedLevels = ['C-Team', 'VP', 'Director', 'Manager', 'Staff', 'Consultant', 'Other'];

  departments = [
    'Business Management',
    'Sales and Support',
    'Engineering',
    'Finance and Administration',
    'Operations',
    'Marketing and Product',
    'Healthcare',
    'Education',
    'Human Resources',
    'Information Technology',
    'Program and Project Management',
    'Legal',
    'Banking and Wealth Management',
    'Consulting',
    'Risk, Safety, Compliance',
    'Quality',
    'Real Estate',
    'Publishing, Editorial and Reporting',
    'Other',
  ];

  arrivalsDeparturesChartOptions = ['Department', 'Job Level'];

  employeeCountChartOptions = ['Department', 'Job Level'];

  historicalTenureChartOptions = ['Month', 'Department', 'Job Level'];

  currentTenureChartOptions = ['Previous Company', 'School', 'Department', 'Job Level'];

  tenureChartStatusOptions = ['Departed', 'Active'];

  // Ideally we'd use the `var` keyword here, but that doesn't work with the export for highcharts
  colors: any[] = [
    // colors from BattleComponent
    '#FF5733', // orange-400 (darker orange)
    '#FFCA3A', // amber-300
    '#8AC926', // lime-400
    '#1982C4', // blue-600
    '#6A4C93', // purple-500
    '#FFB5A7', // light pink (custom)
    '#FFD166', // amber-300 (yellow)
    '#73D2DE', // cyan-300 (soft cyan)
    '#F4A261', // orange-300
    '#E76F51', // dark orange
    // additional colors from TailwindCSS (need more testing)
    '#3B82F6', // blue-500
    '#F87171', // red-400
    '#FBBF24', // yellow-400
    '#34D399', // green-400
    '#818CF8', // indigo-400
    '#E74694', // pink-500
    '#A5B4FC', // indigo-300
    '#60A5FA', // blue-400
    '#F59E0B', // amber-400
    '#D97706', // amber-600
    '#D69E2E', // yellow-600
    '#EAB308', // yellow-500 (might be too close)
    '#F97316', // orange-500
    '#EF4444', // red-500
    '#D946EF', // fuchsia-500
    '#BE185D', // rose-700
    '#9333EA', // purple-600
  ];
  employeeCountColor = '#3F83F8'; // blue-500
  tenureBaseColor = '#9061F9'; // purple-500
  oneMonth: boolean = false;

  // Companies selected in the company picker
  selectedCompany: Company | undefined;
  selectedCompanyGroups: boolean = false;

  // Companies that we are currently showing results for
  resultsCompany: Company;
  filePrefix: string = 'Moneyball';
  minTenureSampleSize: number = 3;
  maxCompanySankeyItems: number = 15; // MAX 15 or the colors will break since we only have 30 colors
  top10ChartSize: number = 10;
  currentTenureMaxDataPoints: number = 20;
  maxSelectedCompanies: number = 20;
  companyHero: string = '';
  loading: boolean = false;
  showCharts: boolean = false;
  arrDepDownloading: boolean = false;
  empCountDownloading: boolean = false;
  recentSearches: SearchItem[] = [];
  recentSearchesLimit: number = 10;
  orgId: string;

  searchCompanies: Observable<SearchItem[]>;

  selectedArrivalsDeparturesChartOption = new UntypedFormControl('Department');
  selectedEmployeeCountChartOption = new UntypedFormControl('Department');
  selectedHistoricalTenureChartOption = new UntypedFormControl('Month');
  selectedCurrentTenureChartOption = new UntypedFormControl('Previous Company');
  selectedTenureChartStatusOption = new UntypedFormControl('Departed');

  monthLabels: any[] = [];

  selectedDates: { startDate: any; endDate: any } = {
    startDate: new Date('2020-01-01 12:00:00'),
    endDate: new Date(),
  };
  startDateDate: any;
  endDateDate: any;
  ranges: any = {
    'Last 6 Months': [moment().subtract(6, 'months').startOf('month'), moment()],
    'Year to Date': [moment().startOf('year'), moment()],
    'Last 12 Months': [moment().subtract(12, 'months').startOf('month'), moment()],
    'Last 2 Years': [moment().subtract(2, 'years').startOf('year'), moment()],
    'Last 5 Years': [moment().subtract(5, 'years').startOf('year'), moment()],
  };

  currentEmployeeCount: number | undefined;
  allTimeEmployeeCount: number | undefined;
  groupedCompanies: Company[] = [];

  Highcharts: typeof Highcharts = Highcharts; // required

  // Cache all series that we get from the API so we don't have to re-fetch them
  seriesCache: any = {};

  treeChartOptions: any = {};

  charts: any = {};
  logChartInstance(chart: Highcharts.Chart, id: string) {
    this.charts[id] = chart;
  }

  jobLevelIndexer(level: any) {
    return this.sortedLevels.indexOf(level);
  }

  departmentIndexer(dept: any) {
    return this.departments.indexOf(dept);
  }

  deepCopy(obj: any) {
    // Can sometimes be called with `undefined`. Squash the error
    return obj ? JSON.parse(JSON.stringify(obj)) : obj;
  }

  byTypeConfigs: any = {
    department: [this.departments, this.departmentIndexer.bind(this)],
    jobLevel: [this.sortedLevels, this.jobLevelIndexer.bind(this)],
  };

  showMoreCompanies: boolean = false;
  displayCompaniesLimit: number = 4;
  displayedCompanies: any[] = [];

  constructor(
    private notify: NotificationService,
    private companiesService: CompaniesService,
    private mbService: MoneyballService,
    private route: ActivatedRoute,
    private router: Router,
    private dwService: SearchService,
    private auth: AuthService,
    private companyInfoService: CompanyInfoService,
    public darkModeService: DarkModeService
  ) {
    // Set global Highcharts options
    Highcharts.setOptions({
      credits: {
        enabled: false,
      },
      chart: {
        alignTicks: false,
      },
      title: {
        text: '',
      },
      lang: {
        decimalPoint: '.',
        thousandsSep: ',',
      },
    });
  }

  isAdmin: any;
  ngOnInit(): void {
    this.isAdmin = this.auth.$isAdmin;

    let orgId = this.route.parent?.snapshot.paramMap.get('orgId');
    if (!orgId) {
      orgId = this.auth.getSelectedOrgIdValue;
      if (!orgId) {
        this.notify.error('Invalid path');
        this.router.navigateByUrl('/main');
      }
    }
    this.orgId = orgId;

    this.loadRecentSearches();
    let saved = JSON.parse(localStorage.getItem('moneyball-search-terms') || '{}');
    this.selectedDates.startDate = new Date(saved?.startDate || '2022-01-01 12:00:00');
    this.selectedDates.endDate = new Date(saved?.endDate || new Date());

    this.route.queryParams.subscribe((params) => {
      if ('id' in params && params['id'].startsWith('LDC-')) {
        const req: FindCompanyRequest = {
          search_value: params['id'],
          search_field: FindCompanyRequest.SearchFieldEnum.Id,
        };

        this.companyInfoService.getCompanyInfo(params['id']).subscribe({
          next: (res: Company | undefined) => {
            if (!res) {
              this.notify.error('Error loading selected company. Try another one.');
              return;
            }

            const group: boolean = params['group'] === 'true';
            this.gotoCompany(res, group);
          },
          error: () => {
            this.notify.error('Error loading company data');
          },
        });
      }
    });

    // Initialize theme on load
    this.applyChartTheme(this.darkModeService.currentDarkModeValue);
  }

  applyChartTheme(isDarkMode: boolean) {
    Highcharts.setOptions(isDarkMode ? darkTheme : lightTheme);
  }

  loadRecentSearches() {
    const localStorageItems = JSON.parse(localStorage.getItem('moneyball-recent-searches') || '[]');

    // Check each item in localStorage and convert it to a 'SearchItem' if it isn't already
    let anyFound = false;
    for (const item of localStorageItems) {
      if (!('company' in item && 'type' in item)) {
        anyFound = true;

        const searchItem: SearchItem = {
          type: 'company',
          company: item,
        };

        // Replace the old item with the new 'SearchItem' in localStorage
        const itemIndex = localStorageItems.indexOf(item);
        localStorageItems[itemIndex] = searchItem;
      }
    }

    if (anyFound) {
      localStorage.setItem('moneyball-recent-searches', JSON.stringify(localStorageItems));
    }

    this.recentSearches = localStorageItems;
  }

  saveRecentSearch(item: SearchItem) {
    if (
      this.recentSearches.find(
        (i: SearchItem) => i.company.id === item.company.id && i.type === item.type
      )
    ) {
      return;
    }
    this.recentSearches.unshift(item);
    this.recentSearches = this.recentSearches.slice(0, this.recentSearchesLimit);
    localStorage.setItem('moneyball-recent-searches', JSON.stringify(this.recentSearches));
  }

  arrDepChartOptionChange(c: any) {
    const chart: Highcharts.Chart = this.charts['arrivalsDeparturesChart'];

    // No idea why, but this has to be done to make the chart redraw the new data correctly
    chart.update({ series: [] }, true, true);

    switch (c.value) {
      case 'Department':
        chart.update(
          {
            series: this.seriesCache['arrivalsDeparturesByDeptSeries'].concat(
              this.seriesCache['employeeCountByMonthSeries']
            ),
          },
          true,
          true
        );
        break;
      case 'Job Level':
        chart.update(
          {
            series: this.seriesCache['arrivalsDeparturesByLevelSeries'].concat(
              this.seriesCache['employeeCountByMonthSeries']
            ),
          },
          true,
          true
        );
        break;
    }
  }

  empCountOptionChange(c: any) {
    const chart: Highcharts.Chart = this.charts['employeeCountByOptions'];

    // No idea why, but this has to be done to make the chart redraw the new data correctly
    chart.update({ series: [] }, true, true);

    switch (c.value) {
      case 'Department':
        chart.update({ series: this.seriesCache['employeeCountByDeptSeries'] }, true, true);
        break;
      case 'Job Level':
        chart.update({ series: this.seriesCache['employeeCountByLevelSeries'] }, true, true);
        break;
    }
  }

  // When someone changes either dropdown for the tenure chart, we figure out which combo
  // is selected and then go get the data if we haven't already. Then render the chart
  historicalTenureChartOptionChange() {
    const chart: Highcharts.Chart = this.charts['tenureByMonthChart'];

    // No idea why, but this has to be done to make the chart redraw the new data correctly
    chart.update({ series: [] }, true, true);
    this.showChartLoading('tenureByMonthChart');

    const field = this.selectedHistoricalTenureChartOption.value;
    const status = this.selectedTenureChartStatusOption.value;

    if (field === 'Month' && status === 'Departed') {
      // Nothing to go get - this is loaded on page load
      this.renderTenureCharts();
    } else if (field === 'Month' && status === 'Active') {
      if (!('tenureByMonthCurrentSeries' in this.seriesCache)) {
        this.getHistoricalTenureActiveData().subscribe({
          next: (res: CreateEmployeeTenureReport200ResponseInner[]) => {
            this.seriesCache['tenureByMonthCurrentSeries'] = this.generateTenureByMonthSeries(res);
            this.renderTenureCharts();
          },
          error: () => {
            this.showChartError('tenureByMonthChart');
          },
        });
      } else {
        this.renderTenureCharts();
      }
    } else if (field === 'Department' && status === 'Departed') {
      if (!('tenureByDeptSeries' in this.seriesCache)) {
        this.getHistoricalTenureByDeptData().subscribe({
          next: (res: CreateEmployeeTenureReport200ResponseInner[]) => {
            this.seriesCache['tenureByDeptSeries'] = this.generateTenureByGroupSeries(
              res,
              'department'
            );
            this.renderTenureCharts();
          },
          error: () => {
            this.showChartError('tenureByMonthChart');
          },
        });
      } else {
        this.renderTenureCharts();
      }
    } else if (field === 'Department' && status === 'Active') {
      if (!('tenureByDeptCurrentSeries' in this.seriesCache)) {
        this.getHistoricalTenureByDeptActiveData().subscribe({
          next: (res: CreateEmployeeTenureReport200ResponseInner[]) => {
            this.seriesCache['tenureByDeptCurrentSeries'] = this.generateTenureByGroupSeries(
              res,
              'department'
            );
            this.renderTenureCharts();
          },
          error: () => {
            this.showChartError('tenureByMonthChart');
          },
        });
      } else {
        this.renderTenureCharts();
      }
    } else if (field === 'Job Level' && status === 'Departed') {
      if (!('tenureByLevelSeries' in this.seriesCache)) {
        this.getHistoricalTenureByLevelData().subscribe({
          next: (res: CreateEmployeeTenureReport200ResponseInner[]) => {
            this.seriesCache['tenureByLevelSeries'] = this.generateTenureByGroupSeries(
              res,
              'jobLevel'
            );
            this.renderTenureCharts();
          },
          error: () => {
            this.showChartError('tenureByMonthChart');
          },
        });
      } else {
        this.renderTenureCharts();
      }
    } else if (field === 'Job Level' && status === 'Active') {
      if (!('tenureByLevelCurrentSeries' in this.seriesCache)) {
        this.getHistoricalTenureByLevelActiveData().subscribe({
          next: (res: CreateEmployeeTenureReport200ResponseInner[]) => {
            this.seriesCache['tenureByLevelCurrentSeries'] = this.generateTenureByGroupSeries(
              res,
              'jobLevel'
            );
            this.renderTenureCharts();
          },
          error: () => {
            this.showChartError('tenureByMonthChart');
          },
        });
      } else {
        this.renderTenureCharts();
      }
    }
  }

  currentTenureChartOptionChange() {
    const chart: Highcharts.Chart = this.charts['currentTenureChart'];

    // No idea why, but this has to be done to make the chart redraw the new data correctly
    chart.update({ series: [] }, true, true);
    this.showChartLoading('currentTenureChart');

    const field = this.selectedCurrentTenureChartOption.value;

    if (field === 'Department') {
      if (!('currentTenureByDeptSeries' in this.seriesCache)) {
        this.getCurrentTenureByDeptData().subscribe({
          next: (res: CreateEmployeeTenureReport200ResponseInner[]) => {
            this.seriesCache['currentTenureByDeptSeries'] =
              this.generateTenureCurrentItemsSeries(res);
            this.renderCurrentTenureCharts();
          },
          error: () => {
            this.showChartError('tenureByMonthChart');
          },
        });
      } else {
        this.renderCurrentTenureCharts();
      }
    } else if (field === 'Job Level') {
      if (!('currentTenureByLevelSeries' in this.seriesCache)) {
        this.getCurrentTenureByLevelData().subscribe({
          next: (res: CreateEmployeeTenureReport200ResponseInner[]) => {
            this.seriesCache['currentTenureByLevelSeries'] =
              this.generateTenureCurrentItemsSeries(res);
            this.renderCurrentTenureCharts();
          },
          error: () => {
            this.showChartError('tenureByMonthChart');
          },
        });
      } else {
        this.renderCurrentTenureCharts();
      }
    } else if (field === 'Previous Company') {
      if (!('tenureByPrevSeries' in this.seriesCache)) {
        this.getCurrentTenureByPreviousCompanyData().subscribe({
          next: (res: CreateEmployeeTenureReport200ResponseInner[]) => {
            this.seriesCache['tenureByPrevSeries'] = this.generateTenureCurrentItemsSeries(res);
            this.renderCurrentTenureCharts();
          },
          error: () => {
            this.showChartError('tenureByMonthChart');
          },
        });
      } else {
        this.renderCurrentTenureCharts();
      }
    } else if (field === 'School') {
      if (!('tenureBySchoolSeries' in this.seriesCache)) {
        this.getCurrentTenureBySchoolData().subscribe({
          next: (res: CreateEmployeeTenureReport200ResponseInner[]) => {
            this.seriesCache['tenureBySchoolSeries'] = this.generateTenureCurrentItemsSeries(res);
            this.renderCurrentTenureCharts();
          },
          error: () => {
            this.showChartError('tenureByMonthChart');
          },
        });
      } else {
        this.renderCurrentTenureCharts();
      }
    }
  }

  companySelected(event: SelectedCompany) {
    if (!event) return;
    if (!event.company) return;

    let url = '/' + this.orgId + '/moneyball?id=' + event.company.id;
    if (event.type === 'group') {
      url += '&group=true';
    }
    this.router.navigateByUrl(url);
  }

  gotoCompany(c: Company, g: boolean): void {
    this.selectedCompany = c;
    this.selectedCompanyGroups = g;

    this.saveRecentSearch({ company: c, type: g ? 'group' : 'company' });

    // Let the UI thread render so our charts show up before we start populating them
    timer(0).subscribe(() => {
      this.getResults();
    });
  }

  getResults() {
    if (!this.selectedCompany) return;
    this.resultsCompany = this.selectedCompany!;
    const newCompanyHero = this.resultsCompany.name;
    this.filePrefix = this.resultsCompany.name.slice(0, 15).replace(/[^a-zA-Z0-9]/g, '-');

    localStorage.setItem(
      'moneyball-search-terms',
      JSON.stringify({
        startDate: this.startDateDate,
        endDate: this.endDateDate,
      })
    );

    this.selectedArrivalsDeparturesChartOption.setValue('Department');
    this.selectedEmployeeCountChartOption.setValue('Department');
    this.selectedHistoricalTenureChartOption.setValue('Month');
    this.selectedTenureChartStatusOption.setValue('Departed');
    this.selectedCurrentTenureChartOption.setValue('Previous Company');

    this.seriesCache = {};
    this.loading = true;
    this.showCharts = false;
    this.companyHero = newCompanyHero;
    this.currentEmployeeCount = undefined;
    this.allTimeEmployeeCount = undefined;
    this.groupedCompanies = [];

    this.cleanupDates();
    this.getReports();
  }

  updateDisplayedCompanies(): void {
    if (this.showMoreCompanies) {
      this.displayedCompanies = this.groupedCompanies ? this.groupedCompanies : [];
    } else {
      this.displayedCompanies = this.groupedCompanies
        ? this.groupedCompanies.slice(0, this.displayCompaniesLimit)
        : [];
    }
  }

  toggleShowMore() {
    this.showMoreCompanies = !this.showMoreCompanies;
    this.updateDisplayedCompanies();
  }

  onDatesChanged() {
    this.cleanupDates();

    const diffInMonths = Math.abs(
      moment(this.startDateDate).diff(moment(this.endDateDate), 'months')
    );
    const areWithinOneMonth = diffInMonths < 1;
    areWithinOneMonth ? (this.oneMonth = true) : (this.oneMonth = false);
  }

  showPeople() {
    const query = {
      query: {
        bool: {
          must: [
            {
              term: {
                'company.id.keyword': {
                  value: this.selectedCompany?.id,
                },
              },
            },
            {
              range: {
                started_at: {
                  gte: moment(this.startDateDate).format('YYYY-MM-DD'),
                  lte: moment(this.endDateDate).format('YYYY-MM-DD'),
                },
              },
            },
          ],
          should: [
            { range: { ended_at: { gt: moment(this.endDateDate).format('YYYY-MM-DD') } } },
            { bool: { must_not: { exists: { field: 'ended_at' } } } },
          ],
          minimum_should_match: 1,
        },
      },
      size: 10000,
    };
    let req: QueryDwRequest = {
      query: query,
      index: 'moneyball',
    };
    this.dwService.queryDw(req).subscribe({
      next: (res: any) => {
        const filter = {
          id: {
            filter: res.hits.hits.map((h: any) => h._source.id.split('#')[0]).join(','),
            filterType: 'text',
            type: 'equals',
          },
        };

        // In the source component:
        this.router.navigate(['/datawarehouse/details'], {
          queryParams: { filter: compressToEncodedURIComponent(JSON.stringify(filter)) },
        });
      },
      error: (err: any) => {
        this.notify.error('Error searching for people. Try again later.');
      },
    });
  }

  onMinTenureSampleSizeChanged(e: any) {
    this.minTenureSampleSize = e.value;
    this.renderTenureCharts();
  }

  cleanupDates() {
    // Ensure we have good dates to use for queries and logic
    // We initialize selecteDates with Date objects, but after a user interacts with the picker,
    // the component changes them to DayJS objects, which have the date in $d
    if (this.selectedDates.startDate.$d) {
      this.startDateDate = this.selectedDates.startDate.$d;

      // If the user didn't set an end date, set it back to what it was before
      if (!this.selectedDates.endDate.$y) {
        this.selectedDates = {
          startDate: this.selectedDates.startDate,
          endDate: dayjs(this.endDateDate),
        };
      }

      this.endDateDate = this.selectedDates.endDate.$d;
    } else {
      this.startDateDate = this.selectedDates.startDate;
      this.endDateDate = this.selectedDates.endDate;
    }
  }

  clearAllCharts() {
    Object.keys(this.charts).forEach((key: string) => {
      const chart: Highcharts.Chart = this.charts[key];
      chart.update(
        {
          series: [],
          lang: { loading: 'Loading chart data...' },
          exporting: {
            filename: this.filePrefix + '-' + key + '-' + moment().format('YYYY-MM-DD[T]HHmmss'),
          },
        },
        true,
        true
      );
      chart.showLoading();
    });
    this.groupedCompanies = [];
  }

  getReports() {
    this.clearAllCharts();
    this.generateMonthLabels();
    this.battleCompanies = [];

    // Set the axis for all histogram charts
    this.charts['arrivalsDeparturesChart'].update(
      { xAxis: { categories: this.monthLabels } },
      true,
      true
    );
    this.charts['employeeCountByOptions'].update(
      { xAxis: { categories: this.monthLabels } },
      true,
      true
    );
    this.charts['tenureByMonthChart'].update(
      { xAxis: { categories: this.monthLabels } },
      true,
      true
    );

    this.getHighLevelStats().subscribe({
      next: (res: any) => {
        this.currentEmployeeCount = res.count_current_employees;
        this.allTimeEmployeeCount = res.count_all_time_employees;
        this.groupedCompanies = res.grouped_companies.filter((c: any) => c.name != null);
        if (this.groupedCompanies && this.groupedCompanies.length > 0) {
          this.groupedCompanies?.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        }
        this.updateDisplayedCompanies();

        this.getCurrentEmployeeSchoolData().subscribe({
          next: (res: CreateEmployeeTenureReport200ResponseInner[]) => {
            // Top 10 school chart (res[2] + stats)
            this.charts['top10School'].update(
              {
                series: {
                  type: 'bar',
                  showInLegend: false,
                  dataLabels: {
                    enabled: true,
                    format: '{point.y:.2f}%',
                  },
                  data: res.slice(0, this.top10ChartSize).map((c: any) => {
                    return 100 * (c.count_employees / (this.currentEmployeeCount || 1));
                  }),
                },
                xAxis: {
                  categories: res.slice(0, this.top10ChartSize).map((c: any) => {
                    return c.group_values[0]?.value;
                  }),
                },
              },
              true,
              true,
              true
            );
            this.charts['top10School'].hideLoading();
          },
          error: () => {
            this.showChartError('top10School');
          },
        });

        this.getCurrentEmployeePreviousCompanyData().subscribe({
          next: (res: CreateEmployeeTenureReport200ResponseInner[]) => {
            this.charts['top10PreviousCompany'].update(
              {
                series: {
                  type: 'bar',
                  showInLegend: false,
                  dataLabels: {
                    enabled: true,
                    format: '{point.y:.2f}%',
                  },
                  data: res.slice(0, this.top10ChartSize).map((c: any) => {
                    return 100 * (c.count_employees / (this.currentEmployeeCount || 1));
                  }),
                },
                xAxis: {
                  categories: res.slice(0, this.top10ChartSize).map((c: any) => {
                    return c.group_values[0]?.value;
                  }),
                },
              },
              true,
              true,
              true
            );
            this.charts['top10PreviousCompany'].hideLoading();
          },
          error: () => {
            this.showChartError('top10PreviousCompany');
          },
        });

        forkJoin([
          this.getEmployeeNextCompanyData(),
          this.getEmployeePreviousCompanyData(),
        ]).subscribe({
          next: (res) => {
            // Sankey (res[0] + res[1])
            const mergedSankey = this.generateSankeySeries(res[0], res[1]);
            this.charts['companySankey'].update({ series: mergedSankey }, true, true, true);
            this.charts['companySankey'].hideLoading();

            const companies = Object.entries(
              [...res[0], ...res[1]]
                .filter(
                  (c) =>
                    c.group_values[0]?.value !== 'None' &&
                    c.group_values[0]?.value !== 'Amazon' &&
                    c.group_values[0]?.value !== 'Freelance/Self-employed'
                )
                .map((c) => {
                  return { company: c.group_values[0]?.value, count: c.count_employees };
                })
                .reduce(
                  (acc: { [key: string]: number }, c) => {
                    if (acc[c.company]) {
                      acc[c.company] += c.count;
                    } else {
                      acc[c.company] = c.count;
                    }
                    return acc;
                  },
                  {} as { [key: string]: number }
                )
            )
              .sort((a, b) => b[1] - a[1])
              .slice(0, 4)
              .map((c) => c[0]);

            forkJoin(
              companies.map((company: string) => {
                const req: FindCompanyRequest = {
                  search_value: company,
                  search_field: FindCompanyRequest.SearchFieldEnum.Any,
                };

                return this.companiesService.findCompany(this.orgId, req);
              })
            ).subscribe({
              next: (res: FindCompany200Response[]) => {
                this.battleCompanies = res
                  .map((r: FindCompany200Response) => r.companies![0]?.id)
                  .filter((v: any) => v !== undefined);

                forkJoin([
                  this.mbService.getHistoricalEmployeeCounts(
                    this.orgId,
                    [this.resultsCompany.id],
                    true,
                    this.startDateDate,
                    this.endDateDate,
                    undefined,
                    undefined,
                    true
                  ),

                  ...this.battleCompanies.map((companyId: string) => {
                    return this.mbService.getHistoricalEmployeeCounts(
                      this.orgId,
                      [companyId],
                      true,
                      this.startDateDate,
                      this.endDateDate,
                      undefined,
                      undefined,
                      true
                    );
                  }),
                ]).subscribe({
                  next: (res) => {
                    const data1 = res[0].map((point) => [point[0], point[1] * 100]); // convert to percentage

                    // Initialize a map to store sum of percentage differences and a count for each timestamp
                    const timestampMap: { [key: string]: { sum: number; count: number } } = {};

                    // Accumulate the sums and counts of the percentage differences
                    res.slice(1).forEach((subArray) => {
                      subArray.forEach(([timestamp, percentDiff]) => {
                        if (timestampMap[timestamp]) {
                          timestampMap[timestamp].sum += percentDiff;
                          timestampMap[timestamp].count += 1;
                        } else {
                          timestampMap[timestamp] = { sum: percentDiff, count: 1 };
                        }
                      });
                    });

                    // Step 4: Calculate the average percentage difference for each timestamp and create the new array
                    const averagePercentDiffArray = Object.entries(timestampMap).map(
                      ([timestamp, { sum, count }]) => {
                        return [parseInt(timestamp), sum / count];
                      }
                    );

                    const data2 = averagePercentDiffArray.map((point) => [
                      point[0],
                      point[1] * 100,
                    ]); // convert to percentage

                    this.charts['competition'].update({
                      yAxis: {
                        labels: {
                          formatter: function () {
                            const value = this.value as number;
                            return value.toFixed(0) + '%';
                          },
                        },
                      },
                      tooltip: {
                        pointFormat: '{series.name}: <b>{point.y:.2f}%</b>',
                      },
                    } as Highcharts.Options);

                    this.charts['competition'].update(
                      {
                        series: [
                          {
                            name: this.resultsCompany.name,
                            type: 'spline',
                            data: data1,
                          },
                          {
                            name: 'Competitors avg (' + companies.join(', ') + ')',
                            type: 'spline',
                            data: data2,
                          },
                        ],
                      },
                      true,
                      true
                    );
                    this.charts['competition'].hideLoading();
                  },
                  error: () => {
                    // This means the line won't be drawn, but don't call attention to it
                  },
                });
              },
              error: () => {
                this.notify.error('Error loading company data. Try again later.');
              },
            });
          },
          error: () => {
            this.showChartError('top10PreviousCompany');
            this.showChartError('companySankey');
          },
        });
      },
      error: () => {},
    });

    this.getHistoricalEmployeeCounts().subscribe({
      next: (res: CreateEmployeeDemoReport200ResponseInner[]) => {
        let totalEmpsByMonth: any = {};
        res.forEach((item: CreateEmployeeDemoReport200ResponseInner) => {
          totalEmpsByMonth[
            new Date(item.date!).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              timeZone: 'UTC',
            })
          ] = item.count_employees;
        });
        this.seriesCache['employeeCountByMonthSeries'] =
          this.generateTotalEmpCountByMonthChartSeries(totalEmpsByMonth);
        this.charts['arrivalsDeparturesChart'].addSeries(
          this.seriesCache['employeeCountByMonthSeries'][0]
        );
        this.charts['arrivalsDeparturesChart'].hideLoading();
      },
      error: () => {
        // This means the line won't be drawn, but don't call attention to it
      },
    });

    this.getArrivalAndDepartureData().subscribe({
      next: (res: CreateArrivalDepartureReport200ResponseInner[]) => {
        this.seriesCache['arrivalsDeparturesByDeptSeries'] =
          this.generateArrivalDepartureChartSeries(res, 'department');
        this.seriesCache['arrivalsDeparturesByLevelSeries'] =
          this.generateArrivalDepartureChartSeries(res, 'jobLevel');

        this.charts['arrivalsDeparturesChart'].hideLoading();
        this.seriesCache['arrivalsDeparturesByDeptSeries'].forEach((s: any) =>
          this.charts['arrivalsDeparturesChart'].addSeries(s, false)
        );
        this.charts['arrivalsDeparturesChart'].redraw();
      },
      error: () => {
        this.showChartError('arrivalsDeparturesChart');
      },
    });

    this.getHistoricalEmployeeCountsByLevel().subscribe({
      next: (res: CreateEmployeeDemoReport200ResponseInner[]) => {
        this.seriesCache['employeeCountByLevelSeries'] = this.generateEmpCountChartSeries(
          res,
          'jobLevel'
        );
      },
      error: () => {
        this.showChartError('employeeCountByOptions');
      },
    });

    this.getHistoricalEmployeeCountsByDept().subscribe({
      next: (res: CreateEmployeeDemoReport200ResponseInner[]) => {
        this.seriesCache['employeeCountByDeptSeries'] = this.generateEmpCountChartSeries(
          res,
          'department'
        );

        this.charts['employeeCountByOptions'].hideLoading();
        this.charts['employeeCountByOptions'].update(
          { series: this.seriesCache['employeeCountByDeptSeries'] },
          true,
          true,
          true
        );
      },
      error: () => {
        this.showChartError('employeeCountByOptions');
      },
    });

    this.getCurrentEmployeeLocationData().subscribe({
      next: (res: CreateEmployeeDemoReport200ResponseInner[]) => {
        const d = this.generateMapSeries(res);
        this.charts['mapChart'].update({ series: d }, true, true, true);
        this.charts['mapChart'].hideLoading();
      },
      error: () => {
        this.showChartError('mapChart');
      },
    });

    this.getCurrentEmployeeOrgChartData().subscribe({
      next: (res: CreateEmployeeDemoReport200ResponseInner[]) => {
        const d = this.generateOrgChartSeries(res);
        this.charts['orgChart'].update({ series: d }, true, true, true);
        this.charts['orgChart'].hideLoading();
      },
      error: () => {
        this.showChartError('orgChart');
      },
    });

    this.getHistoricalTenureData().subscribe({
      next: (res: CreateEmployeeTenureReport200ResponseInner[]) => {
        this.seriesCache['tenureByMonthSeries'] = this.generateTenureByMonthSeries(res);
        this.renderTenureCharts();
      },
      error: () => {
        this.showChartError('tenureByMonthChart');
      },
    });

    this.getCurrentTenureByPreviousCompanyData().subscribe({
      next: (res: CreateEmployeeTenureReport200ResponseInner[]) => {
        this.seriesCache['tenureByPrevSeries'] = this.generateTenureCurrentItemsSeries(res);
        this.renderCurrentTenureCharts();
      },
      error: () => {
        this.showChartError('currentTenureChart');
      },
    });

    this.showCharts = true;
    this.loading = false;
  }

  battleCompanies: string[] = [];
  goToBattle() {
    const settings: BattleUrlSettings = {
      companies: [
        {
          companyId: this.resultsCompany.id,
          type: this.selectedCompanyGroups ? 'group' : 'company',
        },
        ...this.battleCompanies.map((c) => {
          return {
            companyId: c,
            type: 'company' as 'company',
          };
        }),
      ],
    };

    const encodedFilter = encodeURIComponent(serializeBattleUrlSettings(settings));
    this.router.navigate(['/' + this.orgId + '/moneyball/battle'], {
      queryParams: {
        settings: encodedFilter,
      },
    });
  }
  showChartError(chartName: string) {
    this.charts[chartName].update(
      { lang: { loading: 'Problem getting chart data...' } },
      true,
      true,
      true
    );
    this.charts[chartName].showLoading();
  }

  showChartLoading(chartName: string) {
    this.charts[chartName].update({ lang: { loading: 'Loading chart data...' } }, true, true, true);
    this.charts[chartName].showLoading();
  }

  getArrivalAndDepartureData(): Observable<any> {
    let req: CreateArrivalDepartureReportRequest = {
      companies: [this.resultsCompany.id],
      date_from: moment(this.startDateDate).format('YYYY-MM-DD'),
      date_to: moment(this.endDateDate).format('YYYY-MM-DD'),
      include_grouped_companies: this.selectedCompanyGroups,
    };
    return this.companiesService.createArrivalDepartureReport(this.orgId, req);
  }

  getHistoricalEmployeeCountsByLevel(): Observable<any> {
    let req: CreateEmployeeDemoReportRequest = {
      companies: [this.resultsCompany.id],
      date_from: moment(this.startDateDate).format('YYYY-MM-DD'),
      date_to: moment(this.endDateDate).format('YYYY-MM-DD'),
      group_by: [CreateEmployeeDemoReportRequest.GroupByEnum.Level],
      include_grouped_companies: this.selectedCompanyGroups,
    };
    return this.companiesService.createEmployeeDemoReport(this.orgId, req);
  }

  getHistoricalEmployeeCountsByDept(): Observable<any> {
    let req: CreateEmployeeDemoReportRequest = {
      companies: [this.resultsCompany.id],
      date_from: moment(this.startDateDate).format('YYYY-MM-DD'),
      date_to: moment(this.endDateDate).format('YYYY-MM-DD'),
      group_by: [CreateEmployeeDemoReportRequest.GroupByEnum.Department],
      include_grouped_companies: this.selectedCompanyGroups,
    };
    return this.companiesService.createEmployeeDemoReport(this.orgId, req);
  }

  getHistoricalEmployeeCounts(): Observable<any> {
    let req: CreateEmployeeDemoReportRequest = {
      companies: [this.resultsCompany.id],
      date_from: moment(this.startDateDate).format('YYYY-MM-DD'),
      date_to: moment(this.endDateDate).format('YYYY-MM-DD'),
      include_grouped_companies: this.selectedCompanyGroups,
    };
    return this.companiesService.createEmployeeDemoReport(this.orgId, req);
  }

  getCurrentEmployeePreviousCompanyData(): Observable<any> {
    let req: CreateEmployeeDemoReportRequest = {
      companies: [this.resultsCompany.id],
      group_by: [CreateEmployeeDemoReportRequest.GroupByEnum.PreviousCompany],
      include_grouped_companies: this.selectedCompanyGroups,
    };
    return this.companiesService.createEmployeeDemoReport(this.orgId, req);
  }

  getCurrentEmployeeSchoolData(): Observable<any> {
    let req: CreateEmployeeDemoReportRequest = {
      companies: [this.resultsCompany.id],
      group_by: [CreateEmployeeDemoReportRequest.GroupByEnum.School],
      include_grouped_companies: this.selectedCompanyGroups,
    };
    return this.companiesService.createEmployeeDemoReport(this.orgId, req);
  }

  getCurrentEmployeeLocationData(): Observable<any> {
    // Get current employee next company data
    let req: CreateEmployeeDemoReportRequest = {
      companies: [this.resultsCompany.id],
      group_by: [CreateEmployeeDemoReportRequest.GroupByEnum.Location],
      include_grouped_companies: this.selectedCompanyGroups,
    };
    return this.companiesService.createEmployeeDemoReport(this.orgId, req);
  }

  getEmployeeNextCompanyData(): Observable<Array<CreateEmployeeDemoReport200ResponseInner>> {
    let req: CreateEmployeeDemoReportRequest = {
      companies: [this.resultsCompany.id],
      group_by: [CreateEmployeeDemoReportRequest.GroupByEnum.NextCompany],
      date_from: moment(this.startDateDate).format('YYYY-MM-DD'),
      date_to: moment(this.endDateDate).format('YYYY-MM-DD'),
      aggregate_type: CreateEmployeeDemoReportRequest.AggregateTypeEnum.Range,
      include_grouped_companies: this.selectedCompanyGroups,
    };
    return this.companiesService.createEmployeeDemoReport(this.orgId, req);
  }

  getEmployeePreviousCompanyData(): Observable<Array<CreateEmployeeDemoReport200ResponseInner>> {
    let req: CreateEmployeeDemoReportRequest = {
      companies: [this.resultsCompany.id],
      group_by: [CreateEmployeeDemoReportRequest.GroupByEnum.PreviousCompany],
      date_from: moment(this.startDateDate).format('YYYY-MM-DD'),
      date_to: moment(this.endDateDate).format('YYYY-MM-DD'),
      aggregate_type: CreateEmployeeDemoReportRequest.AggregateTypeEnum.Range,
      include_grouped_companies: this.selectedCompanyGroups,
    };
    return this.companiesService.createEmployeeDemoReport(this.orgId, req);
  }

  getCurrentEmployeeOrgChartData(): Observable<any> {
    let req: CreateEmployeeDemoReportRequest = {
      companies: [this.resultsCompany.id],
      group_by: [
        CreateEmployeeDemoReportRequest.GroupByEnum.Department,
        CreateEmployeeDemoReportRequest.GroupByEnum.Level,
      ],
      include_grouped_companies: this.selectedCompanyGroups,
    };
    return this.companiesService.createEmployeeDemoReport(this.orgId, req);
  }

  getHighLevelStats(): Observable<any> {
    let req: CreateStatsReportRequest = {
      companies: [this.resultsCompany.id],
      include_grouped_companies: this.selectedCompanyGroups,
    };
    return this.companiesService.createStatsReport(this.orgId, req);
  }

  getHistoricalTenureData(): Observable<Array<CreateEmployeeTenureReport200ResponseInner>> {
    let req: CreateEmployeeTenureReportRequest = {
      companies: [this.resultsCompany.id],
      date_from: moment(this.startDateDate).format('YYYY-MM-DD'),
      date_to: moment(this.endDateDate).format('YYYY-MM-DD'),
      status: CreateEmployeeTenureReportRequest.StatusEnum.Departed,
      include_grouped_companies: this.selectedCompanyGroups,
    };

    return this.companiesService.createEmployeeTenureReport(this.orgId, req);
  }

  getHistoricalTenureActiveData(): Observable<Array<CreateEmployeeTenureReport200ResponseInner>> {
    let req: CreateEmployeeTenureReportRequest = {
      companies: [this.resultsCompany.id],
      date_from: moment(this.startDateDate).format('YYYY-MM-DD'),
      date_to: moment(this.endDateDate).format('YYYY-MM-DD'),
      status: CreateEmployeeTenureReportRequest.StatusEnum.Current,
      include_grouped_companies: this.selectedCompanyGroups,
    };

    return this.companiesService.createEmployeeTenureReport(this.orgId, req);
  }

  getHistoricalTenureByDeptData(): Observable<Array<CreateEmployeeTenureReport200ResponseInner>> {
    let req: CreateEmployeeTenureReportRequest = {
      companies: [this.resultsCompany.id],
      date_from: moment(this.startDateDate).format('YYYY-MM-DD'),
      date_to: moment(this.endDateDate).format('YYYY-MM-DD'),
      status: CreateEmployeeTenureReportRequest.StatusEnum.Departed,
      group_by: [CreateEmployeeTenureReportRequest.GroupByEnum.Department],
      include_grouped_companies: this.selectedCompanyGroups,
    };

    return this.companiesService.createEmployeeTenureReport(this.orgId, req);
  }

  getHistoricalTenureByDeptActiveData(): Observable<
    Array<CreateEmployeeTenureReport200ResponseInner>
  > {
    let req: CreateEmployeeTenureReportRequest = {
      companies: [this.resultsCompany.id],
      date_from: moment(this.startDateDate).format('YYYY-MM-DD'),
      date_to: moment(this.endDateDate).format('YYYY-MM-DD'),
      group_by: [CreateEmployeeTenureReportRequest.GroupByEnum.Department],
      status: CreateEmployeeTenureReportRequest.StatusEnum.Current,
      include_grouped_companies: this.selectedCompanyGroups,
    };

    return this.companiesService.createEmployeeTenureReport(this.orgId, req);
  }

  getCurrentTenureByDeptData(): Observable<Array<CreateEmployeeTenureReport200ResponseInner>> {
    let req: CreateEmployeeTenureReportRequest = {
      companies: [this.resultsCompany.id],
      group_by: [CreateEmployeeTenureReportRequest.GroupByEnum.Department],
      status: CreateEmployeeTenureReportRequest.StatusEnum.Current,
      include_grouped_companies: this.selectedCompanyGroups,
    };

    return this.companiesService.createEmployeeTenureReport(this.orgId, req);
  }

  getHistoricalTenureByLevelData(): Observable<Array<CreateEmployeeTenureReport200ResponseInner>> {
    let req: CreateEmployeeTenureReportRequest = {
      companies: [this.resultsCompany.id],
      date_from: moment(this.startDateDate).format('YYYY-MM-DD'),
      date_to: moment(this.endDateDate).format('YYYY-MM-DD'),
      group_by: [CreateEmployeeTenureReportRequest.GroupByEnum.Level],
      status: CreateEmployeeTenureReportRequest.StatusEnum.Departed,
      include_grouped_companies: this.selectedCompanyGroups,
    };

    return this.companiesService.createEmployeeTenureReport(this.orgId, req);
  }

  getHistoricalTenureByLevelActiveData(): Observable<
    Array<CreateEmployeeTenureReport200ResponseInner>
  > {
    let req: CreateEmployeeTenureReportRequest = {
      companies: [this.resultsCompany.id],
      date_from: moment(this.startDateDate).format('YYYY-MM-DD'),
      date_to: moment(this.endDateDate).format('YYYY-MM-DD'),
      group_by: [CreateEmployeeTenureReportRequest.GroupByEnum.Level],
      status: CreateEmployeeTenureReportRequest.StatusEnum.Current,
      include_grouped_companies: this.selectedCompanyGroups,
    };

    return this.companiesService.createEmployeeTenureReport(this.orgId, req);
  }

  getCurrentTenureByLevelData(): Observable<Array<CreateEmployeeTenureReport200ResponseInner>> {
    let req: CreateEmployeeTenureReportRequest = {
      companies: [this.resultsCompany.id],
      group_by: [CreateEmployeeTenureReportRequest.GroupByEnum.Level],
      status: CreateEmployeeTenureReportRequest.StatusEnum.Current,
      include_grouped_companies: this.selectedCompanyGroups,
    };

    return this.companiesService.createEmployeeTenureReport(this.orgId, req);
  }

  getCurrentTenureBySchoolData(): Observable<Array<CreateEmployeeTenureReport200ResponseInner>> {
    let req: CreateEmployeeTenureReportRequest = {
      companies: [this.resultsCompany.id],
      group_by: [CreateEmployeeTenureReportRequest.GroupByEnum.School],
      status: CreateEmployeeTenureReportRequest.StatusEnum.Current,
      include_grouped_companies: this.selectedCompanyGroups,
    };

    return this.companiesService.createEmployeeTenureReport(this.orgId, req);
  }

  getCurrentTenureByPreviousCompanyData(): Observable<
    Array<CreateEmployeeTenureReport200ResponseInner>
  > {
    let req: CreateEmployeeTenureReportRequest = {
      companies: [this.resultsCompany.id],
      group_by: [CreateEmployeeTenureReportRequest.GroupByEnum.PreviousCompany],
      status: CreateEmployeeTenureReportRequest.StatusEnum.Current,
      include_grouped_companies: this.selectedCompanyGroups,
    };

    return this.companiesService.createEmployeeTenureReport(this.orgId, req);
  }

  generateMonthLabels() {
    this.monthLabels = [];

    const endDate = moment.utc(moment(this.endDateDate).format('YYYY-MM-DD')).startOf('month');
    const startDate = moment.utc(moment(this.startDateDate).format('YYYY-MM-DD')).startOf('month');

    while (startDate.isSameOrBefore(endDate)) {
      this.monthLabels.push(startDate.format('MMM YYYY'));
      startDate.add(1, 'month');
    }
  }

  generateOrgChartSeries(inputData: any[]): any[] {
    let points: any[] = [];
    let deptCounts = inputData.reduce(
      (r: any, i: any) => (
        (r[i.group_values[0].value] = (r[i.group_values[0].value] || 0) + i.count_employees), r
      ),
      {}
    );
    Object.keys(deptCounts).forEach((d: any) => {
      const keyIndex = this.departmentIndexer(d);
      const color = this.colors[keyIndex];
      points.push({ id: 'id-' + keyIndex, name: d, value: deptCounts[d], color: color });
    });
    inputData.forEach((d: any, i: any) => {
      const dIndex = this.departmentIndexer(d.group_values[0].value);
      points.push({
        id: 'id-' + dIndex + '_' + i,
        name: d.group_values[1].value,
        value: d.count_employees,
        parent: 'id-' + dIndex,
      });
    });

    let series = [
      {
        name: 'Employees',
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        allowDrillToNode: true,
        animationLimit: 1000,
        dataLabels: {
          enabled: false,
        },
        levels: [
          {
            level: 1,
            dataLabels: {
              enabled: true,
            },
            borderWidth: 3,
            levelIsConstant: false,
          },
          {
            level: 1,
            dataLabels: {
              style: {
                fontSize: '14px',
              },
            },
          },
        ],
        accessibility: {
          exposeAsGroupOnly: true,
        },
        data: points,
      },
    ];

    return series;
  }

  generateMapSeries(data: CreateEmployeeDemoReport200ResponseInner[]): any[] {
    const counts = data
      .map((d) => {
        const st = this.mbService.LocationToState.find(
          (l: any) => l.key === d.group_values[0].value
        )?.state;
        let ret = { state: st || 'UNKNOWN', count: d.count_employees };
        return ret;
      })
      .filter((d: any) => d && d.state !== 'UNKNOWN')
      .reduce((r: any, i: any) => ((r[i.state] = (r[i.state] || 0) + i.count), r), {});
    const total: any = Object.values(counts).reduce((a: any, b: any) => a + b, 0);
    let processed: any[] = [];
    if (total) {
      processed = Object.keys(counts).map((k: any) => {
        return ['us-' + k.toLowerCase(), +((100 * counts[k]) / total).toFixed(2)];
      });
    }

    let series = [
      {
        name: 'Employee count',
        type: 'map',
        states: {
          hover: {
            color: this.colors[1],
          },
        },
        dataLabels: {
          enabled: true,
          format: '{point.name}',
        },
        data: processed,
      },
    ];
    return series;
  }

  generateArrivalDepartureChartSeries(inputData: any, byType: string): any[] {
    const [, keyIndexer] = this.byTypeConfigs[byType];

    var refMonth: string;
    var monthIndex: number = 0;
    let processed = inputData.reduce((acc: any, d: any) => {
      if (d.date !== refMonth) {
        refMonth = d.date;
        monthIndex = this.monthLabels.indexOf(new Date(d.date).asMonthAndYear());
      }
      const keyVal = byType === 'department' ? d[byType] : d['level'];
      if (!acc[keyVal]) {
        const keyIndex = keyIndexer(keyVal);
        const color = this.colors[keyIndex];

        acc[keyVal] = {
          id: keyVal,
          name: keyVal,
          data: Array(this.monthLabels.length).fill(null),
          color: color,
          type: 'column',
          legendIndex: keyIndex,
        };
        acc[keyVal + 'DEP'] = {
          name: keyVal,
          data: Array(this.monthLabels.length).fill(null),
          color: color,
          type: 'column',
          legendIndex: keyIndex,
          linkedTo: ':previous',
        };
      }

      acc[keyVal].data[monthIndex] += d.count_arrivals;
      acc[keyVal + 'DEP'].data[monthIndex] -= d.count_departures;

      return acc;
    }, {});

    return Object.values(processed);
  }

  generateSankeySeries(
    nextData: CreateEmployeeDemoReport200ResponseInner[],
    prevData: CreateEmployeeDemoReport200ResponseInner[]
  ): Highcharts.SeriesSankeyOptions[] {
    const thisCompanyName = this.resultsCompany.name;
    let data: (string | number)[][] = [];

    data = data.concat(
      prevData
        .filter(
          (c: CreateEmployeeDemoReport200ResponseInner) =>
            c.group_values[0].value !== 'None' &&
            c.group_values[0].value !== this.resultsCompany.name
        )
        .slice(0, this.maxCompanySankeyItems)
        .map((c: CreateEmployeeDemoReport200ResponseInner, i: any) => [
          c.group_values[0].value + ' ',
          thisCompanyName,
          c.count_employees,
          this.colors[i],
        ])
    );

    data = data.concat(
      nextData
        .filter(
          (c: CreateEmployeeDemoReport200ResponseInner) =>
            c.group_values[0].value !== this.resultsCompany.name
        )
        .slice(0, this.maxCompanySankeyItems)
        .map((c: CreateEmployeeDemoReport200ResponseInner, i: any) => [
          thisCompanyName,
          c.group_values[0].value,
          c.count_employees,
          this.colors[16 + i],
        ])
    );

    let sData: Highcharts.SeriesSankeyOptions[] = [
      {
        colors: this.colors,
        keys: ['from', 'to', 'weight', 'color'],
        type: 'sankey',
        data: data,
      },
    ];

    return sData;
  }

  generateTotalEmpCountByMonthChartSeries(chartData: any): any[] {
    // Calculate employee count by month (once)
    let employeeCountData: any[] = [];

    this.monthLabels.forEach((m: any) => {
      employeeCountData.push(chartData[m] || 0);
    });

    let employeeCountSeries = {
      id: 'employeeCount',
      name: 'Employee Count',
      data: employeeCountData,
      color: this.employeeCountColor,
      type: 'spline',
      yAxis: 1,
      showInLegend: false,
      zIndex: 99999,
    };

    return [employeeCountSeries];
  }

  generateEmpCountChartSeries(inputData: any[], byType: string): any[] {
    const [, keyIndexer] = this.byTypeConfigs[byType];

    var refMonth: string;
    var monthIndex: number = 0;
    let processed = inputData.reduce((acc: any, d: any) => {
      if (d.date !== refMonth) {
        refMonth = d.date;
        monthIndex = this.monthLabels.indexOf(new Date(d.date).asMonthAndYear());
      }
      const keyVal = d.group_values[0].value;
      if (!acc[keyVal]) {
        const keyIndex = keyIndexer(keyVal);
        const color = this.colors[keyIndex];

        acc[keyVal] = {
          id: keyVal,
          name: keyVal,
          data: Array(this.monthLabels.length).fill(null),
          color: color,
          type: 'line',
        };
      }

      acc[keyVal].data[monthIndex] = d.count_employees;

      return acc;
    }, {});

    return Object.values(processed);
  }

  generateTenureByGroupSeries(inputData: any[], byType: string): any[] {
    const [, keyIndexer] = this.byTypeConfigs[byType];

    var refMonth: string;
    var monthIndex: number = 0;
    let processed = inputData.reduce((acc: any, d: any) => {
      if (d.date !== refMonth) {
        refMonth = d.date;
        monthIndex = this.monthLabels.indexOf(new Date(d.date).asMonthAndYear());
      }
      const keyVal = d.group_values[0].value;
      if (!acc[keyVal]) {
        const keyIndex = keyIndexer(keyVal);
        const color = this.colors[keyIndex];

        acc[keyVal] = {
          id: keyVal,
          name: keyVal,
          data: Array(this.monthLabels.length).fill({ y: null, sampleSize: 0 }),
          color: color,
          type: 'line',
        };
      }

      acc[keyVal].data[monthIndex] = {
        y: d.avg_tenure,
        sampleSize: d.count_employees,
      };

      return acc;
    }, {});

    return Object.values(processed);
  }

  generateTenureByMonthSeries(inputData: any[]): any[] {
    var monthIndex: number = 0;

    let data = Array(this.monthLabels.length).fill({ y: null, sampleSize: 0 });

    inputData.forEach((d: any) => {
      monthIndex = this.monthLabels.indexOf(new Date(d.date).asMonthAndYear());
      data[monthIndex] = {
        y: d.avg_tenure,
        sampleSize: d.count_employees,
      };
    });

    let series = {
      id: 'Overall',
      name: 'Overall',
      data: data,
      color: this.colors[0],
      type: 'line',
    };

    return [series];
  }

  generateTenureCurrentItemsSeries(inputData: any[]): any[] {
    const data: any[] = inputData.map((d: any) => {
      return {
        y: d.avg_tenure,
        sampleSize: d.count_employees,
        name: d.group_values[0].value,
      };
    });

    const series = [
      {
        data: data
          .filter((s: any) => s.y)
          .sort((a: any, b: any) => b.y - a.y)
          .slice(0, this.currentTenureMaxDataPoints),
        showInLegend: false,
        type: 'bar',
      },
    ];

    return series;
  }

  ngAfterViewInit(): void {
    this.charts['competition'] = Highcharts.chart(
      'competitionChartContainer',
      this.comphartNoSeries
    );
  }

  // Handles the dynamic rendering of tenure charts based on the selected options
  renderTenureCharts() {
    const chart: Highcharts.Chart = this.charts['tenureByMonthChart'];

    // No idea why, but this has to be done to make the chart redraw the new data correctly
    chart.update({ series: [] }, true, true);
    this.showChartLoading('tenureByMonthChart');

    var filteredSeries: any = [];

    const field = this.selectedHistoricalTenureChartOption.value;
    const status = this.selectedTenureChartStatusOption.value;

    // First get a copy of the source data for the selected chart type
    // For the some data, it's an array of series, for others it's a single series and we treat it different later
    if (field === 'Month' && status == 'Departed') {
      filteredSeries = this.deepCopy(this.seriesCache['tenureByMonthSeries']);
    } else if (field === 'Month' && status == 'Active') {
      filteredSeries = this.deepCopy(this.seriesCache['tenureByMonthCurrentSeries']);
    } else if (field === 'Department' && status == 'Departed') {
      filteredSeries = this.deepCopy(this.seriesCache['tenureByDeptSeries']);
    } else if (field === 'Department' && status == 'Active') {
      filteredSeries = this.deepCopy(this.seriesCache['tenureByDeptCurrentSeries']);
    } else if (field === 'Job Level' && status == 'Departed') {
      filteredSeries = this.deepCopy(this.seriesCache['tenureByLevelSeries']);
    } else if (field === 'Job Level' && status == 'Active') {
      filteredSeries = this.deepCopy(this.seriesCache['tenureByLevelCurrentSeries']);
    }

    // First filter out any data points that don't meet the sample size threshold
    filteredSeries.forEach((s: any) => {
      s.data = s.data.map((d: any) =>
        d && d.sampleSize && d.sampleSize >= this.minTenureSampleSize
          ? d
          : { y: null, sampleSize: 0, name: d.name || null }
      );
    });

    // TODO: handle if no matching data points show a chart message

    const chartUpdate: Highcharts.Options = {
      series: filteredSeries,
    };

    chart.update(chartUpdate, true, true, true);
    chart.hideLoading();
  }

  // Handles the dynamic rendering of tenure charts based on the selected options
  renderCurrentTenureCharts() {
    const chart: Highcharts.Chart = this.charts['currentTenureChart'];

    // No idea why, but this has to be done to make the chart redraw the new data correctly
    chart.update({ series: [] }, true, true);
    this.showChartLoading('currentTenureChart');

    const field = this.selectedCurrentTenureChartOption.value;
    let series: any;

    if (field === 'Department') {
      series = this.deepCopy(this.seriesCache['currentTenureByDeptSeries']);
    } else if (field === 'Job Level') {
      series = this.deepCopy(this.seriesCache['currentTenureByLevelSeries']);
    } else if (field === 'School') {
      series = this.deepCopy(this.seriesCache['tenureBySchoolSeries']);
    } else if (field === 'Previous Company') {
      series = this.deepCopy(this.seriesCache['tenureByPrevSeries']);
    }

    const chartLabels = series[0].data.map((d: any) => d.name);

    const maxSize = Math.max(...series[0].data.map((d: any) => d.sampleSize)) || 1;
    series[0].data.forEach((d: any) => {
      d.color = this.tenureBaseColor.toHexA(Math.max(0.25, d.sampleSize / maxSize));
    });

    const chartUpdate: Highcharts.Options = {
      series: series,
      xAxis: { categories: chartLabels },
    };

    chart.update(chartUpdate, true, true, true);
    chart.hideLoading();
  }

  tenureByMonthChartNoSeries = {
    chart: {
      type: 'line',
    },
    yAxis: [
      {
        allowDecimals: false,
        tickAmount: 10,
        title: {
          text: 'Average Tenure (months)',
        },
        stackLabels: {
          enabled: false,
        },
      },
    ],
    tooltip: {
      valueDecimals: 2,
      pointFormat: '{series.name}<br/><b>{point.y}</b> (N={point.sampleSize})',
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
    exporting: {
      sourceWidth: 1200,
    },
  };

  currentTenureChartNoSeries = {
    chart: {
      type: 'bar',
    },
    yAxis: [
      {
        allowDecimals: true,
        tickAmount: 10,
        title: {
          text: 'Average Tenure (months)',
        },
        stackLabels: {
          enabled: false,
        },
      },
    ],
    tooltip: {
      valueDecimals: 2,
      pointFormat: '<b>{point.y}</b> (N={point.sampleSize})',
    },
    exporting: {
      sourceWidth: 1200,
    },
    plotOptions: {
      bar: {
        borderWidth: 0,
        borderColor: 'transparent',
      },
    },
  };

  top10PreviousCompanyChartNoSeries: Highcharts.Options = {
    chart: {
      type: 'bar',
    },
    colors: this.colors,
    xAxis: {
      title: {
        text: null,
      },
    },
    yAxis: {
      title: {
        text: '% of Employees',
      },
    },
    tooltip: {
      enabled: false,
    },
    exporting: {
      sourceWidth: 1200,
    },
    plotOptions: {
      bar: {
        borderWidth: 0,
        borderColor: 'transparent',
      },
    },
  };

  sankeyChartNoSeries = {
    legend: { enabled: false },
    yAxis: { labels: { enabled: false } },
    plotOptions: {
      sankey: {
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: 'light',
            fontSize: 14,
          },
          color: '#000000',
        },
        nodes: [],
      },
    },
    exporting: {
      sourceWidth: 1200,
    },
  };

  mapChartNoData = {
    chart: {
      map: us_map,
      borderWidth: 0,
    },
    legend: {
      layout: 'horizontal',
      borderWidth: 0,
      // backgroundColor: 'rgba(255,255,255,0.85)',
      floating: true,
      verticalAlign: 'top',
      y: 25,
    },
    mapNavigation: {
      enabled: true,
      enableMouseWheelZoom: false,
    },
    tooltip: {
      pointFormat: '{point.name}: <b>{point.value}%</b>',
    },
    colorAxis: {
      min: 0,
      maxColor: this.colors[0],
    },
    exporting: {
      sourceWidth: 1200,
    },
  };

  arrDepChartNoSeries: Highcharts.Options = {
    xAxis: {
      categories: this.monthLabels,
    },
    yAxis: [
      {
        allowDecimals: false,
        tickAmount: 10,
        title: {
          text: 'Departures | Arrivals',
        },
        stackLabels: {
          enabled: false,
        },
        plotLines: [
          {
            value: 0,
            color: this.employeeCountColor,
            width: 3,
            zIndex: 2,
          },
        ],
      },
      {
        opposite: true,
        title: {
          text: 'Employee Count',
        },
      },
    ],
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 0,
        borderColor: 'transparent',
      },
    },
    legend: {
      itemStyle: {
        fontSize: '10px',
      },
    },
  };

  empCountChartNoSeries = {
    xAxis: {},
    yAxis: [
      {
        allowDecimals: false,
        tickAmount: 10,
        title: {
          text: 'Employee Count',
        },
      },
    ],
    exporting: {
      sourceWidth: 1200,
    },
  };

  // Chart Headcount
  comphartNoSeries: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    colors: ['var(--orange-500)', 'var(--blue-500)'],
    title: {
      text: undefined,
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%e %b %Y', // format for the dates on the xAxis
      },
      title: {
        style: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    yAxis: [
      {
        allowDecimals: true,
        title: {
          text: 'Headcount Growth',
        },
        stackLabels: {
          enabled: false,
        },
      },
    ],
    tooltip: {},
    legend: {
      align: 'left',
      itemStyle: {
        fontFamily: '"Montserrat", sans-serif',
      },
    },
  };

  downloadArrivalsDeparturesCSV() {
    this.arrDepDownloading = true;

    let req: CreateArrivalDepartureReportRequest = {
      companies: [this.resultsCompany.id],
      date_from: moment(this.startDateDate).format('YYYY-MM-DD'),
      date_to: moment(this.endDateDate).format('YYYY-MM-DD'),
      include_grouped_companies: this.selectedCompanyGroups,
    };

    this.companiesService.downloadArrivalDepartureReport(this.orgId, req).subscribe({
      next: (res: any) => {
        var data = new Blob([res], { type: 'text/csv' });
        FileSaver.saveAs(
          data,
          'livedata-moneyball-' +
            this.resultsCompany.name +
            '-arrDepData-' +
            moment().format('YYYYMMDD-HHmmss') +
            '.csv'
        );
        this.arrDepDownloading = false;
      },
      error: () => {
        this.notify.error('Error downloading CSV. Please try again later.');
        this.arrDepDownloading = false;
      },
    });
  }

  downloadEmployeeCountCSV() {
    this.empCountDownloading = true;

    let req: DownloadEmployeeDemoReportRequest = {
      companies: [this.resultsCompany.id],
      date_from: moment(this.startDateDate).format('YYYY-MM-DD'),
      date_to: moment(this.endDateDate).format('YYYY-MM-DD'),
      group_by: [
        DownloadEmployeeDemoReportRequest.GroupByEnum.Department,
        DownloadEmployeeDemoReportRequest.GroupByEnum.Level,
      ],
      include_grouped_companies: this.selectedCompanyGroups,
    };

    this.companiesService.downloadEmployeeDemoReport(this.orgId, req).subscribe({
      next: (res: any) => {
        var data = new Blob([res], { type: 'text/csv' });
        FileSaver.saveAs(
          data,
          'livedata-moneyball-' +
            this.resultsCompany.name +
            '-empCountData-' +
            moment().format('YYYYMMDD-HHmmss') +
            '.csv'
        );
        this.empCountDownloading = false;
      },
      error: () => {
        this.notify.error('Error downloading CSV. Please try again later.');
        this.empCountDownloading = false;
      },
    });
  }
}
