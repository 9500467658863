import { Component, Input, OnInit, HostBinding } from '@angular/core';
import { DarkModeService } from '../dark-mode/dark-mode.service';

@Component({
  selector: 'app-dark-mode-toggle',
  templateUrl: './dark-mode-toggle.component.html',
  styleUrls: ['./dark-mode-toggle.component.scss'],
})
export class DarkModeToggleComponent implements OnInit {
  @Input() customClasses = '';
  @Input() darkModeOnly = false;
  @Input() tooltipPosition: 'above' | 'below' | 'left' | 'right' = 'below'; // default to below

  // apply custom CSS classes to the host element for positioning flexibility
  @HostBinding('class') get hostClasses() {
    return this.customClasses;
  }

  constructor(private darkModeService: DarkModeService) {}

  ngOnInit(): void {
    this.darkModeService.initializeTheme();
  }

  toggleTheme() {
    this.darkModeService.toggleTheme();
  }

  // in some components, e.g. main app, switcher should always be bright white
  get buttonClasses() {
    if (this.darkModeOnly) {
      return 'tw-text-white tw-border-white hover:tw-bg-gray-500 focus:tw-ring-white';
    }
    return 'tw-text-gray-700 dark:tw-text-white hover:tw-bg-gray-200 dark:hover:tw-bg-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-500 dark:focus:tw-ring-white  tw-border-gray-500 dark:tw-border-white';
  }
}
