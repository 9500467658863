<div class="content-title">Clean Jobs</div>
<div class="actionContainer">
  <button mat-raised-button [routerLink]="['upload']">Upload</button>
  <button
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    mat-raised-button
    (click)="updateJobs()">
    Refresh
  </button>
</div>
<div class="container">
  <ag-grid-angular
    style="height: 100%;"
    [ngClass]="{ 'ag-theme-alpine': !(darkModeService.darkMode$ | async), 'ag-theme-alpine-dark': darkModeService.darkMode$ | async }"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [components]="components"
    [defaultColDef]="defaultColDef"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</div>
