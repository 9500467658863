import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HeapService } from './api-client/heap.service';
import { AuthService, UIOrg } from './auth/service/auth.service';
import { NotificationService } from './shared/notification-service/notification.service';
import * as Sentry from '@sentry/angular-ivy';
import { MatSidenav } from '@angular/material/sidenav';
import { ClipboardService } from 'ngx-clipboard';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import Hotjar from '@hotjar/browser';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InvitationsCreateDialogComponent } from './team/invitations-create-dialog/invitations-create-dialog.component';
import { InvitationsService } from 'ldt-identity-service-api';
import { SubscriptionDialogComponent } from './settings/billing/subscription-dialog/subscription-dialog.component';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';

const siteId = 5182445;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav: MatSidenav;

  get email(): string | undefined {
    return this.auth.email;
  }
  $isLoggedIn: Observable<boolean>;

  adminRoutes = [
    {
      path: 'admin/clean',
      name: 'Clean Service',
    },
    {
      path: 'admin/identity',
      name: 'Identity Service',
    },
    {
      path: 'admin/ledger',
      name: 'Ledger Service',
    },
    {
      path: 'admin/moneyball',
      name: 'Moneyball Service',
    },
    {
      path: 'admin/staging-firehose',
      name: 'Staging Search Sync',
    },
    {
      path: 'admin/data-deliveries/destinations',
      name: 'Data Deliveries',
    },
    {
      path: 'datawarehouse/stats',
      name: 'Data Warehouse',
    },
    {
      path: 'admin/streams/dashboard',
      name: 'Onboarding',
    },
    {
      path: 'admin/billing/subscriptions',
      name: 'Billing',
    },
    {
      path: 'grow',
      name: 'Grow',
    },
    {
      path: 'techno-target',
      name: 'Techno Target',
    },
  ];
  showNav: boolean = false;
  isSmallScreen: boolean = false;
  showAdmin: boolean = false;
  $showAdmin: Observable<boolean>;
  appVersion: string;
  showSubscribeCTA: boolean = false;
  mbDaysRemaining: number;

  constructor(
    heapService: HeapService,
    private auth: AuthService,
    private router: Router,
    private notify: NotificationService,
    private _clipboardService: ClipboardService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private invService: InvitationsService,
    private _hotkeysService: HotkeysService
  ) {
    heapService.load({
      app_id: environment.heapAppId,
      force_ssl: true,
      secure_cookie: true,
      disable_text_capture: false,
      cookie_path: '/',
    });
    this.appVersion = environment.appVersion;

    this.auth.$isLoggedIn.subscribe((isLoggedIn) => {
      if (!isLoggedIn) return;

      const is_employee = this.auth.email
        ? this.auth.email.endsWith('livedatatechnologies.com')
        : false;

      (window as any)['Intercom']('update', {
        app_id: environment.intercomAppId,
        email: this.auth.email,
        user_id: this.auth.getUserValue.id,
        last_request_at: new Date().getTime() / 1000,
        alignment: 'left',
        horizontal_padding: 170,
        is_employee: is_employee,
      });
      Sentry.setUser({ email: this.auth.getUserValue.email, id: this.auth.getUserValue.id });
      heapService.identify(this.auth.email || '');
      heapService.addUserProperties({
        is_employee: is_employee,
      });
      Hotjar.identify(this.auth.getUserValue.id, {
        is_employee: is_employee,
        created_at: this.auth.getUserValue.createdAt,
        email: this.auth.getUserValue.email,
        source: this.auth.getUserValue.source || 'Unknown',
      });
    });
    this.registerUserHotkeys();
  }
  $orgs: Observable<any>;
  orgs: UIOrg[] = [];
  selectedOrg: any;
  $selectedOrg: Observable<string>;
  $isOrgAdmin: Observable<boolean> = of(false);
  $isOrgActive: Observable<boolean> = of(false);
  $isMBLicensed: Observable<boolean> = of(false);
  $orgCapabilities: Observable<String[]> = of([]);
  selectedOrgContactLimit: number;
  selectedOrgContactCount: number;
  isDarkMode = false;

  toggleSideNav() {
    this.sidenav.toggle();
    setTimeout(() => window.dispatchEvent(new Event('resize')), 200); // emit resize event to other components
  }

  async ngOnInit() {
    this.$isLoggedIn = this.auth.$isLoggedIn;
    this.$selectedOrg = this.auth.$getSelectedOrgId;
    this.$isOrgAdmin = this.auth.$isOrgAdmin;
    this.$isOrgActive = this.auth.$isOrgActive;
    this.$orgCapabilities = this.auth.$getOrgCapabilites;
    this.$showAdmin = this.auth.$isAdmin;
    this.$isMBLicensed = this.auth.$hasMBLicense;
    this.$showAdmin.subscribe((isAdmin) => {
      if (!isAdmin) return;

      this.registerAdminHotkeys();
    });
    this.auth.$getOrgs.subscribe(() => {
      this.orgs = this.auth.getOrgsValue || [];
      this.selectedOrg = this.auth.getSelectedOrgIdValue;
    });
    this.$isLoggedIn.subscribe(() => {
      this.orgs = this.auth.getOrgsValue || [];
      this.selectedOrg = this.auth.getSelectedOrgIdValue;
    });
    this.$selectedOrg.subscribe(() => {
      this.selectedOrg = this.auth.getSelectedOrgIdValue;
      this.showSubscribeCTA = false;

      let org = this.orgs.find((o) => {
        return o.id === this.selectedOrg;
      });
      this.selectedOrgContactCount = org?.contactCount || 0;
      let settings: any = org?.settings;
      this.selectedOrgContactLimit = settings?.ledger?.contactLimit || 100000000000000;

      if (settings?.moneyball?.capability) {
        if (settings?.moneyball?.subType === 'licensed') {
          return;
        }

        let expiresAt = new Date(settings?.moneyball?.expiresAt);
        let today = new Date();
        let diff = expiresAt.getTime() - today.getTime();
        this.mbDaysRemaining = Math.ceil(diff / (1000 * 3600 * 24));
        if (this.mbDaysRemaining < 30) {
          this.showSubscribeCTA = true;
        }
      }
    });
  }

  onOrgSelected() {
    this.auth.setSelectedOrgId(this.selectedOrg);
    this.router.navigate(['/main']);
  }

  async logout() {
    this.auth.logOut();
    this.notify.info('Successfully logged out.');
  }

  ngAfterViewInit(): void {
    this.breakpointObserver
      .observe([
        Breakpoints.HandsetPortrait,
        Breakpoints.TabletPortrait,
        Breakpoints.HandsetLandscape,
      ])
      .subscribe((result) => {
        setTimeout(() => {
          this.isSmallScreen = result.matches;
          if (this.isSmallScreen) {
            this.sidenav.close();
          } else {
            this.sidenav.open();
          }
          setTimeout(() => window.dispatchEvent(new Event('resize')), 200); // emit resize event to other components
        });
      });
  }

  triggerRefresh() {
    this.auth.refreshSession().subscribe({
      next: () => {
        this._clipboardService.copyFromContent(this.auth.getAccessTokenValue);
        this.notify.success('Refreshed and copied to clipboard');
      },
      error: () => {
        this.notify.error('Error');
      },
    });
  }

  handleNavigation(url: string): void {
    this.router.navigateByUrl(url);
  }

  intercomTargetName = 'Sales Inquiry';
  showSupportWindow() {
    (window as any)['Intercom']('showNewMessage');
  }

  showSubscriptionDialog() {
    const dialogRef = this.dialog.open(SubscriptionDialogComponent, {
      backdropClass: 'darker-dialog-backdrop',
    });
    dialogRef.afterClosed().subscribe((data) => {
      console.log(data);
    });
  }

  // handle the invite dialog form submission
  showInviteDialog(evt?: MouseEvent) {
    evt?.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    const dialogRef = this.dialog.open(InvitationsCreateDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((data) => {
      if (!data) return;

      if (data.emails && Array.isArray(data.emails)) {
        // Confirm it's an array
        data.emails.forEach((email: string) => {
          const body = {
            inviteeEmail: email.trim(),
            inviteeRole: data.role,
          };

          this.invService.createInvitation(this.selectedOrg, body).subscribe({
            next: (r) => {
              this.notify.success(email + ' successfully invited.');
            },
            error: (err) => {
              if (err.status === 400 && err.error?.message.startsWith('Invalid email')) {
                this.notify.error('Invalid email: ' + email);
                return;
              }
              if (err.status === 409) {
                this.notify.error('User already has access to this org');
                return;
              }

              this.notify.error('Failed to invite ' + email + '. Please try again later.');
            },
          });
        });
      } else {
        this.notify.error('Error inviting users. Please try again later.');
      }
    });
  }

  private registerAdminHotkeys() {
    this._hotkeysService.reset();
    this._hotkeysService.add([
      new Hotkey(
        'alt+shift+r',
        (event: KeyboardEvent): boolean => {
          this.triggerRefresh();
          return true;
        },
        undefined,
        'Refresh Session & Copy Token'
      ),
      new Hotkey(
        'alt+shift+a',
        (event: KeyboardEvent): boolean => {
          this.auth.toggleAdminVisibility();
          return true;
        },
        undefined,
        'Show/Hide Admin Items'
      ),
      new Hotkey(
        'alt+shift+i',
        (event: KeyboardEvent): boolean => {
          this.router.navigate(['/datawarehouse/inspect']);
          return true;
        },
        undefined,
        'Go to Inspect Page'
      ),
      new Hotkey(
        'alt+shift+o',
        (event: KeyboardEvent): boolean => {
          this.router.navigate(['/admin/identity']);
          return true;
        },
        undefined,
        'Go to Orgs Page'
      ),
      new Hotkey(
        'alt+shift+q',
        (event: KeyboardEvent): boolean => {
          this.inspectPersonAtCursor();
          return true;
        },
        undefined,
        'Inspect Person at Cursor'
      ),
    ]);
  }

  private registerUserHotkeys() {
    this._hotkeysService.reset();
    this._hotkeysService.add([
      new Hotkey(
        'alt+shift+r',
        (event: KeyboardEvent): boolean => {
          this.triggerRefresh();
          return true;
        },
        undefined,
        'Refresh Session & Copy Token'
      ),
    ]);
  }

  private inspectPersonAtCursor() {
    const selection = window.getSelection();
    let selectedText = '';
    if (selection && !selection.isCollapsed) {
      selectedText = selection.toString().trim();
    } else {
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const textNode = range.startContainer;

        if (textNode.nodeType === Node.TEXT_NODE) {
          const text = textNode.textContent || '';
          const position = range.startOffset;
          selectedText = this.getWordAtIndex(text, position);
        }
      }
    }
    if (selectedText.startsWith('LDP-')) {
      this.router.navigate(['/datawarehouse/inspect'], {
        queryParams: { ref: selectedText },
      });
    }
  }

  private getWordAtIndex(text: string, index: number): string {
    // Find word boundaries
    const beforeCursor = text.slice(0, index);
    const afterCursor = text.slice(index);

    const wordStartMatch = beforeCursor.match(/\S*$/);
    const wordEndMatch = afterCursor.match(/^\S*/);

    const wordStart = wordStartMatch ? wordStartMatch[0] : '';
    const wordEnd = wordEndMatch ? wordEndMatch[0] : '';

    return wordStart + wordEnd;
  }
  startIntercomSurvey() {
    (window as any)['Intercom']('showNewMessage');
  }

  reloadApp() {
    window.location.reload();
  }
}
