import { Component } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { ReportSource, ReportsService } from 'ldt-data-deliveries-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';

@Component({
  selector: 'app-report-sources',
  templateUrl: './report-sources.component.html',
  styleUrls: ['./report-sources.component.scss'],
})
export class ReportSourcesComponent {
  refreshing: boolean = false;
  reportSources: ReportSource[] = [];
  public colDefs: ColDef[] = [
    { field: 'id', maxWidth: 60 },
    { field: 'name' },
    { field: 'description' },
    { field: 'filter_type' },
    {
      headerName: 'Valid Trigger Fields',
      valueGetter: (params) => params.data.valid_trigger_date_fields.join(', '),
    },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    flex: 1,
  };

  constructor(
    private reportsService: ReportsService,
    private notify: NotificationService,
    public darkModeService: DarkModeService
  ) {}

  ngOnInit(): void {
    this.getReportSources();
  }

  getReportSources(): void {
    this.refreshing = true;
    this.reportsService.listReportSources().subscribe({
      next: (data) => {
        this.reportSources = data;
        this.refreshing = false;
      },
      error: (error) => {
        this.notify.error('Error fetching report sources');
        this.refreshing = false;
      },
    });
  }
}
