import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompaniesService } from 'ldt-dw-reader-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { DefaultService } from 'ldt-dw-reader-service-api';
import { SelectedCompany } from 'src/app/shared/company-search/company-search.component';
@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent {
  companyJson: any;
  inputFormGroup: UntypedFormGroup;
  refreshing: boolean = false;
  replaying: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private companiesService: CompaniesService,
    private notify: NotificationService,
    private _formBuilder: UntypedFormBuilder,
    private router: Router,
    private companyReplayService: DefaultService
  ) {
    this.inputFormGroup = this._formBuilder.group({
      id: ['', [Validators.required]],
    });

    if (this.route.snapshot.queryParams.id) {
      this.inputFormGroup.setValue({ id: this.route.snapshot.queryParams.id });
      this.getCompany(this.route.snapshot.queryParams.id);
    }
  }

  companySelected(company: SelectedCompany) {
    this.getCompany(company.company.id);
  }

  getCompany(id: string) {
    if (!id) {
      return;
    }

    let url = '/datawarehouse/companies?id=' + id.trim();
    this.router.navigateByUrl(url);

    this.refreshing = true;
    this.companiesService.getCompanyByLookup(id.trim()).subscribe({
      next: (company) => {
        this.companyJson = company;
        this.refreshing = false;
      },
      error: (err) => {
        this.refreshing = false;
        console.error(err);
        this.notify.error('Failed to load company: ' + id);
      },
    });
  }

  refresh() {
    if (!this.companyJson) {
      return;
    }

    this.getCompany(this.companyJson.id);
  }

  replayCompany() {
    if (!this.companyJson) {
      return;
    }

    const linkedin = this.companyJson.linkedin;
    if (!linkedin) {
      this.notify.error('Company does not have a linkedin');
      return;
    }

    this.replaying = true;
    this.companyReplayService
      .replayCompanies({
        lookups: [linkedin],
      })
      .subscribe({
        next: (res) => {
          this.notify.success('Company replay started');
          this.replaying = false;
        },
        error: (err) => {
          this.notify.error('Failed to replay company: ' + linkedin);
          this.replaying = false;
        },
      });
  }
}
