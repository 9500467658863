<!-- Load List -->
<div *ngIf="!this.items">
  <h2 mat-dialog-title class="tw-font-semibold tw-mb-0 tw-pb-0">Load List</h2>
  <p class="tw-text-sm tw-text-gray-500 dark:tw-text-gray-400 tw-pl-6 tw-mt-0">
    Select one of the saved companies lists below to see their data.
  </p>
  <mat-dialog-content class="tw-p-5">
    <mat-list class="tw-flex tw-flex-col tw-gap-4 tw-flex-wrap">
      <mat-list-item
        *ngFor="let item of this.savedLists | async"
        class="saved-battle-item tw-flex tw-items-center tw-flex-1 tw-border tw-border-solid tw-border-gray-300 tw-bg-gray-50 dark:tw-bg-gray-700 dark:tw-text-gray-200 dark:tw-border-gray-500 tw-rounded tw-p-2">
        <div>
          <h4 mat-line class="tw-text-gray-800 dark:tw-text-gray-100 tw-my-1">{{ item.name }}</h4>
          <p mat-line class="tw-text-sm tw-my-1">{{ item.description }}</p>
        </div>

        <button mat-flat-button color="primary" (click)="load(item)" class="tw-ml-auto">
          <span>Load List</span>
          <mat-icon class="tw-hidden sm:tw-block">folder_open</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
  </mat-dialog-content>
  <mat-dialog-actions class="tw-flex tw-justify-between tw-px-5 tw-pb-5">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>
