import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { AdministrativeService } from 'ldt-identity-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-add-role-dialog',
  templateUrl: './add-role-dialog.component.html',
  styleUrls: ['./add-role-dialog.component.scss'],
})
export class AddEmployeeRoleDialogComponent implements OnInit {
  form: UntypedFormGroup;
  serviceId: any;
  roleId: any;
  services: any[];
  roles: string[] = ['admin', 'editor', 'viewer'];

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AddEmployeeRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private adminService: AdministrativeService,
    private notify: NotificationService
  ) {}

  ngOnInit() {
    this.adminService.getServices().subscribe(
      (r) => {
        this.services = r;
      },
      () => {
        this.notify.error('Error getting services');
      }
    );

    this.form = this.fb.group({
      serviceId: [this.serviceId, [Validators.required]],
      roleId: [this.roleId, [Validators.required]],
    });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }
}
