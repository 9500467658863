import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Job, JobsService } from 'ldt-clean-service-api';
import { GridApi } from 'ag-grid-community';
import { AuthService } from '../auth/service/auth.service';
import { BtnCellRenderer } from '../shared/ag-grid-button/button-cell-renderer.component';
import { NotificationService } from '../shared/notification-service/notification.service';
import { formatNumber } from '@angular/common';
import { DarkModeService } from '../shared/dark-mode/dark-mode.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit {
  /// AG -GRID --------------
  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true,
  };
  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    filterParams: this.simpleFilterParams,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  columnDefs = [
    // { field: 'id', cellRenderer: 'loadingRenderer', maxWidth: 150 },
    {
      field: 'data.date',
      comparator: this.dateComparator,
      sort: 'desc',
      headerName: 'Date Submitted',
    },
    { field: 'status.state', headerName: 'State', filter: true },
    { field: 'data.name', headerName: 'Name' },
    { field: 'data.email', headerName: 'Email' },
    {
      field: 'status.size',
      headerName: 'Rows',
      valueFormatter: (params: any) => {
        return formatNumber(params.value, this.locale);
      },
    },
    {
      headerName: 'Download',
      filter: false,
      maxWidth: 150,
      sortable: false,
      cellRenderer: BtnCellRenderer,
      cellRendererParams: {
        onClick: this.downloadResults.bind(this),
        label: 'Download',
        display: 'compact',
        buttonType: 'mat-stroked-button',
      },
    },
  ];
  rowData: Job[];

  private gridApi: GridApi;

  components = {
    loadingRenderer: function (params: any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
      }
    },
  };
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  dateComparator(date1: string, date2: string): number {
    return new Date(date1) < new Date(date2) ? -1 : 1;
  }

  refreshing: boolean = true;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private jobService: JobsService,
    private dialog: MatDialog,
    private notify: NotificationService,
    private auth: AuthService,
    public darkModeService: DarkModeService
  ) {}

  orgId: string;
  ngOnInit() {
    this.orgId = this.auth.getSelectedOrgIdValue;
    this.updateJobs();
  }

  updateJobs() {
    this.refreshing = true;
    this.jobService.getJobs(this.orgId).subscribe(
      (res) => {
        this.rowData = res;
        this.refreshing = false;
      },
      () => {
        this.notify.error('Error getting jobs');
        this.rowData = [];
        this.refreshing = false;
      }
    );
  }
  downloadResults(e: any) {
    let job: Job = e.rowData;
    this.jobService.downloadJob(this.orgId, job.id).subscribe(
      (res) => {
        const csvSTring = res.fileContents || '';
        const blob = new Blob([csvSTring], { type: 'application/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = res.fileName || '';
        link.click();
      },
      () => {
        this.notify.error('Error downloading job results. Please try again later.');
      }
    );
  }
}
