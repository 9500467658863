<h2 mat-dialog-title>Add Employee Role</h2>
<mat-dialog-content [formGroup]="form">
  <mat-form-field>
    <mat-label>Service</mat-label>
    <mat-select id="service" name="service" formControlName="serviceId">
      <mat-option
        [value]="service.id"
        *ngFor="let service of services"
        >{{service.name}}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Role</mat-label>
    <mat-select id="role" name="role" formControlName="roleId">
      <mat-option [value]="role" *ngFor="let role of roles">{{role}}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="close()">Cancel</button>
  <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="save()">Add</button>
</mat-dialog-actions>
