<div
  class="custom-ranges-container tw-flex tw-flex-col tw-justify-center tw-bg-white tw-font-medium tw-text-gray-900 tw-border-0 tw-border-t tw-border-r tw-border-solid tw-border-gray-200 tw-rounded-bl dark:tw-bg-gray-700 dark:tw-border-gray-800">
  <button
    *ngFor="let item of customPresets"
    mat-button
    color="primary"
    (click)="selectRange(item)"
    class="custom-range-btn tw-w-full tw-font-medium tw-text-left rtl:tw-text-right tw-border-0 tw-border-b last:tw-border-b-0 tw-border-gray-200 tw-border-solid tw-cursor-pointer hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-700 focus:tw-text-blue-700 tw-px-4 tw-py-2 dark:tw-text-teal-300 dark:tw-border-gray-600 dark:hover:tw-bg-gray-700">
    {{ item }}
  </button>

  <!-- active selection if needed -->
  <!-- <button
    aria-current="true"
    type="button"
    class="tw-w-full tw-font-medium tw-text-left rtl:tw-text-right tw-text-white tw-bg-blue-700 tw-border-b tw-border-gray-200 tw-rounded-t tw-cursor-pointer focus:tw-outline-none tw-px-4 tw-py-2"></button> -->
</div>
