export const environment = {
  production: true,
  appVersion: require('../../package.json').version,
  heapAppId: '1250160802',
  intercomAppId: 'mgjydob6',
  moneyballSubscriptionPriceId: 'price_1QDuYCKjCB7MuRGZ0cyLE7RR',
  ledgerApiBasePath: 'https://gotlivedata.io/api/ledger/v1',
  ledgerApiBasePathV2: 'https://gotlivedata.io/api/ledger/v2',
  identityApiBasePath: 'https://gotlivedata.io/api/identity/v1',
  cleanApiBasePath: 'https://gotlivedata.io/api/clean/v2',
  onboardingApiBasePath: 'https://gotlivedata.io/api/onboarding/v1',
  billingApiBasePath: 'https://gotlivedata.io/api/billing/v1',
  dwReaderApiBasePath: 'https://gotlivedata.io/api/dw/v1',
  growApiBasePath: 'https://gotlivedata.io/api/grow',
  devDeploysApiBasePath: 'https://dwh-pipeline.gotlivedata.io/api/dynamo/v1',
  moneyballApiBasePath: 'https://gotlivedata.io/api/moneyball/v1',
  peopleApiBasePath: 'https://gotlivedata.io/api/people/v1',
  dataDeliveryApiBasePath: 'https://gotlivedata.io/api/deliveries/v1',
  resolverApiBasePath: 'https://gotlivedata.io/api/resolver/v1',
  chatApiBasePath: 'https://wkhlw1b9b1.execute-api.us-east-1.amazonaws.com/',
  recaptchaV3SiteKey: '6LfmIkweAAAAAM689lhlWcgbrKSol1Mh7X7Ngw81',
  onboardingNotificationURL: 'https://6d37285ddbed7749cd09a734325ce9cb.m.pipedream.net',
  googleAuthClientId: '615192757992-spf0aq02d2oak0iprkk7h2oae8gskf15.apps.googleusercontent.com',
  flatfileEnvironmentId: 'us_env_Fest5Hst',
  flatfilePublishableKey: 'pk_7374bf5668b34e849d9ee35a702465e5',
};
