import { Flatfile } from '@flatfile/api';

export const workbookConfig: Pick<Flatfile.CreateWorkbookConfig, 'name' | 'sheets' | 'actions'> = {
  name: 'Ledger Contacts',
  sheets: [
    {
      name: 'Contacts',
      slug: 'ledger_contacts',
      allowAdditionalFields: false,
      fields: [
        {
          label: 'Reference ID',
          key: 'reference_id',
          type: 'string',
          description:
            'A reference ID that can be used to match records back to your source data (optional)',
          constraints: [
            {
              type: 'unique',
            },
          ],
        },
        {
          label: 'Full Name',
          key: 'full_name',
          type: 'string',
          description: 'Full name of the employee',
        },
        {
          label: 'First Name',
          key: 'first_name',
          type: 'string',
          description: 'First name of the employee',
        },
        {
          label: 'Last Name',
          key: 'last_name',
          type: 'string',
          description: 'Last name of the employee',
        },
        {
          label: 'LinkedIn Profile',
          key: 'linkedin',
          type: 'string',
          description: 'LinkedIn Profile URL',
        },
        {
          label: 'Email Address',
          key: 'email_address',
          type: 'string',
          description: 'Email address',
        },
        {
          label: 'Title',
          key: 'title',
          type: 'string',
          description: 'Title of the employee',
        },
        {
          label: 'Company Name',
          key: 'company_name',
          type: 'string',
          description: 'Company name',
        },
        {
          label: 'Company LinkedIn',
          key: 'company_linkedin',
          type: 'string',
          description: 'LinkedIn URL or slug for the company',
        },
        {
          label: 'Company Domain',
          key: 'company_domain',
          type: 'string',
          description: 'Domain for the company',
        },
        {
          label: 'Company Ticker',
          key: 'company_ticker',
          type: 'string',
          description: 'Stock ticker for the company',
        },
        {
          label: 'School Name',
          key: 'school_name',
          type: 'string',
          description: 'Name of the school they attended',
        },
      ],
    },
  ],
  actions: [
    {
      operation: 'submitAction',
      label: 'Submit',
      mode: 'foreground',
      description: 'Add data to ledger',
      primary: true,
    },
  ],
};
