<div class="tw-flex tw-items-center tw-mt-10">
  <button
    mat-raised-button
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    (click)="getReportSources()">
    Refresh
  </button>
</div>
<div id="myGridTypes" class="tw-mt-4">
  <ag-grid-angular
    style="width: 100%; height: 100%"
    [ngClass]="{ 'ag-theme-balham': !(darkModeService.darkMode$ | async), 'ag-theme-balham-dark': darkModeService.darkMode$ | async }"
    [rowData]="reportSources"
    [columnDefs]="colDefs"
    [defaultColDef]="defaultColDef"
    [rowSelection]="'multiple'"
    [animateRows]="true"
    [domLayout]="'autoHeight'"></ag-grid-angular>
</div>
