import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription, take, takeUntil, timer } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { DeleteConfirmationComponent } from '../../delete-confirmation/delete-confirmation.component';
import { LedgersService, Ledger } from 'ldt-ledger-service-api';
import { AuthService } from '../../auth/service/auth.service';
import { NotificationService } from '../../shared/notification-service/notification.service';
import * as Highcharts from 'highcharts';
import { lightTheme, darkTheme } from 'src/app/shared/highcharts-themes';
import { DarkModeService } from '../../shared/dark-mode/dark-mode.service';
import HC_sankey from 'highcharts/modules/sankey';
HC_sankey(Highcharts);
import HC_dependencywheel from 'highcharts/modules/dependency-wheel';
import variwide from 'highcharts/modules/variwide';
variwide(Highcharts);
import HighchartsMore from 'highcharts/highcharts-more';
HighchartsMore(Highcharts);
import * as moment from 'moment-timezone';
HC_dependencywheel(Highcharts);
import {
  LedgerStatsService,
  CreateLedgerReport200Response,
  LedgersService as LedgersServiceV2,
} from 'ldt-ledger-service-api-v2';

import { GridApi, RowClickedEvent } from 'ag-grid-community';
import { formatNumber } from '@angular/common';
import { CreateLedgerDialogComponent } from '../create-ledger-dialog/create-ledger-dialog.component';
import { NgLedgerService } from '../ledger.service';
import { GetLedgers200ResponseInner } from 'ldt-ledger-service-api-v2/model/getLedgers200ResponseInner';

class ChartDef {
  category: string;
  chartOptions: Highcharts.Options;
}

@Component({
  selector: 'app-ledgers-list',
  templateUrl: './ledgers-list.component.html',
  styleUrls: ['./ledgers-list.component.scss'],
})
export class LedgersListComponent implements OnInit {
  ledgers: Ledger[] = [];
  ledgersLoading: boolean = true;
  ledgersDeleting: string[] = [];
  adding = false;
  form: UntypedFormGroup;
  $canEdit: Subject<boolean> = new Subject();
  selected: { startDate: any; endDate: any } = {
    startDate: moment().subtract(9, 'months'),
    endDate: new Date(),
  };
  isAdmin: Observable<boolean>;
  chartDefinitions: ChartDef[] = [];
  categories: string[] = [];
  selectedCharts: ChartDef[] = [];
  selectedCategory: string;

  ledgerView: string = 'card';

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private ledgerService: LedgersService,
    private router: Router,
    private notify: NotificationService,
    public dialog: MatDialog,
    private auth: AuthService,
    private ngLedgerService: NgLedgerService,
    private ledgerStatsService: LedgerStatsService,
    private ledgersServiceV2: LedgersServiceV2,
    public darkModeService: DarkModeService
  ) {
    this.$canEdit.next(false);
  }

  selectedOrg: any;
  selectedOrgSubscription: Subscription;
  atContactLimit: boolean = false;
  ledgerApiV2: boolean = false;

  private stopPolling = new Subject<void>();

  async ngOnInit() {
    this.isAdmin = this.auth.$isAdmin;
    this.ledgerApiV2 = this.ngLedgerService.getApiVersionValue();

    // Subscribe to dark mode changes and apply theme
    this.darkModeService.darkMode$.subscribe((isDarkMode) => {
      this.applyChartTheme(isDarkMode);
      // Update charts only if already loaded
      if (!this.chartsLoading) {
        this.updateFlag = true;
      }
    });

    this.selectedOrgSubscription = this.auth.$getSelectedOrgId.subscribe((r) => {
      this.selectedOrg = this.auth.getSelectedOrgIdValue;
      const thisOrg = this.auth.getSelectedOrg();

      let settings: any = thisOrg?.settings;
      let limit = settings?.ledger?.contactLimit;
      if (limit) {
        let rem = limit - (thisOrg?.contactCount || 0);
        if (rem <= 0) {
          this.atContactLimit = true;
        }
      }

      this.ledgerView = localStorage.getItem('ledger-list-view') || 'card';
      this.chartsLoading = true;
      this.chartDefinitions = [];

      this.$canEdit.next(this.auth.userHasRole('editor'));
      this.getLedgers();
      this.setHighchartsDataV2();
    });

    this.ngLedgerService.getApiVersion().subscribe({
      next: (v2: boolean) => {
        if (this.ledgerApiV2 != v2) {
          this.ledgerApiV2 = v2;
        }
      },
    });

    // Initialize theme on load
    this.applyChartTheme(this.darkModeService.currentDarkModeValue);
  }

  ngOnDestroy(): void {
    this.selectedOrgSubscription?.unsubscribe();
    this.stopPolling.next();
  }

  getLedgers(): void {
    this.ledgersLoading = true;
    this.ledgerService.getLedgers(this.selectedOrg).subscribe({
      next: (resp) => {
        if (resp.ledgers) {
          this.ledgers = resp.ledgers.sort((a, b) => a.name.localeCompare(b.name));
        }
        this.ledgers.forEach((l) => (this.ledgerCharts[l.id] = this.cardChartOptions));

        if (this.ledgerApiV2) {
          this.ledgers.forEach((l: Ledger) => (l.size = undefined));

          this.ledgersServiceV2.getLedgers(this.selectedOrg).subscribe({
            next: (resp: GetLedgers200ResponseInner[]) => {
              resp.forEach((l: GetLedgers200ResponseInner) => {
                const ledger = this.ledgers.find((l2: Ledger) => l2.id === l.id);
                if (ledger) {
                  ledger.size = l.contact_counts.total;
                }
              });
              this.ledgersLoading = false;
            },
            error: (err) => {
              if (err.status === 404) {
                timer(10000)
                  .pipe(takeUntil(this.stopPolling), take(1))
                  .subscribe(() => {
                    this.getLedgers();
                  });
                return;
              }

              this.notify.error(
                'Oops. There was an error getting the data. Please try again later.'
              );
              this.ledgersLoading = false;
            },
          });
        } else {
          this.ledgersLoading = false;
        }
      },
      error: () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
        this.ledgers = [];
        this.ledgersLoading = false;
      },
    });
  }
  goToLedger(ledgerId: string, showAddContacts: boolean = false) {
    this.router.navigate(
      [this.selectedOrg, 'ledgers', ledgerId, 'overview'],
      showAddContacts ? { queryParams: { showAddContacts: true } } : undefined
    );
  }

  showAddLedgerDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    const dialogRef = this.dialog.open(CreateLedgerDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.name) {
        this.ledgerService.createLedger(this.selectedOrg, data).subscribe({
          next: (ledger: Ledger) => {
            this.notify.success('Ledger successfully created');
            this.router.navigate([this.selectedOrg, 'ledgers', ledger.id, 'overview']);
          },
          error: () => {
            this.notify.error(
              'Oops. There was an error during your request. Please try again later.'
            );
          },
        });
      }
    });
  }

  changeView(view: string) {
    this.ledgerView = view;
    localStorage.setItem('ledger-list-view', this.ledgerView);
  }

  deleteLedger(ledger: Ledger) {
    const confirmDialog = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        title: 'Confirm Ledger Deletion',
        message:
          "Are you sure you want to delete the ledger '" +
          ledger.name +
          "' with " +
          ledger.size +
          ' contacts? This action is unrecoverable!',
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result == true) {
        this.ledgersDeleting.push(ledger.id);
        this.ledgerService.deleteLedger(ledger.id, this.selectedOrg).subscribe(
          (res) => {
            if (res) {
              this.getLedgers();
              this.notify.success('Ledger successfully deleted');
            }
          },
          () => {
            this.notify.error(
              'Oops. There was an error during your request. Please try again later.'
            );
          }
        );
      }
    });
  }

  //////////////////// HIGHCHARTS /////////////////////////
  Highcharts: typeof Highcharts = Highcharts; // required
  updateFlag: boolean = false; // optional boolean
  chartsLoading: boolean = true;
  ledgerCharts: any = {};

  setHighchartsData() {
    if (this.ledgerApiV2) {
      this.setHighchartsDataV2();
    } else {
      this.setHighchartsDataV2();
    }
  }

  applyChartTheme(isDarkMode: boolean) {
    Highcharts.setOptions(isDarkMode ? darkTheme : lightTheme);
  }

  setHighchartsDataV2() {
    this.chartsLoading = true;

    this.ledgerStatsService.createLedgerReport(this.selectedOrg, {}).subscribe({
      next: (stats: CreateLedgerReport200Response) => {
        this.chartDefinitions = [];

        const today = new Date();
        const relevantJobChangesByMonth = stats.job_changes_by_month?.filter(
          (item) => item.month && new Date(item.month) <= today
        );

        let vals = relevantJobChangesByMonth?.map((b: any) => b.count);
        let cats = relevantJobChangesByMonth?.map((b: any) => b.month);

        this.chartDefinitions.push({
          category: 'Overview',
          chartOptions: {
            credits: { enabled: false },
            colors: ['var(--primary-purple-500)'],
            title: {
              text: 'Job changes by month',
              style: {
                font: '20px SF UI Text,sans-serif',
              },
            },
            legend: { enabled: false },
            yAxis: { labels: { enabled: true }, title: { text: undefined } },
            series: [
              {
                data: vals,
                type: 'column',
                name: 'Job changes',
              },
            ],
            xAxis: {
              categories: cats,
              type: 'datetime',
              labels: {
                rotation: 0,
                formatter: function () {
                  let v = this.value as string;
                  return moment(v).tz('UTC').format('MMM');
                },
              },
            },
            tooltip: {
              formatter: function () {
                let v = this.x as string;
                return (
                  '<b>' +
                  moment(v).tz('UTC').format('MMM YYYY') +
                  '</b><br />' +
                  this.y?.toLocaleString()
                );
              },
            },
            plotOptions: {
              column: {
                borderWidth: 0,
                borderColor: 'transparent',
              },
            },
          },
        });

        this.ledgers.forEach((ledger: Ledger) => {
          const lstats = stats.ledger_changes_by_month?.find((b: any) => b.ledger_id === ledger.id);
          if (!lstats) return;

          let vals = lstats.monthly_changes?.map((b: any) => b.count);
          let cats = lstats.monthly_changes?.map((b: any) => b.month);

          let thisChart = JSON.parse(JSON.stringify(this.cardChartOptions));
          thisChart.series[0].data = vals;
          thisChart.xAxis.categories = cats;
          this.ledgerCharts[ledger.id] = thisChart;
        });
        this.updateFlag = true;

        let vals3 = stats.top_new_companies
          ?.filter((b: any) => b.company_name != 'None' && b.company_name != 'N/A')
          .map((b: any) => b.count);
        let cats3 = stats.top_new_companies
          ?.filter((b: any) => b.company_name != 'None' && b.company_name != 'N/A')
          .map((b: any) => b.company_name);

        this.chartDefinitions.push({
          category: 'Overview',
          chartOptions: {
            credits: { enabled: false },
            colors: ['var(--primary-purple-500)'],
            title: {
              text: 'Top new companies',
              style: {
                font: '20px SF UI Text,sans-serif',
              },
            },
            legend: { enabled: false },
            yAxis: { labels: { enabled: true }, title: { text: undefined } },
            chart: { type: 'bar' },
            series: [
              {
                data: vals3,
                type: 'bar',
              },
            ],
            xAxis: {
              categories: cats3,
            },
            tooltip: {
              formatter: function () {
                let v = this.x as string;
                return '<b>' + v + '</b><br />' + this.y?.toLocaleString();
              },
            },
            plotOptions: {
              bar: {
                borderWidth: 0,
                borderColor: 'transparent',
              },
            },
          },
        });

        let items: any = [];
        stats.company_movement?.forEach((b: any) => {
          if (b.company_name_from !== '0') {
            b.departures.forEach((b2: any) => {
              items.push([b.company_name_from, b2.company_name, b2.count]);
            });
          }
        });
        let filtered = items.filter((d: any) => {
          return d[0] !== 'Facebook' && d[1] !== 'Meta';
        });

        this.chartDefinitions.push({
          category: 'Overview',
          chartOptions: {
            credits: { enabled: false },
            colors: [
              'var(--primary-purple-500)',
              'var(--Purple-500)',
              'var(--Orange-500)',
              'var(--Green-500)',
              'var(--Pink-500)',
              'var(--Grey-500)',
              'var(--Blue-400)',
              'var(--Purple-400)',
              'var(--Orange-400)',
              'var(--Green-400)',
              'var(--Pink-400)',
              'var(--Grey-400)',
              'var(--Blue-300)',
              'var(--Purple-300)',
              'var(--Orange-300)',
              'var(--Green-300)',
              'var(--Pink-300)',
              'var(--Grey-300)',
              'var(--Blue-200)',
              'var(--Purple-200)',
              'var(--Orange-200)',
              'var(--Green-200)',
              'var(--Pink-200)',
              'var(--Grey-200)',
              'var(--Blue-100)',
              'var(--Purple-100)',
              'var(--Orange-100)',
              'var(--Green-100)',
              'var(--Pink-100)',
              'var(--Grey-100)',
            ],
            title: {
              text: 'Company movement',
              style: {
                font: '20px SF UI Text,sans-serif',
              },
            },
            legend: { enabled: false },
            yAxis: { labels: { enabled: false } },
            series: [
              {
                keys: ['from', 'to', 'weight'],
                data: filtered,
                type: 'dependencywheel',
                name: 'Movements',
              },
            ],
            chart: {
              zooming: {
                type: 'xy',
              },
            },
          },
        });

        this.categories = this.chartDefinitions
          .map((c) => c.category)
          .filter((v, i, s) => s.indexOf(v) === i)
          .sort();
        this.selectedCategory = 'Overview';
        this.selectCategory();
        this.chartsLoading = false;
      },
      error: () => {
        this.chartsLoading = false;
        this.notify.error('Error loading chart data');
      },
    });
  }

  selectCategory() {
    this.selectedCharts = this.chartDefinitions.filter((d) => d.category == this.selectedCategory);
  }

  /// AG -GRID --------------
  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true,
  };
  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    filterParams: this.simpleFilterParams,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  columnDefs = [
    { field: 'id', cellRenderer: 'loadingRenderer', maxWidth: 150 },
    { field: 'name', sort: 'asc' },
    {
      field: 'size',
      filter: false,
      maxWidth: 150,
      valueFormatter: (params: any) => {
        return formatNumber(params.value, this.locale);
      },
    },
    { field: 'createdAt' },
    { field: 'description' },
  ];

  private gridApi: GridApi;

  components = {
    loadingRenderer: function (params: any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
      }
    },
  };
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onRowClicked(event: RowClickedEvent) {
    this.goToLedger(event.data.id);
  }

  cardChartOptions: Highcharts.Options = {
    credits: { enabled: false },
    colors: ['var(--primary-purple-500)'],
    title: {
      text: undefined,
    },
    exporting: { enabled: false },
    legend: { enabled: false },
    yAxis: { labels: { enabled: true }, title: { text: undefined } },
    series: [
      {
        data: [],
        type: 'line',
        name: 'Job changes',
      },
    ],
    xAxis: {
      categories: [],
      type: 'datetime',
      labels: {
        rotation: 0,
        formatter: function () {
          let v = this.value as string;
          return moment(v).tz('UTC').format('MMM');
        },
      },
    },
    tooltip: {
      formatter: function () {
        let v = this.x as string;
        return (
          '<b>' +
          moment(v).format('MMM YYYY') +
          '</b><br />' +
          this.y?.toLocaleString() +
          ' jobs started'
        );
      },
    },
  };
}
