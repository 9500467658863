import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { AddOrgUserRequestInner, Org, UserRole, UserRolesService } from 'ldt-identity-service-api';
import { CellEditRequestEvent, ColDef, ColumnApi, GridApi } from 'ag-grid-community';
import { DeleteConfirmationComponent } from 'src/app/delete-confirmation/delete-confirmation.component';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { BtnCellRenderer } from 'src/app/shared/ag-grid-button/button-cell-renderer.component';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-team-users',
  templateUrl: './team-users.component.html',
  styleUrls: ['./team-users.component.scss'],
  providers: [DatePipe],
})
export class TeamUsersComponent implements OnInit {
  @Input() org: Org;
  @Input() canAdmin: boolean;

  /// AG -GRID --------------
  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true,
  };
  defaultColDef: ColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    filterParams: this.simpleFilterParams,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
    suppressMenu: true,
  };

  columnDefs: ColDef[] = [
    {
      field: 'user.name',
      checkboxSelection: true,
      headerName: 'Name',
      sort: 'asc',
      headerTooltip: 'Name of the user',
    },

    {
      field: 'role',
      headerName: 'Role',
      maxWidth: 150,
      filterParams: { values: ['admin', 'viewer', 'editor'] },
      filter: 'agSetColumnFilter',
      headerTooltip: 'Role this user has (either admin, editor, or viewer)',
      cellRenderer: (params: any) => {
        return `
          <div class="d-flex align-items-center tw-cursor-pointer">
            <span>${params.value}</span>
            <span>✎</span>
          </div>
        `;
      },
      singleClickEdit: true,
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['viewer', 'editor', 'admin'],
      },
    },
    {
      field: 'moneyballLicense',
      headerName: 'Moneyball License?',
      headerTooltip: 'Assign a Moneyball license to this user?',
      hide: true,
      cellRendererSelector: (params: any) => {
        const hasMBLicense = params.data?.settings?.licenses?.moneyball;

        if (hasMBLicense) {
          return {
            component: BtnCellRenderer,
            params: {
              onClick: this.revokeMoneyballLicense.bind(this),
              label: 'Revoke License',
              display: 'compact',
              btnColor: 'warn',
            },
          };
        }

        if (this.remainingLicenses() <= 0) {
          return undefined;
        }

        return {
          component: BtnCellRenderer,
          params: {
            onClick: this.assignMoneyballLicense.bind(this),
            label: 'Assign License',
            display: 'compact',
          },
        };
      },
    },
    { field: 'user.email', headerName: 'Email', headerTooltip: 'Email address of the user' },
    {
      field: 'user.lastLoginAt',
      headerName: 'Last Active (UTC)',
      valueFormatter: (params: any) => {
        return this.datePipe.transform(params.value, 'yyyy-MM-dd', 'UTC') || '';
      },
      headerTooltip: 'Date user last logged in',
    },
  ];
  rowData: UserRole[];
  emails: string = '';
  rowSelection: string = 'multiple';
  tooltipShowDelay = 200;
  hasMBLicense: boolean = false;
  currentlyLicensedMBUsers: number = 0;
  totalMBLicenses: number = 0;

  private gridApi: GridApi;
  private columnsApi: ColumnApi;
  private orgId: string = '';
  roles: string[] = ['viewer', 'editor', 'admin'];
  role: string;

  components = {
    loadingRenderer: function (params: any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
      }
    },
  };
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.columnsApi = params.columnApi;
    this.initView();
    this.gridApi.sizeColumnsToFit();
  }

  refreshing: boolean = true;

  constructor(
    private userRolesService: UserRolesService,
    private route: ActivatedRoute,
    private notify: NotificationService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    public darkModeService: DarkModeService,
    private authService: AuthService
  ) {
    this.orgId = route.snapshot.paramMap.get('orgId') || '';
  }

  ngOnInit() {
    if (this.org.settings?.moneyball?.subType === 'licensed') {
      this.hasMBLicense = true;
      this.totalMBLicenses = this.org.settings?.moneyball?.licensedUsers || 0;
    }
  }

  initView() {
    if (this.hasMBLicense) {
      this.columnsApi.setColumnVisible('moneyballLicense', true);
    }
    this.refreshOrgUsers();
  }

  refreshOrgUsers() {
    this.refreshing = true;
    this.userRolesService.getOrgUsers(this.orgId).subscribe({
      next: (r) => {
        this.rowData = r;
        this.refreshing = false;
        this.currentlyLicensedMBUsers = r.filter(
          (u: any) => u.settings?.licenses?.moneyball
        ).length;
      },
      error: () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
        this.rowData = [];
        this.refreshing = false;
      },
    });
  }

  rowsSelected: boolean = false;
  onSelectionChanged(event: any) {
    var selectedRows = this.gridApi.getSelectedRows();
    this.rowsSelected = selectedRows.length !== 0;
  }

  updateUser(evt: CellEditRequestEvent) {
    switch (evt.column.getColId()) {
      case 'role':
        const req: AddOrgUserRequestInner = {
          userId: evt.data.user.id,
          role: evt.value,
        };
        this.userRolesService.updateOrgUser(this.orgId, [req]).subscribe({
          next: () => {
            this.notify.success('Role updated');
            this.refreshOrgUsers();
          },
          error: () => {
            this.notify.error('Error updating role');
          },
        });
        break;
    }
  }

  remainingLicenses(): number {
    return this.totalMBLicenses - this.currentlyLicensedMBUsers;
  }

  assignMoneyballLicense(e: any) {
    const userRole = e.rowData;
    const req: AddOrgUserRequestInner = {
      userId: userRole.user.id,
      role: userRole.role,
      settings: {
        licenses: {
          moneyball: true,
        },
      },
    };
    this.userRolesService.updateOrgUser(this.orgId, [req]).subscribe({
      next: () => {
        this.notify.success('License Assigned');
        this.refreshOrgUsers();

        if (userRole.user.id === this.authService.getUserValue.id) {
          this.authService.refreshSession().subscribe(() => {
            // nothing to do
          });
        }
      },
      error: (err) => {
        if (err.status && err.status === 402) {
          this.notify.error(
            'Not enough licenses available. Please visit Billing to purchase more.'
          );
          return;
        }

        this.notify.error('Error assigning license');
      },
    });
  }

  revokeMoneyballLicense(e: any) {
    const userRole = e.rowData;
    const req: AddOrgUserRequestInner = {
      userId: userRole.user.id,
      role: userRole.role,
      settings: {
        licenses: {
          moneyball: false,
        },
      },
    };
    this.userRolesService.updateOrgUser(this.orgId, [req]).subscribe({
      next: () => {
        this.notify.success('License Revoked');
        this.refreshOrgUsers();
        if (userRole.user.id === this.authService.getUserValue.id) {
          this.authService.refreshSession().subscribe(() => {
            // nothing to do
          });
        }
      },
      error: () => {
        this.notify.error('Error revoking license');
      },
    });
  }

  removeSelectedUsers() {
    var numToDelete = this.gridApi.getSelectedRows().length;
    var ids: any = this.gridApi.getSelectedRows().map((d: any) => d.user.id);

    const confirmDialog = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        title: 'Confirm User Removal',
        message: 'Are you sure you want to remove ' + numToDelete + ' users from this Org?',
      },
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.userRolesService.deleteOrgUsers(this.orgId, { userIds: ids }).subscribe(
          (response) => {
            this.gridApi.deselectAll();
            this.refreshOrgUsers();
            this.notify.success(numToDelete + ' users successfully removed.');
          },
          () => {
            this.notify.error(
              'Oops. There was an error during your request. Please try again later.'
            );
          }
        );
      }
    });
  }
}
