<h2 mat-dialog-title>Clean Now</h2>
<div *ngIf="!cleaning">
  <div class="descriptionText">Perform a Clean (for now, just the SERP)</div>
  <br />
  <mat-dialog-content [formGroup]="form">
    <div>
      <mat-form-field>
        <input matInput placeholder="First name" formControlName="firstName" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Last name" formControlName="lastName" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Company" formControlName="company" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Lipid" formControlName="lipid" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>HTML Source</mat-label>
        <mat-select id="htmlSource" name="htmlSource" formControlName="htmlSource">
          <mat-option [value]="source" *ngFor="let source of htmlSources">{{source}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>Scraper</mat-label>
        <mat-select id="scraper" name="scraper" formControlName="scraper">
          <mat-option [value]="scraper" *ngFor="let scraper of scrapers">{{scraper}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button (click)="close()">Cancel</button>
    <button mat-raised-button [disabled]="!form.valid" color="primary" (click)="cleanNow()">
      Clean
    </button>
  </mat-dialog-actions>
</div>
<div *ngIf="cleaning && !cleanResults">
  Cleaning...<br /><br />
  <div>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button (click)="close()">Cancel</button>
  </mat-dialog-actions>
</div>
<div *ngIf="cleanResults">
  <div class="descriptionText">Results</div>
  <br />
  <mat-dialog-content>
    <pre>{{cleanResults | json}}</pre>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button (click)="close()">Close</button>
  </mat-dialog-actions>
</div>
