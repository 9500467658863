<!-- action btns -->
<div class="tw-flex tw-gap-4 tw-flex-row tw-items-center tw-my-4 tw-ml-[.1rem]">
  <button
    mat-raised-button
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    (click)="refreshOrgInvitations()">
    Refresh
  </button>
</div>
<div class="tw-w-full" width="100%">
  <ag-grid-angular
    style="height: 100%;"
    [ngClass]="{ 'ag-theme-quartz': !(darkModeService.darkMode$ | async), 'ag-theme-quartz-dark': darkModeService.darkMode$ | async }"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [components]="components"
    [rowSelection]="rowSelection"
    [defaultColDef]="defaultColDef"
    [tooltipShowDelay]="tooltipShowDelay"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    domLayout="autoHeight"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="onSelectionChanged($event)">
  </ag-grid-angular>
</div>
