<mat-card id="login">
  <div class="logo">
    <a routerLink="/">
      <img class="ldt-logo" src="../../assets/img/logo-black.svg" alt="logo" />
    </a>
  </div>
  <div *ngIf="!session">
    <asl-google-signin-button
      type="standard"
      size="large"
      width="380"
      theme="filled_blue"></asl-google-signin-button>
    <div class="terms">
      By creating an account, you agree to the
      <a href="https://www.livedatatechnologies.com/privacy-policy/" target="_blank">
        Privacy Policy </a
      >.
    </div>
  </div>
  <div *ngIf="session">
    Successfully logged in. This tab can be closed.
    <div class="creds">{{session}}</div>
  </div>
</mat-card>
