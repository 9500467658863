<h2 mat-dialog-title>Create Service Account</h2>

<mat-dialog-content [formGroup]="form">
  <mat-form-field>
    <input matInput placeholder="Service Account Name" formControlName="name" />
  </mat-form-field>
  <mat-form-field>
    <textarea matInput placeholder="Description" formControlName="description"></textarea>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Service</mat-label>
    <mat-select formControlName="serviceId">
      <mat-option
        *ngFor="let service of services"
        [value]="service.id"
        >{{service.name}}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Role</mat-label>
    <mat-select formControlName="role">
      <mat-option value="viewer">viewer</mat-option>
      <mat-option value="editor">editor</mat-option>
      <mat-option value="admin">admin</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="close()">Cancel</button>
  <button mat-raised-button [disabled]="!form.valid" color="primary" (click)="save()">
    Create
  </button>
</mat-dialog-actions>
