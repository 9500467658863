import { Component, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ColDef, GridApi } from 'ag-grid-community';
import { User, UsersService } from 'ldt-identity-service-api';
import { AdministrativeService } from 'ldt-identity-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { AddEmployeeDialogComponent } from './add-employee-dialog/add-employee-dialog.component';
import { ServiceRoleRendererComponent } from './service-role-renderer/service-role-renderer.component';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';

@Component({
  selector: 'app-admin-identity-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
})
export class EmployeesComponent implements OnInit {
  /// AG -GRID --------------
  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true,
  };
  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    filterParams: this.simpleFilterParams,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  columnDefs: ColDef[] = [
    { field: 'id', cellRenderer: 'loadingRenderer', maxWidth: 150 },
    { field: 'name', sort: 'asc' },
    { field: 'email' },
    { field: 'lastLoginAt' },
    {
      headerName: 'Roles',
      field: 'name',
      cellRenderer: ServiceRoleRendererComponent,
      autoHeight: true,
      filter: false,
      sortable: false,
      cellRendererParams: {
        updateEmployees: () => {
          this.updateEmployees();
        },
      },
    },
  ];

  rowData: any[];
  private gridApi: GridApi;
  refreshing: boolean = true;

  components = {
    loadingRenderer: function (params: any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
      }
    },
  };
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  constructor(
    private adminService: AdministrativeService,
    private userService: UsersService,
    private notify: NotificationService,
    private dialog: MatDialog,
    public darkModeService: DarkModeService
  ) {}

  ngOnInit(): void {
    this.updateEmployees();
  }

  updateEmployees() {
    this.refreshing = true;
    this.adminService.getEmployees().subscribe(
      (res) => {
        this.rowData = res.employees || [];
        this.refreshing = false;
      },
      () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
      }
    );
  }

  showCreateDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    const dialogRef = this.dialog.open(AddEmployeeDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (data.employeeEmail.endsWith('@livedatatechnologies.com')) {
          this.userService.getUsers(data.employeeEmail).subscribe(
            (r) => {
              // This could return multiple people, since it does a partial match, so try to find the actual user
              let user: User | undefined;
              user = r.find((u) => u.email === data.employeeEmail);

              if (user) {
                this.adminService.createEmployee({ personId: user.id }).subscribe(
                  () => {
                    this.notify.success('User marked as employee');
                    this.updateEmployees();
                  },
                  () => {
                    this.notify.error('Error marking user as employee');
                  }
                );
              } else {
                this.notify.error('Could not find such a user');
              }
            },
            () => {
              this.notify.error('Error trying to find employee');
            }
          );
        } else {
          this.notify.error('Employee role can only be granted to @LD email addresses');
        }
      }
    });
  }
}
