<!-- Flex container with full height to grow with content -->
<div
  class="tw-flex tw-flex-col tw-h-full dark:tw-border dark:tw-border-solid dark:tw-border-gray-300 dark:tw-rounded">
  <mat-dialog-title class="tw-flex tw-justify-between tw-items-center">
    <h2
      mat-dialog-title
      class="tw-text-gray-800 dark:tw-text-gray-100 tw-font-semibold tw-mb-0 tw-px-4">
      Test Destination Status and Details
    </h2>
    <button
      mat-icon-button
      aria-label="Close dialog"
      (click)="closeDialog()"
      class="tw-text-gray-500 tw-bg-gray-100 hover:tw-text-gray-800 hover:tw-bg-gray-200 dark:tw-text-gray-400 dark:tw-bg-gray-700 tw-w-8 tw-h-8 tw-transition-all tw-leading-5 tw-mr-3">
      <mat-icon>close</mat-icon>
    </button>
  </mat-dialog-title>

  <mat-dialog-content class="modal-content tw-flex-1 tw-overflow-auto">
    <p class="tw-mb-2 tw-flex tw-flex-col">
      <span class="tw-font-semibold tw-mr-2">Destination Name: </span>
      <span class="tw-inline-block code-font-direct tw-break-words tw-text-wrap">
        {{data.destinationData.name}}
      </span>
    </p>

    <div class="tw-mb-2 tw-flex tw-flex-col">
      <app-config-info
        *ngFor="let config of paramsForDisplay"
        [itemName]="config.key"
        [itemValue]="config.value"></app-config-info>
    </div>

    <div
      *ngIf="data.destinationData.destination_type_id !== 1"
      class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-48">
      <div class="tw-mb-5">
        <app-test-status-badge
          [testStatus]="data.destinationData.status"
          [isLoading]="isLoading"
          [timeStampFromConfig]="data.destinationData.tested_at"></app-test-status-badge>
      </div>

      <button
        mat-raised-button
        color="primary"
        [disabled]="isLoading"
        (click)="testDestinationStatus(data.destinationData)">
        Test Again
      </button>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="modal-footer tw-px-6 tw-py-4 tw-mt-auto">
    <button mat-raised-button (click)="closeDialog()">Close</button>
  </mat-dialog-actions>
</div>
