import { Injectable } from '@angular/core';
import { UIFilterGroup } from './filters/filters.component';
import { DWColumn } from '../data-warehouse/dw-column';
import { PeopleColumns } from './people-columns';
import {
  CreateSearchReport,
  SearchService as DWSearchService,
  SearchReport,
} from 'ldt-dw-reader-service-api'; // TEMP TODO prototype quick builds
import { NotificationService } from '../shared/notification-service/notification.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../auth/service/auth.service';

export interface QuickBuild {
  id: number;
  name: string;
  rootFilterGroup: UIFilterGroup;
}

@Injectable({
  providedIn: 'root',
})
export class QuickBuildService {
  private quickBuildsSource = new BehaviorSubject<QuickBuild[] | null>(null);
  private refreshing = new BehaviorSubject<boolean>(false);

  quickBuilds$ = this.quickBuildsSource.asObservable();
  refreshing$ = this.refreshing.asObservable();

  // While this is an internal-only service, we track employee status and don't do work unless they're an employee
  isEmployee = false;

  constructor(
    private notify: NotificationService,
    private dwReader: DWSearchService, // TEMP: TODO: prototype for quick builds
    private auth: AuthService
  ) {
    this.isEmployee = this.auth.isAdminValue;
    this.getQuickBuilds();
  }

  ngOnInit() {
    this.auth.$isAdmin.subscribe((isAdmin) => {
      this.isEmployee = isAdmin;
    });
  }

  public refresh() {
    this.getQuickBuilds();
  }

  private getQuickBuilds() {
    if (!this.isEmployee) {
      return;
    }

    this.refreshing.next(true);
    this.dwReader.getSearchReports().subscribe({
      next: (r: SearchReport[]) => {
        // Use this as a hack to find QBs in search reports
        const quickBuilds = r
          .filter((qb) => qb.parameters.includes('"quickBuild":true'))
          .map((qb) => {
            const qbFromParams = this.deserializeQuickBuild(qb.parameters);
            qbFromParams.id = qb.id;
            qbFromParams.name = qb.name;
            return qbFromParams;
          });

        // Cheating until we aren't casting SearchReports to QuickBuilds
        this.quickBuildsSource.next(quickBuilds); // For demo purposes
        this.refreshing.next(false);
      },
      error: () => {
        this.notify.error('Error getting saved searches for quick builds');
        this.refreshing.next(false);
      },
    });
  }

  createQuickBuild(qb: QuickBuild): void {
    const serializedQB = this.serializeQuickBuild(qb);
    let report: CreateSearchReport = {
      name: qb.name,
      parameters: serializedQB,
    };

    this.dwReader.createSearchReport(report).subscribe({
      next: () => {
        this.notify.success('New quick build saved');
        this.refresh();
      },
      error: () => {
        this.notify.error('Error saving quick build');
      },
    });
  }

  deleteQuickBuild(id: number): void {
    const idAsString = id.toString();
    this.dwReader.deleteSearchReport(idAsString).subscribe({
      next: () => {
        this.refresh();
      },
      error: () => {
        this.notify.error('Error deleting saved search');
      },
    });
  }

  // TODO (some day):
  // Improve serialization to add compression
  serializeQuickBuild(qb: QuickBuild): string {
    const searchParams = {
      rootFilterGroup: qb.rootFilterGroup,
      quickBuild: true,
    };

    return JSON.stringify(searchParams);
  }

  // TODO (some day):
  // Improve deserialization to add schema validation
  deserializeQuickBuild(serializedString: string): QuickBuild {
    let parsedParams = JSON.parse(serializedString);

    const qb: QuickBuild = {
      id: parsedParams.id || 0,
      name: parsedParams.name || '',
      rootFilterGroup: parsedParams.rootFilterGroup,
    };

    return qb;
  }

  getColumnForField(fieldName: string): DWColumn | undefined {
    const col: DWColumn | undefined = PeopleColumns.find((c) => c.name === fieldName);
    return col;
  }
}
