import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  CellClickedEvent,
  ColDef,
  GridOptions,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { BtnCellRenderer } from 'src/app/shared/ag-grid-button/button-cell-renderer.component';
import {
  DestinationsService,
  Destination,
  ListAllDestinationsRequest,
} from 'ldt-data-deliveries-api';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacyDialog as LegacyMatDialog } from '@angular/material/legacy-dialog';
import { DeleteConfirmationComponent } from 'src/app/delete-confirmation/delete-confirmation.component';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { TestStatusDialogComponent } from './test-status-dialog/test-status-dialog.component';
import { DetailsTooltipCellComponent } from 'src/app/integrations/helper-components/details-tooltip-cell/details-tooltip-cell.component';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';

interface DeleteCellClickedEvent extends CellClickedEvent {
  rowData: Destination;
}

@Component({
  selector: 'configured-integrations',
  templateUrl: './configured-integrations.component.html',
  styleUrls: ['./configured-integrations.component.scss'],
  providers: [DatePipe],
})
export class ConfiguredIntegrationsComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  constructor(
    private router: Router,
    private destinationsServices: DestinationsService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private notify: NotificationService,
    private legacyDialog: LegacyMatDialog,
    public darkModeService: DarkModeService
  ) {}

  orgId: string;
  destinations: Destination[] = [];
  loading: boolean = true;
  noData: boolean = true;
  showErrorMsg: boolean = false;
  refreshing: boolean = false;

  ngOnInit() {
    let routeOrg = this.route.parent?.snapshot.paramMap.get('orgId');
    if (routeOrg) {
      this.orgId = routeOrg;
    }

    this.refreshTable();
  }

  /// AG-GRID: all configured data deliveries for this org --------------
  public rowData!: Destination[];
  public gridOptions: GridOptions = {
    getRowClass: () => {
      return 'ag-row-hover-pointer';
    },
  };
  public colDefs: ColDef[] = [
    { field: 'name', floatingFilter: true },
    {
      field: 'destination_type',
      headerName: 'Destination Type Name',
      floatingFilter: true,
      maxWidth: 150,
      valueFormatter: (params) => params.value.display_name,
    },
    {
      headerName: 'Created',
      field: 'created_at',
      maxWidth: 180,
      sort: 'desc', // default sort order
      valueFormatter: (params: ValueFormatterParams) => {
        return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || '';
      },
    },
    {
      field: 'status',
      cellRenderer: this.statusCellRenderer,
      floatingFilter: true,
      maxWidth: 140,
      sortable: true,
    },
    {
      field: 'config',
      headerName: 'Details',
      cellRenderer: DetailsTooltipCellComponent,
      cellRendererParams: (params: ICellRendererParams) => {
        return {
          params: params,
          orgId: this.orgId,
        };
      },
      autoHeight: true,
    },
    {
      headerName: 'Delete',
      filter: false,
      maxWidth: 120,
      sortable: false,
      cellRenderer: BtnCellRenderer,
      cellRendererParams: {
        onClick: this.deleteDestination.bind(this),
        label: 'Delete',
        btnColor: 'warn',
        btnType: 'mat-raised-button',
        display: 'compact',
      },
    },
  ];
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
  };

  // AG-GRID: custom cell renderers --------------
  // conditionally display a badge based on the status value
  private statusCellRenderer(params: ICellRendererParams) {
    const status = params.value;
    let badgeHTML = '';

    // Hack for now - the API will change to give us a good status value to check
    if (params.data.destination_type_id === 1) {
      return 'N/A';
    }

    if (!status || status === 'unknown') {
      badgeHTML = `
      <span class="tw-bg-orange-100 tw-text-orange-800 tw-border-orange-400 dark:tw-bg-gray-800 dark:tw-text-yellow-400 dark:tw-border-yellow-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-1 tw-rounded tw-border tw-border-solid">
      Unknown
      </span>`;
    } else if (status === 'healthy') {
      badgeHTML = `
        <span class="tw-bg-green-100 tw-text-green-800 dark:tw-bg-gray-800 dark:tw-text-green-400 dark:tw-border-green-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-1 tw-rounded tw-border tw-border-solid tw-border-green-400">
          Healthy
        </span>`;
    } else if (status === 'unhealthy') {
      badgeHTML = `
        <span class="tw-bg-red-100 tw-text-red-800 tw-border-red-400 dark:tw-bg-gray-700 dark:tw-text-red-400 dark:tw-border-red-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-1 tw-rounded tw-border tw-border-solid">
          Unhealthy
        </span>`;
    }

    return badgeHTML;
  }
  deleteDestination(evt: DeleteCellClickedEvent): void {
    const confirmDialog = this.legacyDialog.open(DeleteConfirmationComponent, {
      data: {
        title: 'Confirm Deleting Data Destination',
        message:
          'This will delete your data delivery destination and could cause delivery interruption. Are you sure?',
      },
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.notify.success('Data delivery destination deleted.');

        // use service to delete this destination
        this.destinationsServices.deleteDestination(this.orgId, evt.rowData.id, 'body').subscribe({
          next: (data) => {
            this.notify.success('Data delivery destination deleted.');

            // Remove the item from rowData to update the table in UI
            this.rowData = this.rowData.filter(function (item) {
              return item.id !== evt.rowData.id;
            });
          },
          error: (error) => {
            this.notify.error('Error deleting destination.');
          },
        });
      }
    });
  }

  onCellClicked(event: CellClickedEvent): void {
    if (event.colDef.headerName !== 'Delete' && event.colDef.headerName !== 'Details') {
      this.openTestDialog(event.data);
    }
  }

  private openTestDialog(rowData: Destination): void {
    try {
      const dialogRef = this.dialog.open(TestStatusDialogComponent, {
        panelClass: 'ldt-custom-modal', // dimensions are set in styles.scss using this class
        data: {
          destinationData: rowData,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // update the table with the new status
        const newStatus = result?.status;
        if (newStatus) {
          this.rowData = this.rowData.map((item) => {
            if (item.id === rowData.id) {
              // return a new object with updated status
              return {
                ...item,
                status: newStatus,
              };
            }
            return item;
          });
          // Refresh ag-Grid to reflect changes
          this.agGrid.api.setRowData(this.rowData);
        }
      });
    } catch (error) {
      console.error('Error opening dialog:', error);
    }
  }

  refreshTable(): void {
    this.refreshing = true;
    let req: ListAllDestinationsRequest = {
      size: 1000,
    };
    this.destinationsServices.listDestinations(this.orgId, req).subscribe({
      next: (data) => {
        this.rowData = data.items;
        this.loading = false;
        this.noData = this.rowData.length === 0;
        this.refreshing = false;
        if (data.pagination_token) {
          this.notify.warning('Only first 1000 destinations are displayed, but there are more!');
        }
      },
      error: (error) => {
        console.error('Error fetching destinations:', error);
        this.loading = false;
        this.showErrorMsg = true;
        this.noData = true;
        this.refreshing = false;
      },
    });
  }

  goToAvailableIntegrations(): void {
    this.router.navigate(['../available-integrations'], { relativeTo: this.route });
  }
}
