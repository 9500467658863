<div class="container">
  <div class="content-title">Job Change Firehose</div>
  <div class="descriptionText">
    Items will appear at the top as they come in. Expect periods of long silence...
  </div>
  <div style="flex-grow: 1;">
    <ag-grid-angular
      style="width: 100%; height: 100%;"
      class="ag-theme-alpine"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="eventsToShow"></ag-grid-angular>
  </div>
</div>

<!-- <mat-list>
    <mat-list-item *ngFor="let event of eventsToShow">
        <div>{{event.name}}</div>
        <div>{{event.previous_job.company.name}} => {{event.current_job.company.name}}</div>
        <div>{{event.previous_job.company.title}} => {{event.current_job.company.title}}</div>
    </mat-list-item>
</mat-list> -->
<!-- 
<div id="titles"><div id="titlecontent" [innerHtml]="htmlContent">

</div></div> -->
