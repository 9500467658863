<div class="actionContainer">
  <button mat-raised-button color="primary" (click)="showCreateSADialog()">
    Create Service Account
  </button>
  <button mat-raised-button color="primary" (click)="showCreateServiceDialog()">
    Create Service
  </button>
  <button
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    mat-raised-button
    (click)="updateISAs()">
    Refresh
  </button>
</div>
<div class="container" width="100%">
  <ag-grid-angular
    style="height: 100%;"
    [ngClass]="{ 'ag-theme-balham': !(darkModeService.darkMode$ | async), 'ag-theme-balham-dark': darkModeService.darkMode$ | async }"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    accentedSort="true"
    [components]="components"
    [defaultColDef]="defaultColDef"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    (cellValueChanged)="updateServiceAccount($event)"
    (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</div>
