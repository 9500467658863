<div class="actionContainer tw-flex-wrap">
  <button
    mat-raised-button
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    (click)="refreshData()"
    class="tw-shrink-0">
    Refresh
  </button>
  <button mat-raised-button (click)="inspectPerson()" class="tw-shrink-0">Inspect DW Record</button>
  <button
    [class.spinner]="downloading"
    [disabled]="downloading || !latestQuery || viewCount > 10000"
    matTooltip="Only available for 10K or fewer records"
    mat-raised-button
    color="primary"
    (click)="downloadData()"
    class="tw-shrink-0">
    Download all results as CSV
  </button>
  <button mat-raised-button (click)="copyToClipboard()" class="tw-shrink-0">Copy Moves</button>
  <mat-checkbox [formControl]="hideStaleDataControl" class="tw-pl-2">
    Hide Stale Records
  </mat-checkbox>

  <div class="ledgerSize tw-ml-6">
    <mat-progress-spinner
      mode="indeterminate"
      diameter="20"
      *ngIf="countUpdating; else contactCount"
      class="tw-pl-2"></mat-progress-spinner>
    <ng-template #contactCount>
      <span class="tw-font-semibold">
        {{viewCount | number}}
      </span>
    </ng-template>
    Matching Contacts
  </div>
</div>
<div class="container" width="100%">
  <ag-grid-angular
    style="height: 100%; width: 100%"
    [ngClass]="{ 'ag-theme-balham': !(darkModeService.darkMode$ | async), 'ag-theme-balham-dark': darkModeService.darkMode$ | async }"
    [rowData]="rowData"
    [tooltipShowDelay]="tooltipShowDelay"
    [columnDefs]="columnDefs"
    [rowModelType]="rowModelType"
    [infiniteInitialRowCount]="infiniteInitialRowCount"
    [components]="components"
    [rowSelection]="rowSelection"
    [cacheBlockSize]="cacheBlockSize"
    [defaultColDef]="defaultColDef"
    (gridReady)="onGridReady($event)"
    [sideBar]="sideBar"
    [masterDetail]="masterDetail"
    [getRowId]="getRowId"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    [serverSideDatasource]="serverSideDatasource"
    (displayedColumnsChanged)="saveColumnState()">
  </ag-grid-angular>
</div>
