import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-status-badge',
  template: `
  <span class="tw-font-semibold tw-mr-3">{{testStatusLabel}}: </span>
  <span
  *ngIf="!isLoading"
    [ngClass]="{
    'tw-bg-green-100 tw-text-green-800 tw-border-green-400 dark:tw-bg-gray-800 dark:tw-text-green-400 dark:tw-border-green-800': testStatus === 'healthy',
    'tw-bg-red-100 tw-text-red-800 tw-border-red-400 dark:tw-bg-gray-800 dark:tw-text-red-400 dark:tw-border-red-800': testStatus === 'unhealthy',
    'tw-bg-orange-100 tw-text-orange-800 tw-border-orange-400 dark:tw-bg-gray-800 dark:tw-text-yellow-400 dark:tw-border-yellow-800': testStatus === 'unknown' || !['healthy', 'unhealthy', 'unknown'].includes(testStatus)
  }"
  class="tw-text-base tw-font-medium tw-me-2 tw-px-2.5 tw-py-2 tw-rounded tw-border tw-border-solid">
  {{ testStatus }}
  </span>
  <span *ngIf="isLoading" class="spinner tw-relative tw-mx-5"></span>
  <div class="tw-mt-5" *ngIf="showTimeStamp">
    <span class="tw-font-semibold tw-mr-3">Last Tested at: </span>
    <span *ngIf="!isLoading">{{formattedTimeStamp}}</span>
    <span *ngIf="isLoading" class="spinner tw-relative tw-mx-5"></span>
  </div>
  `,
  styles: [
    `
  .spinner:after {
    border-top-color: #313131;
    margin-right: 10px;
    display: inline-block;
  }`,
  ],
})
export class TestStatusBadgeComponent implements OnInit {
  @Input() testStatus: string = '';
  @Input() testStatusLabel: string = '' || 'Destination Status';
  @Input() timeStampFromConfig: string = '';
  @Input() isLoading: boolean = false;
  @Input() showTimeStamp: boolean = true;

  formattedTimeStamp: string = '';

  ngOnInit(): void {
    this.updateTimeStamp(this.timeStampFromConfig);
  }

  updateStatusBadge(status: string): void {
    this.testStatus = status;
  }

  updateTimeStamp(timeStamp: string): void {
    // Check if the timeStamp is a valid date or emptry string or null
    if (!timeStamp) {
      this.formattedTimeStamp = 'Never Tested';
    } else {
      if (Date.parse(timeStamp)) {
        this.formattedTimeStamp = new Date(timeStamp).toLocaleString();
      } else {
        this.formattedTimeStamp = 'Invalid timeStamp';
      }
    }
  }
}
