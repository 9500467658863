<div
  class="field-wrapper filter-name tw-relative tw-w-[190px] tw-h-[26px] tw-bg-indigo-500 tw-text-white tw-flex tw-items-center tw-justify-between tw-rounded tw-border tw-border-solid tw-border-indigo-500 tw-py-1 tw-pl-2 tw-ml-8 tw-mr-1">
  <span class="tw-font-semibold tw-leading-[.9rem]">{{column.displayName}}</span>
  <button
    class="delete-icon-btn tw-relative tw-text-white hover:tw-bg-gray-300 hover:tw-text-gray-700 hover:tw-border-transparent hover:tw-font-bold tw-transition-all tw-mr-1"
    title="Delete this filter"
    (click)="onRemove()">
    <mat-icon class="delete-icon tw-absolute hover:tw-font-bold">delete_outline</mat-icon>
  </button>
</div>

<!-- operators -->
<div
  [ngClass]="{ 'field-wrapper-last': selectedOperator.inputsRequired < 1 }"
  class="field-wrapper tw-relative tw-h-[34px] tw-rounded tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-400 tw-pl-2 tw-mx-1">
  <mat-select
    [(ngModel)]="selectedOperator"
    (ngModelChange)="setFilterParams()"
    [disabled]="availableOperators.length === 1"
    class="operator-dropdown tw-w-40 tw-mr-3">
    <mat-option *ngFor="let item of availableOperators" [value]="item">
      {{item.displayName}}
    </mat-option>
  </mat-select>
</div>

<div
  class="string-display-container field-wrapper tw-max-w-96 tw-flex-wrap tw-gap-x-1 tw-gap-y-2 field-wrapper-last tw-relative tw-rounded tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-400 tw-shrink-0 tw-pr-1 tw-pl-2 tw-py-[4px] tw-ml-1">
  <app-badge
    *ngFor="let value of displayedBadges"
    [value]="value"
    [color]="'dark'"
    (remove)="removeBadge(value)">
  </app-badge>

  <button
    mat-stroked-button
    color="primary"
    *ngIf="filter.filter.string_values && filter.filter.string_values.length > displayBadgeLimit"
    (click)="toggleShowMore()"
    class="tw-h-[24px] tw-leading-[20px] transition duration-300 tw-ml-1 tw-px-[5px]"
    [ngClass]="{'animate-hover-effect': animateNum }">
    <ng-container *ngIf="showMoreBadges; else showMoreLabel">
      <span class="tw-flex tw-items-center">
        <mat-icon class="tw-text-[20px] tw-w-[20px] tw-h-[20px]"> keyboard_arrow_left </mat-icon>
        <span>Show fewer</span>
      </span>
    </ng-container>
    <ng-template #showMoreLabel>
      <span [ngClass]="{'tw-animate-pulse': animateNum }">
        ...and
        <span id="num-badges" class="tw-font-semibold tw-transition-all tw-duration-300">
          {{ filter.filter.string_values.length - displayBadgeLimit }}
        </span>
        more
      </span>
    </ng-template>
  </button>

  <!-- Company Search Component -->
  <div class="tw-ml-1">
    <app-company-search
      resetAfterSelection="true"
      [labelText]="'Add a company'"
      (companySelected)="companySelectedAndAddBadge($event)"
      class="people-filters-company-search">
    </app-company-search>
  </div>
</div>

<div
  class="connector-and"
  [ngClass]="{'tw-bg-white': !isEvenDepth(), 'tw-bg-gray-100': isEvenDepth(), 'tw-text-gray-500': isEvenDepth()}">
  {{ operator }}
</div>
