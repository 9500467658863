<!-- license cards -->
<div class="tw-flex tw-flex-row tw-justify-between tw-content-center tw-mt-4 tw-mb-6">
  <div
    *ngIf="hasMBLicense"
    class="tw-max-w-sm tw-bg-white tw-shadow-md tw-rounded-lg tw-border tw-border-gray-300 dark:tw-bg-gray-800 dark:tw-border-gray-600 tw-border-solid tw-p-6 tw-ml-[.1rem]">
    <div class="tw-mb-4">
      <h3
        class="tw-flex tw-items-center tw-gap-2 tw-text-lg tw-font-semibold tw-text-gray-700 dark:tw-text-gray-100 tw-my-0">
        Available Moneyball Licenses
        <mat-icon>key_outline</mat-icon>
      </h3>
      <p
        *ngIf="!refreshing"
        class="tw-text-2xl tw-font-bold tw-text-primary_purple-600 dark:tw-text-primary_purple-500 tw-my-2">
        {{ remainingLicenses() }}
      </p>
    </div>
    <p class="tw-text-gray-500 tw-mb-4 dark:tw-text-gray-200">
      Out of {{ totalMBLicenses }} total licenses
    </p>
    <a
      [routerLink]="['/' + org.id + '/settings']"
      class="tw-text-primary_purple-500 hover:tw-text-primary_purple-600 dark:tw-text-primary_purple-500 hover:tw-underline tw-font-medium">
      Manage Licenses
    </a>
  </div>
</div>

<!-- action btns -->
<div class="tw-flex tw-gap-4 tw-flex-row tw-items-center tw-my-4 tw-ml-[.1rem]">
  <button
    mat-raised-button
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    (click)="refreshOrgUsers()">
    Refresh
  </button>
  <button
    *ngIf="canAdmin"
    mat-flat-button
    color="warn"
    [disabled]="!rowsSelected"
    (click)="removeSelectedUsers()"
    class="disabled:tw-border-gray-400 disabled:tw-text-gray-400 disabled:tw-cursor-not-allowed">
    Remove Selected Users
  </button>
</div>

<!-- ag-grid table -->
<div class="tw-w-full tw-max-w-[1600px]">
  <ag-grid-angular
    style="height: 100%;"
    [ngClass]="{ 'ag-theme-quartz': !(darkModeService.darkMode$ | async), 'ag-theme-quartz-dark': darkModeService.darkMode$ | async }"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [tooltipShowDelay]="tooltipShowDelay"
    accentedSort="true"
    [components]="components"
    [rowSelection]="rowSelection"
    [defaultColDef]="defaultColDef"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    domLayout="autoHeight"
    (gridReady)="onGridReady($event)"
    [readOnlyEdit]="true"
    (cellEditRequest)="updateUser($event)"
    (selectionChanged)="onSelectionChanged($event)">
  </ag-grid-angular>
</div>
