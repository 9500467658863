import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { QuickBuild, QuickBuildService } from '../../quick-build.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-quick-builds',
  templateUrl: './quick-builds.component.html',
  styles: [
    `
    .compact-btn.quick-build-btns {
      color: var(--dark-white-dynamic-text) !important;
      border: .6px solid var(--gray-500) !important;
      display: block !important;
    }
    `,
  ],
})
export class QuickBuildsComponent implements OnInit {
  private destroy$ = new Subject<void>();
  @Output() quickBuildSelected = new EventEmitter<QuickBuild>();
  @Input() showQuickBuilds: boolean = true;
  quickBuilds: QuickBuild[] = [];
  selectedQuickBuildId: number;

  constructor(private qbService: QuickBuildService) {}

  ngOnInit(): void {
    this.qbService.quickBuilds$.pipe(takeUntil(this.destroy$)).subscribe((quickBuilds) => {
      if (!quickBuilds) return;

      this.quickBuilds = quickBuilds.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  setQuickBuildFilters(quickBuildId: number) {
    const saved = this.quickBuilds.find((s) => s.id == quickBuildId);
    if (saved) {
      this.selectedQuickBuildId = quickBuildId;
      this.quickBuildSelected.emit(saved);
    }
  }

  deleteQuickBuild(id: number) {
    this.qbService.deleteQuickBuild(id);
  }

  toggleQuickBuilds() {
    this.showQuickBuilds = !this.showQuickBuilds;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
