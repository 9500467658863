<div class="tw-flex tw-min-h-full">
  <div
    id="login-signup"
    class="tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-bg-gray-100 dark:tw-bg-gray-900 tw-min-w-[60%]">
    <!-- Header -->
    <header class="tw-p-4 tw-flex tw-justify-between tw-items-center dark:tw-bg-gray-900">
      <app-dark-mode-toggle
        customClasses="tw-absolute tw-top-2 tw-left-2 lg:tw-top-4 lg:tw-left-4"></app-dark-mode-toggle>
    </header>

    <!-- Main Content -->
    <main
      class="tw-flex-grow tw-flex tw-flex-col tw-justify-center tw-items-center tw-mx-4 tw-my-8">
      <!-- logo -->
      <div class="tw-flex tw-items-center tw-mb-6 lg:tw-mb-8">
        <a href="https://www.livedatatechnologies.com">
          <img class="ldt-logo" src="../../assets/img/logo-black.svg" alt="logo" />
        </a>
      </div>

      <!-- onboarding -->
      <div class="onboarding">
        <div class="main-column">
          <app-stepper #cdkStepper [linear]="linear" (gotoStep)="gotoStep($event)" color="primary">
            <!-- step: profile -->
            <cdk-step label="Profile" [stepControl]="contactFormGroup">
              <!-- profile -->
              <div *ngIf="currentStep == 'profile'">
                <div class="header tw-text-gray-800 dark:tw-text-white">Personal Information</div>
                <div class="description tw-text-gray-800 dark:tw-text-white">
                  To get started, tell us about yourself
                </div>
                <div class="content tw-text-gray-800 dark:tw-text-white">
                  <form [formGroup]="contactFormGroup" class="tw-w-full tw-max-w-md">
                    <mat-form-field class="onboarding-form-field">
                      <mat-label>Name</mat-label>
                      <input matInput placeholder="John Doe" formControlName="name" required />
                    </mat-form-field>
                    <mat-form-field class="onboarding-form-field">
                      <mat-label>Company</mat-label>
                      <input
                        matInput
                        placeholder="Acme Widgets"
                        formControlName="company"
                        required />
                    </mat-form-field>
                    <mat-form-field class="onboarding-form-field">
                      <mat-label>Email Address</mat-label>
                      <input
                        matInput
                        placeholder="bill@microsoft.com"
                        formControlName="emailAddress"
                        required />
                    </mat-form-field>
                    <div class="footer tw-mt-4">
                      <button
                        type="submit"
                        mat-flat-button
                        class="continue-btn dark:hover:tw-bg-teal-400"
                        color="primary"
                        [class.spinner]="continuing"
                        [disabled]="!contactFormGroup.valid || continuing"
                        (click)="nextStep()">
                        Continue
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <!-- step: persona -->
              <div *ngIf="currentStep == 'persona'" class="tw-text-gray-800 dark:tw-text-white">
                <div class="header">Get onboarded with Live Data</div>
                <div class="description">What are you trying to accomplish?</div>
                <div class="content">
                  <form [formGroup]="personaFormGroup">
                    <mat-radio-group formControlName="persona" color="primary">
                      <mat-radio-button *ngFor="let persona of personas" [value]="persona">
                        <div class="optionHeadline tw-ml-2">{{persona.headline}}</div>
                        <div class="optionGoal tw-ml-2">{{persona.goal}}</div>
                      </mat-radio-button>
                    </mat-radio-group>
                    <div class="footer">
                      <div (click)="prevStep()"><mat-icon>arrow_back</mat-icon> Back</div>
                      <button
                        type="submit"
                        mat-flat-button
                        class="continue-btn dark:hover:tw-bg-teal-400"
                        color="primary"
                        [class.spinner]="continuing"
                        [disabled]="!personaFormGroup.valid || continuing"
                        (click)="nextStep()">
                        Continue
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <!-- step: goals -->
              <div *ngIf="currentStep == 'goals'" class="tw-text-gray-800 dark:tw-text-white">
                <!-- investor -->
                <div *ngIf="personaFormGroup.get('persona')?.value?.name === 'investor'">
                  <div class="header">Get company insights</div>
                  <div class="description">
                    Tell us the companies you care about and we'll deliver insights straight to you
                  </div>
                  <div class="content">
                    <form [formGroup]="goalFormGroup">
                      <mat-radio-group formControlName="goal" color="primary">
                        <mat-radio-button *ngFor="let goal of investorGoals" [value]="goal">
                          <div class="optionHeadline tw-ml-2">{{goal.headline}}</div>
                          <div class="optionGoal tw-ml-2">{{goal.goal}}</div>
                        </mat-radio-button>
                      </mat-radio-group>
                      <div class="footer">
                        <div (click)="prevStep()"><mat-icon>arrow_back</mat-icon> Back</div>
                        <button
                          type="submit"
                          mat-flat-button
                          class="continue-btn dark:hover:tw-bg-teal-400"
                          color="primary"
                          [class.spinner]="continuing"
                          [disabled]="!goalFormGroup.valid || continuing"
                          (click)="nextStep()">
                          Continue
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <!-- gtm -->
                <div *ngIf="personaFormGroup.get('persona')?.value?.name === 'gtm'">
                  <div class="header">Get started with Live Data</div>
                  <div class="description">Let us surface your best prospects.</div>
                  <div class="content">
                    <form [formGroup]="goalFormGroup">
                      <mat-radio-group formControlName="goal" color="primary">
                        <mat-radio-button *ngFor="let goal of gtmGoals" [value]="goal">
                          <div class="optionHeadline tw-ml-2">{{goal.headline}}</div>
                          <div class="optionGoal tw-ml-2">{{goal.goal}}</div>
                        </mat-radio-button>
                      </mat-radio-group>
                      <div class="footer">
                        <div (click)="prevStep()"><mat-icon>arrow_back</mat-icon> Back</div>
                        <button
                          type="submit"
                          mat-flat-button
                          class="continue-btn dark:hover:tw-bg-teal-400"
                          color="primary"
                          [class.spinner]="continuing"
                          [disabled]="!goalFormGroup.valid || continuing"
                          (click)="nextStep()">
                          Continue
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <!-- data -->
                <div *ngIf="personaFormGroup.get('persona')?.value?.name === 'data'">
                  <div class="header tw-mb-4">For developers and data vendors</div>
                  <div class="description">
                    Looking to improve your data quality or incorporate real-time job change data
                    into your offering?
                  </div>
                  <div class="description">
                    Tell us what you're working on and we'll reach out to chat.
                    <form [formGroup]="dataFormGroup" class="tw-mt-4">
                      <mat-form-field class="onboarding-form-field">
                        <textarea
                          matInput
                          formControlName="description"
                          placeholder="What are you trying to accomplish?"></textarea>
                      </mat-form-field>
                      <div class="footer">
                        <div (click)="prevStep()"><mat-icon>arrow_back</mat-icon> Back</div>
                        <button
                          *ngIf="!complete"
                          type="submit"
                          mat-flat-button
                          class="continue-btn dark:hover:tw-bg-teal-400"
                          color="primary"
                          [class.spinner]="continuing"
                          [disabled]="!dataFormGroup.valid || continuing"
                          (click)="nextStep()">
                          Continue
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </cdk-step>

            <!-- step: details -->
            <cdk-step label="Details">
              <!-- gtm -->
              <div *ngIf="personaFormGroup.get('persona')?.value?.name === 'gtm'">
                <!-- sample -->
                <div
                  *ngIf="goalFormGroup.get('goal')?.value?.name === 'sample'"
                  class="tw-text-gray-800 dark:tw-text-white">
                  <div class="header">Who do you sell to?</div>
                  <div class="description">
                    Select your target customers by industry, company, function, and level.
                  </div>
                  <div class="content">
                    <form [formGroup]="sampleFormGroup">
                      <ng-select
                        formControlName="industries"
                        [multiple]="true"
                        [closeOnSelect]="false"
                        placeholder="Select industries...">
                        <ng-option *ngFor="let industry of industries" [value]="industry">
                          {{industry}}
                        </ng-option>
                      </ng-select>
                      <ng-select
                        formControlName="functions"
                        [multiple]="true"
                        [closeOnSelect]="false"
                        placeholder="Select functions...">
                        <ng-option *ngFor="let function of functions" [value]="function">
                          {{function}}
                        </ng-option>
                      </ng-select>
                      <ng-select
                        formControlName="levels"
                        [multiple]="true"
                        [closeOnSelect]="false"
                        placeholder="Select levels...">
                        <ng-option *ngFor="let level of levels" [value]="level">
                          {{level}}
                        </ng-option>
                      </ng-select>
                      <div class="footer">
                        <div (click)="prevStep()"><mat-icon>arrow_back</mat-icon> Back</div>
                        <button
                          *ngIf="goalFormGroup.get('goal')?.value?.name === 'sample'"
                          mat-raised-button
                          color="primary"
                          (click)="nextStep()">
                          Continue
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </cdk-step>

            <!-- step: welcome -->
            <cdk-step label="Welcome!">
              <!-- data -->
              <div
                *ngIf="!uploadActive && personaFormGroup.get('persona')?.value?.name === 'data'"
                class="tw-text-gray-700 dark:tw-text-white">
                <div class="header">Thank you for your interest!</div>
                <div class="description">We'll be in touch!</div>
                <div class="description">
                  Our team will reach out to discuss how Live Data can help you use real-time job
                  change data.
                </div>
                <div class="description">
                  <a
                    href="https://www.livedatatechnologies.com"
                    class="tw-underline tw-font-semibold dark:tw-text-white dark:tw-decoration-teal-400 tw-transition-all">
                    In the meantime, continue exploring the value of real-time job change data on
                    our website.
                  </a>
                </div>

                <div class="footer">
                  <div (click)="prevStep()"><mat-icon>arrow_back</mat-icon> Back</div>
                </div>
              </div>

              <!-- investor -->
              <div
                *ngIf="personaFormGroup.get('persona')?.value?.name === 'investor'"
                class="tw-text-gray-700 dark:tw-text-white">
                <div class="header tw-mb-4">Thank You!</div>
                <div class="description">
                  Check your email to create your password and get immediate access to the Live Data
                  platform.
                </div>
                <div class="content">
                  <!-- <img src="../assets/img/moneyball-sample.png" alt="sample" /> -->
                  <img
                    src="../assets/img/onboarding-moneyball-sample.png"
                    alt="sample"
                    class="tw-rounded-xl" />
                </div>
              </div>
              <!-- gtm/sample -->
              <div
                *ngIf="personaFormGroup.get('persona')?.value?.name === 'gtm' && goalFormGroup.get('goal')?.value?.name === 'sample'"
                class="tw-text-gray-700 dark:tw-text-white">
                <div class="header tw-mb-4">We're building your sample dataset!</div>
                <div class="description">Thank you for providing your ICP information</div>
                <div class="description">
                  You will receive an email shortly once your custom data sample is ready.
                </div>
                <div class="description">
                  <a
                    href="https://www.livedatatechnologies.com"
                    class="tw-underline tw-font-semibold dark:tw-text-white dark:tw-decoration-teal-400 tw-transition-all">
                    In the meantime, continue exploring the value of real-time job change data on
                    our website.
                  </a>
                </div>
                <div class="footer">
                  <div (click)="prevStep()"><mat-icon>arrow_back</mat-icon> Back</div>
                </div>
              </div>
              <div
                *ngIf="personaFormGroup.get('persona')?.value?.name === 'gtm' && goalFormGroup.get('goal')?.value?.name === 'upload'"
                class="tw-text-gray-700 dark:tw-text-white">
                <div class="header">Thank you!</div>
                <div class="description">
                  Check your email to create your password and access the Live Data platform to
                  upload your data sample.
                </div>
                <div class="content">
                  <img src="../assets/img/ledgers_sample.png" alt="sample" />
                </div>
              </div>
            </cdk-step>
          </app-stepper>

          <div class="tw-flex tw-flex-col">
            <!-- help -->
            <div
              class="tw-font-light tw-text-center lg:tw-text-left tw-text-gray-700 dark:tw-text-white tw-mt-8">
              Need help?
              <a
                href="javascript:void(0)"
                onclick="window['Intercom']('showNewMessage')"
                class="tw-font-medium tw-text-dynamic-link-color hover:tw-underline tw-inline-block tw-ml-1">
                Contact us!
              </a>
            </div>

            <a
              href="/"
              class="tw-font-medium tw-text-center lg:tw-text-left tw-text-link-color-dynamic tw-transition-all tw-mt-3">
              <span class="hover:tw-underline">Back to Homepage</span>
            </a>
          </div>
        </div>
      </div>
    </main>

    <!-- Footer -->
    <footer
      class="tw-flex tw-items-center tw-justify-center lg:tw-justify-start tw-flex-wrap tw-gap-6 lg:tw-gap-8 tw-bg-gray-800 dark:tw-bg-gray-900 tw-text-gray-400 dark:tw-text-white tw-py-4 tw-text-center tw-mt-auto tw-px-4 lg:tw-bg-gray-100 dark:lg:tw-bg-gray-900 lg:tw-text-gray-500 dark:lg:tw-text-white">
      <div class="lg:tw-ml-4 tw-text-sm">©2024 Live Data Technologies, Inc.</div>
      <nav class="tw-flex tw-justify-start tw-space-x-6">
        <a
          href="https://www.livedatatechnologies.com/privacy-policy"
          target="_blank"
          class="tw-flex tw-items-center tw-text-white tw-text-sm lg:tw-text-gray-700 dark:tw-text-white">
          <span class="hover:tw-underline hover:tw-font-semibold tw-transition-all">
            Privacy Policy
          </span>
          <mat-icon class="tw-text-base">north_east</mat-icon>
        </a>
        <a
          href="https://www.livedatatechnologies.com/terms-of-service"
          target="_blank"
          class="tw-flex tw-items-center tw-text-white tw-text-sm lg:tw-text-gray-700 dark:tw-text-white">
          <span class="hover:tw-underline hover:tw-font-semibold tw-transition-all">
            Terms of Use
          </span>
          <mat-icon class="tw-text-base">north_east</mat-icon>
        </a>
      </nav>
    </footer>
  </div>

  <app-side-img-decoration></app-side-img-decoration>
</div>
