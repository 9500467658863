<section>
  <div class="tw-max-w-screen-xl lg:tw-py-16 lg:tw-px-6 tw-py-8 tw-px-4 tw-mx-auto">
    <div class=" tw-max-w-screen-md tw-text-center tw-mb-8 lg:tw-mb-12 tw-mx-auto">
      <h2
        class="tw-mb-4 tw-text-4xl tw-tracking-tight tw-font-extrabold tw-text-gray-800 dark:tw-text-white">
        <span
          class="tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-to-purple-600 tw-from-sky-500">
          Unlock The Power
        </span>
        Of Millions Of Job Changes
      </h2>
      <p class="tw-mb-5 tw-font-light tw-text-gray-500 dark:tw-text-gray-300 sm:tw-text-xl">
        Our platform monitors millions of job changes monthly at the individual-level. Get the data
        and insights you need, how you need them.
      </p>
    </div>

    <div class="">
      <app-billing-products-moneyball
        [subscription]="subscription"></app-billing-products-moneyball>
    </div>
  </div>
</section>
