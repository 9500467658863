<div
  class="tw-flex tw-row tw-justify-between tw-items-center tw-flex-wrap tw-mb-4 people-container">
  <div>
    <h1 class="content-title tw-items-center tw-mb-0 tw-mt-0">Live Data People</h1>
  </div>
  <div class="tw-flex tw-items-center tw-flex-row">
    <a
      target="_blank"
      href="https://docs.gotlivedata.com/docs/people-api#/"
      class="tw-flex tw-items-center tw-gap-1 tw-font-medium tw-text-link-color-dynamic-darker tw-text-sm hover:tw-underline">
      <mat-icon class="info-icon tw-h-4 tw-w-4">school</mat-icon>
      Learn about the People API
    </a>
  </div>
</div>

<div class="content tw-flex-col">
  <nav mat-tab-nav-bar>
    <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./details" class="tw-text-base">
      Search
    </a>
    <a
      *ngIf="(isAdmin | async) || (orgSettings | async)?.settings?.people?.features?.explore"
      mat-tab-link
      routerLinkActive="active-tab-nav"
      routerLink="./filters"
      class="tw-text-base">
      Explore
      <span
        class="tw-inline-flex tw-items-center tw-rounded-md tw-leading-4 tw-bg-blue-50 tw-text-sm tw-font-semibold tw-text-blue-700 dark:tw-bg-transparent dark:tw-text-teal-400 dark:tw-ring-teal-400/50 tw-ring-1 tw-ring-inset tw-ring-blue-700/50 tw-px-2 tw-py-1 tw-ml-2">
        beta
      </span>
    </a>
    <a
      *ngIf="isAdmin | async"
      mat-tab-link
      routerLinkActive="active-tab-nav"
      routerLink="./find"
      class="tw-text-base">
      Find
    </a>
  </nav>
  <router-outlet></router-outlet>
</div>
