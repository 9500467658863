<div class="tw-flex tw-min-h-full">
  <div
    id="reset-password"
    class="tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-bg-gray-100 tw-min-w-[50%] dark:tw-bg-gray-900">
    <!-- dark mode switch -->
    <app-dark-mode-toggle
      customClasses="tw-absolute tw-top-2 tw-left-2 lg:tw-top-4 lg:tw-left-4"></app-dark-mode-toggle>

    <!-- Main Content -->
    <main class="tw-flex-grow tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-4">
      <!-- forgot password w/ email -->
      <section
        *ngIf="!enterPassword"
        class="bg-gray-50 tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6 tw-py-8 tw-mx-auto md:tw-h-screen lg:tw-py-0 lg:tw-min-w-[500px]">
        <a routerLink="/" class="tw-mb-6 lg:tw-mb-8">
          <img class="ldt-logo" src="../../assets/img/logo-black.svg" alt="logo" />
        </a>
        <div
          class="tw-w-full tw-bg-white tw-rounded-md tw-shadow md:tw-mt-0 sm:tw-max-w-md dark:tw-bg-gray-800 xl:tw-p-0">
          <div class="tw-p-6 tw-space-y-4 md:tw-space-y-6 sm:tw-p-8">
            <div
              *ngIf="!emailSent"
              class="tw-flex tw-items-center tw-gap-2 tw-text-base tw-text-blue-800 tw-border tw-border-blue-300 tw-rounded-lg tw-bg-blue-50 dark:tw-bg-gray-800 dark:tw-text-white dark:tw-border-white tw-p-4 tw-mb-4"
              role="alert">
              <mat-icon class="material-icons-outlined tw-flex-shrink-0 tw-inline tw-me-3">
                info_outline
              </mat-icon>
              <div>Don't worry! We'll send you a link to reset your password.</div>
            </div>
            <h1
              class="tw-text-xl tw-font-bold tw-leading-tight tw-tracking-tight tw-text-gray-800 dark:tw-text-white md:tw-text-2xl tw-mt-0">
              Forgot password
            </h1>
            <form
              class="tw-space-y-4 md:tw-space-y-6"
              [formGroup]="form"
              (ngSubmit)="initiateReset()">
              <div class="tw-w-full">
                <label
                  for="email"
                  class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">
                  Your email address
                </label>
                <input
                  type="email"
                  id="email"
                  formControlName="email"
                  placeholder="name@company.com"
                  required
                  [disabled]="enterPassword"
                  class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-rounded focus:tw-ring-primary_purple-600 focus:tw-border-primary_purple-600 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-600 dark:tw-border-gray-500 dark:tw-placeholder-gray-300 dark:tw-text-white" />
              </div>

              <button
                type="submit"
                [disabled]="emailSent"
                [ngClass]="{
                'tw-opacity-65 tw-cursor-not-allowed': emailSent,
                'hover:tw-bg-primary_purple-700 focus:tw-ring-4 focus:tw-ring-primary_purple-300': !emailSent
              }"
                class="tw-w-full tw-bg-link-color-dynamic tw-text-white-dark-dynamic tw-font-semibold tw-transition-all hover:tw-bg-primary_purple-700 dark:hover:tw-bg-teal-400 tw-rounded tw-text-sm tw-px-5 tw-py-2.5 tw-text-center">
                Reset
              </button>

              <div
                *ngIf="emailSent"
                class="tw-flex tw-flex-wrap tw-items-center tw-text-base tw-border tw-rounded-lg tw-text-green-800 tw-border-green-300 tw-bg-green-50 dark:tw-bg-gray-800 dark:tw-text-green-400 dark:tw-border-green-800 tw-p-4 tw-mb-4"
                role="alert">
                <span class="tw-font-semibold tw-mr-1">Email sent.</span> Please check your inbox
                for a reset link.
              </div>
            </form>

            <a
              (click)="goToLogin()"
              href="javascript:void(0)"
              class="tw-text-sm tw-font-medium tw-text-link-color-dynamic hover:tw-underline tw-transition-all tw-inline-block tw-mb-8">
              Return to login
            </a>
          </div>
        </div>
      </section>

      <!-- create new password form -->
      <section
        *ngIf="enterPassword"
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6 tw-py-8 tw-mx-auto md:tw-h-screen lg:tw-py-0 md:tw-min-w-[500px] dark:tw-bg-gray-900">
        <a routerLink="/" class="tw-mb-6 lg:tw-mb-8">
          <img class="ldt-logo" src="../../assets/img/logo-black.svg" alt="logo" />
        </a>
        <div
          class="tw-w-full tw-bg-white tw-rounded-md tw-shadow md:tw-mt-0 sm:tw-max-w-md xl:tw-p-0">
          <div class="tw-p-6 tw-space-y-4 md:tw-space-y-6 dark:tw-bg-gray-800 sm:tw-p-8">
            <div>
              <h1
                class="tw-text-xl tw-font-bold tw-leading-tight tw-tracking-tight tw-text-gray-800 dark:tw-text-white md:tw-text-2xl">
                {{ isOnboarding ? 'Get Started' : 'Reset Password'}}
              </h1>
              <p *ngIf="isOnboarding" class="tw-text-base tw-text-gray-700 dark:tw-text-white">
                Welcome to Live Data. Set your password to login and view your sample data.
              </p>
            </div>

            <form
              [formGroup]="form"
              (ngSubmit)="completeReset()"
              class="tw-space-y-4 md:tw-space-y-6">
              <div class="tw-w-full">
                <label
                  for="email"
                  class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">
                  Email address
                </label>
                <input
                  type="email"
                  id="email"
                  [disabled]="enterPassword"
                  formControlName="email"
                  placeholder="name@company.com"
                  required
                  class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-rounded focus:tw-ring-primary_purple-600 focus:tw-border-primary_purple-600 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-600 dark:tw-border-gray-500 dark:tw-placeholder-gray-300 dark:tw-text-white" />
              </div>

              <div class="tw-w-full">
                <div>
                  <label
                    for="password"
                    class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">
                    New Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    formControlName="passwordFormControl"
                    placeholder="••••••••"
                    [type]="showPassword ? 'text' : 'password'"
                    required
                    (input)="checkingPassword($event);"
                    class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-rounded focus:tw-ring-primary_purple-600 focus:tw-border-primary_purple-600 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-600 dark:tw-border-gray-500 dark:tw-placeholder-gray-300 dark:tw-text-white" />
                </div>

                <div class="tw-flex tw-items-center tw-justify-between tw-mt-3">
                  <button
                    type="button"
                    (click)="togglePasswordVisibility()"
                    class="tw-text-gray-700 hover:tw-text-primary_purple-700 tw-transition-all tw-text-sm focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary_purple-600 focus:tw-ring-offset-2 tw-rounded dark:tw-text-white dark:hover:tw-text-gray-100">
                    <span *ngIf="showPassword" class="tw-flex tw-items-center tw-gap-1">
                      <mat-icon class="tw-w-5 tw-h-5 tw-text-sm">visibility_off</mat-icon>
                      <span class="dark:hover:tw-underline">Hide password</span>
                    </span>
                    <span *ngIf="!showPassword" class="tw-flex tw-items-center tw-gap-1">
                      <mat-icon class="tw-w-5 tw-h-5 tw-text-sm">visibility</mat-icon>
                      <span class="dark:hover:tw-underline">Show password</span>
                    </span>
                  </button>
                </div>

                <!-- pw requirements & validation -->
                <div
                  class="tw-flex tw-flex-col tw-text-sm tw-text-blue-800 tw-rounded-lg tw-border tw-bg-blue-50 dark:tw-bg-gray-800 dark:tw-text-white dark:tw-border-white tw-p-4 tw-my-4"
                  role="alert">
                  <div class="tw-flex tw-items-center">
                    <mat-icon
                      class="material-icons-outlined tw-inline tw-text-[1.2rem] tw-h-5 tw-mr-1">
                      info_outline
                    </mat-icon>
                    <span class="tw-font-semibold">Password requirement:</span>
                  </div>

                  <ul class="tw-list-disc tw-list-inside tw-mt-1.5 tw-mb-0">
                    <li
                      *ngFor="let v of pValidation;"
                      class="tw-relative"
                      [ngClass]="{'tw-text-red-500 tw-font-semibold': !v.valid}">
                      <span class="tw-pr-1">{{v.text}}</span>
                      <mat-icon
                        *ngIf="!v.valid"
                        class="material-icons tw-absolute -tw-top-1 tw-text-red-500 tw-inline">
                        warning
                      </mat-icon>
                    </li>
                  </ul>
                </div>
              </div>

              <button
                type="submit"
                [value]="submitButtonValue"
                [ngClass]="{
                  'tw-opacity-65 tw-cursor-not-allowed': !form.valid || emailSent,
                  'hover:tw-bg-primary_purple-700 dark:hover:tw-bg-teal-400 focus:tw-ring-4 focus:tw-ring-primary_purple-300': !emailSent
                }"
                class="tw-w-full tw-bg-link-color-dynamic tw-text-white-dark-dynamic tw-font-semibold tw-transition-all tw-rounded tw-text-sm tw-px-5 tw-py-2.5 tw-text-center"
                [disabled]="!form.valid || emailSent">
                {{ isOnboarding ? 'Get Started' : 'Reset Password'}}
              </button>

              <div
                *ngIf="validationError"
                class="tw-flex tw-items-center tw-text-sm tw-text-red-800 tw-border tw-border-red-300 tw-rounded-lg tw-bg-red-50 dark:tw-bg-gray-800 dark:tw-text-red-400 dark:tw-border-red-800 tw-p-4 tw-mb-4"
                role="alert">
                <span class="tw-font-semibold tw-mr-1">Error!</span>Please check your password and
                try again.
              </div>
            </form>
            <a
              (click)="goToLogin()"
              href="javascript:void(0)"
              class="tw-text-sm tw-font-medium tw-text-link-color-dynamic hover:tw-underline tw-inline-block tw-mb-8">
              Return to login
            </a>
          </div>
        </div>
      </section>
    </main>

    <!-- Footer -->
    <footer
      class="tw-flex tw-items-center tw-justify-center lg:tw-justify-start tw-flex-wrap tw-gap-6 lg:tw-gap-8 tw-bg-gray-800 dark:tw-bg-gray-900 tw-text-gray-400 dark:tw-text-white tw-py-4 tw-text-center tw-mt-auto tw-px-4 lg:tw-bg-gray-100 dark:lg:tw-bg-gray-900 lg:tw-text-gray-500 dark:lg:tw-text-white">
      <div class="lg:tw-ml-4 tw-text-sm">©2024 Live Data Technologies, Inc.</div>
      <nav class="tw-flex tw-justify-start tw-space-x-6">
        <a
          href="https://www.livedatatechnologies.com/privacy-policy"
          target="_blank"
          class="tw-flex tw-items-center tw-text-white tw-text-sm lg:tw-text-gray-700 dark:tw-text-white">
          <span class="hover:tw-underline hover:tw-font-semibold tw-transition-all">
            Privacy Policy
          </span>
          <mat-icon class="tw-text-base">north_east</mat-icon>
        </a>
        <a
          href="https://www.livedatatechnologies.com/terms-of-service"
          target="_blank"
          class="tw-flex tw-items-center tw-text-white tw-text-sm lg:tw-text-gray-700 dark:tw-text-white">
          <span class="hover:tw-underline hover:tw-font-semibold tw-transition-all">
            Terms of Use
          </span>
          <mat-icon class="tw-text-base">north_east</mat-icon>
        </a>
      </nav>
    </footer>
  </div>

  <app-side-img-decoration></app-side-img-decoration>
</div>
