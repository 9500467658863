<div class="actionContainer tw-flex tw-flex-wrap tw-gap-y-2 tw-items-center tw-mt-4 tw-mb-3">
  <button
    mat-raised-button
    [disabled]="!canEdit || !areRowsSelected()"
    color="warn"
    (click)="deleteSelectedContacts()"
    class="tw-shrink-0">
    Delete Selected Contacts
  </button>
  <button
    mat-raised-button
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    (click)="refreshData()"
    class="tw-shrink-0">
    Refresh
  </button>
  <button
    mat-raised-button
    [class.spinner]="downloading"
    [disabled]="downloading"
    [matMenuTriggerFor]="downloadmenu"
    class="tw-shrink-0">
    Download Contacts
    <mat-menu #downloadmenu="matMenu">
      <a mat-menu-item (click)="downloadView();">Current View</a>
      <a mat-menu-item (click)="downloadAll();">All Contacts</a>
    </mat-menu>
  </button>
  <button *ngIf="isAdmin | async" mat-raised-button (click)="inspect()" class="tw-shrink-0">
    Inspect
  </button>
  <button *ngIf="isAdmin | async" mat-raised-button (click)="find()" class="tw-shrink-0">
    Find
  </button>
  <button
    *ngIf="(isAdmin | async) || (orgHasReinit && canAdmin)"
    mat-raised-button
    [class.spinner]="reiniting"
    [disabled]="reiniting"
    (click)="reinitLedger()"
    class="tw-shrink-0">
    Re-initialize Contacts
  </button>
  <span class="tw-max-w-[170px] tw-ml-3">
    <a
      target="_blank"
      href="https://docs.gotlivedata.com/docs/understanding-ledger-data-fields"
      class="tw-text-link-color-dynamic-darker tw-text-sm hover:tw-underline">
      <mat-icon class="info-icon">info</mat-icon>
      Understanding the data fields in your ledger
    </a>
  </span>
  <span class="ledgerSize tw-text-sm tw-ml-4">
    <mat-progress-spinner
      mode="indeterminate"
      diameter="20"
      *ngIf="countUpdating; else contactCount"></mat-progress-spinner>
    <ng-template #contactCount>
      <span class="tw-font-semibold">{{viewCount | number}}</span>
    </ng-template>
    <span class="tw-ml-2">Matching Contacts</span>
  </span>
</div>

<div class="container tw-px-0" width="100%">
  <ag-grid-angular
    style="height: 100%; width: 100%"
    [ngClass]="{ 'ag-theme-balham': !(darkModeService.darkMode$ | async), 'ag-theme-balham-dark': darkModeService.darkMode$ | async }"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [rowModelType]="rowModelType"
    [cacheBlockSize]="cacheBlockSize"
    [components]="components"
    [rowSelection]="rowSelection"
    [defaultColDef]="defaultColDef"
    (gridReady)="onGridReady($event)"
    [sideBar]="sideBar"
    [getRowId]="getRowId"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    [serverSideDatasource]="serverSideDatasource"
    (displayedColumnsChanged)="saveColumnState()">
  </ag-grid-angular>
</div>
