<div class="tw-flex tw-flex-col tw-h-full tw-p-6">
  <!-- Search Form -->
  <div class="tw-flex tw-flex-row tw-mb-6 tw-content-center">
    <form [formGroup]="inputFormGroup" class="tw-flex tw-gap-2">
      <app-company-search
        [labelText]="'Search for a company'"
        [showConglomerates]="false"
        (companySelected)="companySelected($event)"
        class="people-filters-company-search">
      </app-company-search>
      <div class="tw-flex tw-gap-2 tw-h-10">
        <button
          type="submit"
          mat-raised-button
          [disabled]="!inputFormGroup.valid || refreshing"
          color="primary"
          [class.spinner]="refreshing"
          (click)="refresh()">
          Refresh
        </button>
        <button
          mat-raised-button
          [disabled]="!inputFormGroup.valid || refreshing || replaying"
          color="primary"
          [class.spinner]="refreshing || replaying"
          (click)="replayCompany()">
          IS Replay
        </button>
      </div>
    </form>
  </div>

  <!-- Company Information Display -->
  <div
    *ngIf="companyJson"
    class="tw-bg-white dark:tw-bg-gray-800 tw-rounded-lg tw-shadow-md tw-p-6">
    <!-- Header Section -->
    <div class="tw-flex tw-justify-between tw-items-center tw-mb-6">
      <div>
        <h1 class="tw-text-2xl tw-font-bold tw-mb-2 dark:tw-text-white">
          {{companyJson.name}}
          <span
            *ngIf="companyJson.ticker"
            class="tw-text-sm tw-bg-blue-100 dark:tw-bg-blue-900 tw-px-2 tw-py-1 tw-rounded">
            {{companyJson.ticker}}
          </span>
        </h1>
        <div class="tw-flex tw-gap-4 tw-text-gray-600 dark:tw-text-gray-300 tw-items-center">
          <span *ngIf="companyJson.type">
            <mat-icon class="tw-mr-1">business</mat-icon>{{companyJson.type}}
          </span>
          <span *ngIf="companyJson.founded_year">
            <mat-icon class="tw-mr-1">event</mat-icon>Founded: {{companyJson.founded_year}}
          </span>
          <span *ngIf="companyJson.domain">
            <mat-icon class="tw-mr-1">dns</mat-icon
            ><a href="https://{{companyJson.domain}}" target="_blank">{{companyJson.domain}}</a>
          </span>
          <span *ngIf="companyJson.linkedin">
            <img class="tw-h-5 tw-w-5 tw-mr-2" src="assets/img/linkedin-3-48.png" />
            <a
              href="https://linkedin.com/company/{{companyJson.linkedin}}"
              target="_blank"
              >{{companyJson.linkedin}}</a
            >
            <span *ngIf="companyJson.linkedin_numeric_id"
              >(<a
                href="https://linkedin.com/company/{{companyJson.linkedin_numeric_id}}"
                target="_blank"
                >{{companyJson.linkedin_numeric_id}}</a
              >)</span
            >
          </span>
        </div>
      </div>
    </div>

    <!-- Main Content Grid -->
    <div class="tw-grid tw-grid-cols-2 tw-gap-3 tw-align-top">
      <!-- Company Details -->
      <div class="tw-bg-gray-50 dark:tw-bg-gray-700 tw-p-4 tw-rounded">
        <h2 class="tw-text-lg tw-font-semibold tw-mb-4 dark:tw-text-white">Company Details</h2>
        <div class="tw-space-y-3 dark:tw-text-gray-200">
          <div *ngIf="companyJson.id" class="tw-flex tw-items-center">
            <mat-icon class="tw-mr-2">fingerprint</mat-icon>
            <span>{{companyJson.id}}</span>
          </div>
          <div *ngIf="companyJson.employee_count" class="tw-flex tw-items-center">
            <mat-icon class="tw-mr-2">people</mat-icon>
            <span>{{companyJson.employee_count}} Employees</span>
          </div>
          <div *ngIf="companyJson.industry" class="tw-flex tw-items-center">
            <mat-icon class="tw-mr-2">category</mat-icon>
            <span>{{companyJson.industry}}</span>
          </div>
          <div *ngIf="companyJson.location" class="tw-flex tw-items-center">
            <mat-icon class="tw-mr-2">location_on</mat-icon>
            <span>{{companyJson.location}}</span>
          </div>
          <div *ngIf="companyJson.country" class="tw-flex tw-items-center">
            <mat-icon class="tw-mr-2">flag</mat-icon>
            <span>{{companyJson.country}}</span>
          </div>
          <div *ngIf="companyJson.address" class="tw-flex tw-items-center">
            <mat-icon class="tw-mr-2">home</mat-icon>
            <span>{{companyJson.address}}</span>
          </div>
        </div>
      </div>

      <!-- Classification -->
      <div class="tw-bg-gray-50 dark:tw-bg-gray-700 tw-p-4 tw-rounded">
        <h2 class="tw-text-lg tw-font-semibold tw-mb-4 dark:tw-text-white">Classification</h2>
        <div class="tw-space-y-3">
          <div *ngIf="companyJson.naics_codes">
            <span class="tw-font-medium dark:tw-text-gray-200">NAICS Codes:</span>
            <div class="tw-flex tw-flex-wrap tw-gap-2 tw-mt-1">
              <span
                *ngFor="let code of companyJson.naics_codes"
                class="tw-bg-blue-100 dark:tw-bg-blue-900 tw-px-2 tw-py-1 tw-rounded dark:tw-text-blue-100">
                {{code}}
              </span>
            </div>
          </div>
          <div *ngIf="companyJson.sic_codes">
            <span class="tw-font-medium dark:tw-text-gray-200">SIC Codes:</span>
            <div class="tw-flex tw-flex-wrap tw-gap-2 tw-mt-1">
              <span
                *ngFor="let code of companyJson.sic_codes"
                class="tw-bg-blue-100 dark:tw-bg-blue-900 tw-px-2 tw-py-1 tw-rounded dark:tw-text-blue-100">
                {{code}}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- Group Information -->
      <div class="tw-bg-gray-50 dark:tw-bg-gray-700 tw-p-4 tw-rounded">
        <h2 class="tw-text-lg tw-font-semibold tw-mb-4 dark:tw-text-white">Group Information</h2>
        <div class="tw-space-y-3 dark:tw-text-gray-200">
          <div *ngIf="companyJson.group_id" class="tw-flex tw-items-center">
            <mat-icon class="tw-mr-2">group_work</mat-icon>
            <span class="tw-font-medium">Group ID:</span>
            <span class="tw-ml-2"
              ><a
                href="/datawarehouse/companies?id={{companyJson.group_id}}"
                >{{companyJson.group_id}}</a
              ></span
            >
          </div>
          <div
            *ngIf="companyJson.grouped_company_count !== undefined"
            class="tw-flex tw-items-center">
            <mat-icon class="tw-mr-2">corporate_fare</mat-icon>
            <span class="tw-font-medium">Companies in Group:</span>
            <span class="tw-ml-2">{{companyJson.grouped_company_count}}</span>
          </div>
        </div>
      </div>

      <!-- Metadata Section -->
      <div class="tw-bg-gray-50 dark:tw-bg-gray-700 tw-p-4 tw-rounded">
        <h2 class="tw-text-lg tw-font-semibold tw-mb-4 dark:tw-text-white">Metadata</h2>
        <div class="tw-grid tw-grid-cols-2 tw-gap-4 dark:tw-text-gray-200">
          <div *ngIf="companyJson.metadata?.created_at">
            <span class="tw-font-medium">Created:</span>
            <span class="tw-ml-2">{{companyJson.metadata.created_at | date:'medium'}}</span>
          </div>
          <div *ngIf="companyJson.metadata?.updated_at">
            <span class="tw-font-medium">Updated:</span>
            <span class="tw-ml-2">{{companyJson.metadata.updated_at | date:'medium'}}</span>
          </div>
          <div *ngIf="companyJson.metadata?.is_internal !== undefined">
            <span class="tw-font-medium">Internal:</span>
            <span class="tw-ml-2">{{companyJson.metadata.is_internal ? 'Yes' : 'No'}}</span>
          </div>
          <div *ngIf="companyJson.metadata?.lookup">
            <span class="tw-font-medium">Lookup Key:</span>
            <span class="tw-ml-2">{{companyJson.metadata.lookup}}</span>
          </div>
          <div *ngIf="companyJson.metadata?.aliases">
            <span class="tw-font-medium">Aliases:</span>
            <div class="tw-flex tw-flex-wrap tw-gap-2 tw-mt-1">
              <span
                *ngFor="let alias of companyJson.metadata.aliases"
                class="tw-bg-gray-200 dark:tw-bg-gray-600 tw-px-2 tw-py-1 tw-rounded">
                {{alias}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Raw JSON Viewer (Collapsed by Default) -->
    <div class="tw-grid tw-grid-cols-2 tw-gap-3">
      <div class="tw-mt-6">
        <mat-expansion-panel class="dark:tw-bg-gray-700 dark:tw-text-gray-200">
          <mat-expansion-panel-header>
            <mat-panel-title>Company JSON</mat-panel-title>
          </mat-expansion-panel-header>
          <ngx-json-viewer [json]="companyJson" [depth]="3" [expanded]="false"></ngx-json-viewer>
        </mat-expansion-panel>
      </div>
      <div class="tw-mt-6"></div>
    </div>
  </div>
</div>
