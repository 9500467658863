<div>
  <div class="company {{headlineColor}} tw-flex tw-items-center tw-gap-2">
    {{company}}
    <span class="startDate tw-text-gray-800 dark:tw-text-white" *ngIf="jobStartedAt">
      {{jobStartedAt | date:'MMM yyyy'}}
    </span>
    <span class="linkedin" *ngIf="linkedin">
      <a href="https://www.linkedin.com/in/{{linkedin}}" target="_blank">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          style="fill: var(--dynamic-link-color);">
          <path
            d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
        </svg>
      </a>
    </span>
  </div>
  <div class="title">
    <span class="tw-pr-2">{{title}}</span>

    <span *ngIf="email && emailStatus == 'valid'">
      <a class="email tw-text-link-color-dynamic" href="mailto: {{email}}">
        <mat-icon [inline]="true">mark_email_read</mat-icon>
      </a>
    </span>
    <span *ngIf="email && emailStatus == 'catch-all'">
      <a class="email tw-text-link-color-dynamic" href="mailto: {{email}}">
        <mat-icon [inline]="true">email</mat-icon>
      </a>
    </span>
    <span *ngIf="email && emailStatus == 'best-guess'">
      <a class="email tw-text-link-color-dynamic" href="mailto: {{email}}">
        <mat-icon [inline]="true">email</mat-icon>
      </a>
    </span>
  </div>
</div>
