import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DarkModeService {
  // to conditionally display different things based on dark mode status in different components
  private darkModeSubject = new BehaviorSubject<boolean>(false);
  public readonly darkMode$ = this.darkModeSubject.asObservable(); // expose as public observable

  initializeTheme() {
    // Retrieve the stored theme preference
    const storedTheme = localStorage.getItem('color-theme');
    let isDarkMode: boolean;

    if (storedTheme === null) {
      // No stored preference, fallback to system preference
      isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    } else {
      // Check if the stored preference is 'tw-dark'
      isDarkMode = storedTheme === 'tw-dark';
    }

    // Apply the theme to the document
    if (isDarkMode) {
      document.documentElement.classList.add('tw-dark');
    } else {
      document.documentElement.classList.remove('tw-dark');
    }

    // Update the dark mode subject
    this.darkModeSubject.next(isDarkMode);
  }

  toggleTheme() {
    const isCurrentlyDark = document.documentElement.classList.toggle('tw-dark');
    if (isCurrentlyDark) {
      localStorage.setItem('color-theme', 'tw-dark');
    } else {
      localStorage.setItem('color-theme', 'light');
    }

    this.darkModeSubject.next(isCurrentlyDark); // update observable with new mode
  }

  get currentDarkModeValue(): boolean {
    return this.darkModeSubject.getValue();
  }
}
