import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthService } from '../auth/service/auth.service';

@Injectable({ providedIn: 'root' })
export class OrgsGuard  {
    constructor(private auth: AuthService, private router: Router) { }

    async canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (this.auth.getOrgsValue && this.auth.getOrgsValue.length > 0) {
            return true;
        } else {
            return this.router.parseUrl('/invite');
        }
    }
}
