<h2 mat-dialog-title class="tw-text-gray-800 dark:tw-text-gray-100">
  Select Filter Field<span *ngIf="multi">s</span>
</h2>

<mat-dialog-content>
  <div
    class="filter-toolbar tw-flex tw-items-center tw-flex-wrap tw-justify-between tw-gap-4 tw-bg-white dark:tw-bg-gray-800">
    <mat-form-field appearance="outline" class="search-field">
      <mat-label>Find a filter...</mat-label>
      <input
        matInput
        placeholder="Filter name"
        [(ngModel)]="filterText"
        class="tw-font-bold tw-text-link-color-dynamic" />
      <button
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        *ngIf="filterText"
        (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!-- filter count messages -->
    <div class="tw-mb-4 tw-text-sm" *ngIf="!filterText">
      <div>
        Displaying
        <span class="tw-font-bold tw-text-dark-light-dynamic">
          {{getTotalCount()}}
        </span>
        items.
      </div>

      <div *ngIf="multi">
        Total selected items:
        <span class="tw-font-semibold">
          {{ getCheckedCount() }}
        </span>
      </div>
    </div>

    <!-- switches -->
    <div
      class="settings-sliders tw-flex tw-flex-col tw-text-sm tw-absolute tw-right-4 tw-top-4 md:tw-static tw-pl-2 tw-mb-4 tw-ml-auto">
      <mat-slide-toggle [(ngModel)]="classicView" color="primary" class="multi-toggle">
        Classic view
      </mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="showDBName" color="primary" class="multi-toggle">
        Show API names
      </mat-slide-toggle>
    </div>
  </div>

  <!-- Search Panel -->
  <div class="panel-search-results" *ngIf="filterText">
    <p *ngIf="getFilteredTotal() < 1">No matching items. Please adjust your search above.</p>

    <p>
      <span>
        Displaying
        <span *ngIf="filterText" class="tw-font-bold tw-text-dark-light-dynamic">
          {{ getFilteredTotal() }}
        </span>
        <span *ngIf="filterText"> out of </span>
        <span class="tw-font-bold tw-text-dark-light-dynamic">
          {{getTotalCount()}}
        </span>
        items.
      </span>
    </p>

    <div class="filtered-results-container tw-columns-1 md900:tw-columns-2">
      <ng-container *ngFor="let category of displayData | keyvalue">
        <mat-list *ngIf="category.key !== 'default' || !filterText">
          <mat-list-item *ngFor="let field of category.value | filter:filterText:'displayName'">
            <div class="field-category tw-flex tw-items-center">
              <mat-icon class="tw-mr-2 tw-flex tw-items-center tw-text-xl">filter_alt</mat-icon>
              <span class="tw-text-sm tw-font-medium">{{ category.key }}</span>
              <mat-icon>chevron_right</mat-icon>
            </div>
            <div>
              <button
                *ngIf="!multi"
                mat-button
                class="fieldValue tw-text-link-color-dynamic"
                (click)="selectField(field.name)"
                [disabled]="isFieldDisabled(field.name)"
                [ngClass]="{'code-font': showDBName}">
                {{ showDBName ? field.name : field.displayName }}
              </button>
              <mat-checkbox
                class="multi-field"
                *ngIf="multi"
                color="primary"
                [(ngModel)]="returnFields[field.name]"
                [ngClass]="{'code-font': showDBName}">
                {{ showDBName ? field.name : field.displayName }}
              </mat-checkbox>
            </div>
          </mat-list-item>
        </mat-list>
      </ng-container>
    </div>
  </div>

  <!-- Vertical tabs -->
  <div class="panel-vertical-tabs" *ngIf="!filterText && !classicView">
    <mat-tab-group>
      <!-- favorites -->
      <mat-tab *ngIf="!multi && data.categories.length > 1">
        <ng-template mat-tab-label>
          <mat-icon class="tw-text-[14px] tw-w-[14px] tw-h-[14px] tw-mr-2">star</mat-icon>
          <span>Favorites</span>
          <mat-icon class="tw-ml-auto">chevron_right</mat-icon>
        </ng-template>
        <mat-list>
          <mat-list-item
            (click)="toggleField(field.name)"
            *ngFor="let field of popularFilters | filter:filterText:'displayName'"
            [ngClass]="{'checked-item': multi && returnFields[field.name]}">
            <button
              *ngIf="!multi"
              mat-button
              class="fieldValue tw-text-link-color-dynamic"
              (click)="selectField(field.name)"
              [disabled]="isFieldDisabled(field.name)"
              [ngClass]="{'code-font': showDBName}">
              {{ showDBName ? field.name : field.displayName }}
            </button>
            <mat-checkbox
              class="multi-field"
              *ngIf="multi"
              color="primary"
              [(ngModel)]="returnFields[field.name]"
              (click)="$event.stopPropagation()"
              [ngClass]="{'code-font': showDBName}">
              {{ showDBName ? field.name : field.displayName }}
            </mat-checkbox>
          </mat-list-item>
        </mat-list>
      </mat-tab>

      <!-- category tabs -->
      <mat-tab *ngFor="let category of categories">
        <ng-template mat-tab-label>
          <span>{{category.displayName}}</span>
          <mat-icon
            matTooltip="{{category.description}}"
            matTooltipPosition="right"
            class="tooltip-icon tw-ml-1 tw-mr-auto tw-text-base">
            info_outline
          </mat-icon>
          <span class="tw-mr-2 badge-checked-items" *ngIf="multi">
            {{ getCheckedCount(category.name) }}
          </span>
          <span class="tw-font-bold">{{ displayData[category.name].length }}</span>
          <mat-icon>chevron_right</mat-icon>
        </ng-template>

        <!-- tabs content -->
        <mat-list
          class="tw-mb-2"
          [ngClass]="{'mdx:tw-columns-2': displayData[category.name].length > 12}">
          <!-- select / unselect -->
          <mat-list-item *ngIf="multi" class="fields-bulk-options tw-h-12">
            <div class="select-all tw-flex tw-items-center">
              <button
                mat-stroked-button
                (click)="selectAll(category.name)"
                class="tw-text-sm tw-ml-4 tw-mr-3">
                Select All
              </button>
              <span class="tw-font-light"> | </span>
              <button
                mat-stroked-button
                (click)="deselectAll(category.name)"
                class="tw-text-sm tw-ml-4 tw-mr-3">
                Unselect All
              </button>
              <span class="tw-font-light"> | </span>
              <p class="tw-text-xs lg:tw-text-sm tw-ml-4">
                Total Selected:
                <span class="tw-font-bold tw-text-link-color-dynamic">
                  {{ getCheckedCount(category.name) }}
                </span>
              </p>
            </div>
          </mat-list-item>

          <div class="field-selection-items">
            <ng-container *ngFor="let subcategory of getSubcategories(category.name)">
              <div
                class="tw-mb-4"
                *ngIf="displayDataBySubcategory[category.name][subcategory.name]?.length">
                <h3 class="tw-flex tw-font-medium tw-text-dark-light-dynamic tw-my-2 tw-ml-4">
                  {{subcategory.displayName}}
                  <mat-icon
                    *ngIf="subcategory.description"
                    matTooltip="{{subcategory.description}}"
                    matTooltipPosition="right"
                    class="tooltip-icon tw-ml-1 tw-text-base">
                    info_outline
                  </mat-icon>
                </h3>

                <div>
                  <mat-list-item
                    (click)="toggleField(field.name)"
                    *ngFor="let field of displayDataBySubcategory[category.name][subcategory.name] | filter:filterText:'displayName'"
                    [ngClass]="{'checked-item': multi && returnFields[field.name]}">
                    <button
                      *ngIf="!multi"
                      mat-button
                      class="fieldValue tw-text-link-color-dynamic"
                      (click)="selectField(field.name)"
                      [disabled]="isFieldDisabled(field.name)"
                      [ngClass]="{'code-font': showDBName}">
                      {{ showDBName ? field.name : field.displayName }}
                    </button>
                    <mat-checkbox
                      class="multi-field"
                      *ngIf="multi"
                      color="primary"
                      [(ngModel)]="returnFields[field.name]"
                      (click)="$event.stopPropagation()"
                      [ngClass]="{'code-font': showDBName}">
                      {{ showDBName ? field.name : field.displayName }}
                    </mat-checkbox>
                  </mat-list-item>
                </div>
              </div>
            </ng-container>

            <!-- Other fields without subcategory -->
            <div class="tw-mb-4" *ngIf="displayDataBySubcategory[category.name]['other']?.length">
              <h3 class="tw-font-medium tw-text-dark-light-dynamic tw-my-2 tw-ml-4">Other</h3>
              <div>
                <mat-list-item
                  (click)="toggleField(field.name)"
                  *ngFor="let field of displayDataBySubcategory[category.name]['other'] | filter:filterText:'displayName'"
                  [ngClass]="{'checked-item': multi && returnFields[field.name]}">
                  <button
                    *ngIf="!multi"
                    mat-button
                    class="fieldValue tw-text-link-color-dynamic"
                    (click)="selectField(field.name)"
                    [disabled]="isFieldDisabled(field.name)"
                    [ngClass]="{'code-font': showDBName}">
                    {{ showDBName ? field.name : field.displayName }}
                  </button>
                  <mat-checkbox
                    class="multi-field"
                    *ngIf="multi"
                    color="primary"
                    [(ngModel)]="returnFields[field.name]"
                    (click)="$event.stopPropagation()"
                    [ngClass]="{'code-font': showDBName}">
                    {{ showDBName ? field.name : field.displayName }}
                  </mat-checkbox>
                </mat-list-item>
              </div>
            </div>
          </div>
        </mat-list>
      </mat-tab>
    </mat-tab-group>
  </div>

  <!-- Classic view -->
  <div *ngIf="classicView && !filterText" class="classic-view tw-flex tw-flex-wrap tw-gap-10">
    <div *ngFor="let columnNum of getAllCategoryColumns()" class="tw-flex-1">
      <div *ngFor="let category of getCategoriesForColumn(columnNum)" class="tw-mt-3 tw-mb-5">
        <h3 class="tw-text-dark-light-dynamic tw-font-bold tw-text-lg tw-my-0">
          {{category.displayName}}
        </h3>

        <!-- Select All/Unselect All buttons -->
        <mat-list>
          <mat-list-item *ngIf="multi" class="fields-bulk-options tw-h-8 tw-my-1">
            <div class="select-all tw-flex tw-items-center tw-pb-2">
              <button
                mat-stroked-button
                (click)="selectAll(category.name)"
                class="tw-text-sm tw-mr-3">
                Select All
              </button>
              <span class="tw-font-light"> | </span>
              <button
                mat-stroked-button
                (click)="deselectAll(category.name)"
                class="tw-text-sm tw-ml-4 tw-mr-3">
                Unselect All
              </button>
            </div>
          </mat-list-item>
        </mat-list>

        <!-- Subcategories -->
        <ng-container *ngFor="let subcategory of getSubcategories(category.name)">
          <div
            *ngIf="displayDataBySubcategory[category.name][subcategory.name]?.length"
            class="tw-mb-4">
            <h4
              class="tw-font-medium tw-text-sm tw-flex tw-items-center tw-mb-2 tw-mt-0 tw-text-dark-light-dynamic">
              {{subcategory.displayName}}
              <mat-icon
                *ngIf="subcategory.description"
                matTooltip="{{subcategory.description}}"
                matTooltipPosition="right"
                class="tooltip-icon tw-ml-1 tw-text-base">
                info_outline
              </mat-icon>
            </h4>
            <mat-list class="tw-mb-2">
              <mat-list-item
                class="tw-h-6 tw-text-sm"
                (click)="toggleField(field.name)"
                *ngFor="let field of displayDataBySubcategory[category.name][subcategory.name] | filter:filterText:'displayName'"
                [ngClass]="{'checked-item': multi && returnFields[field.name]}">
                <button
                  *ngIf="!multi"
                  mat-button
                  class="fieldValue tw-text-link-color-dynamic tw-leading-6 tw-px-2"
                  (click)="selectField(field.name)"
                  [disabled]="isFieldDisabled(field.name)"
                  [ngClass]="{'code-font': showDBName}">
                  {{ showDBName ? field.name : field.displayName }}
                </button>
                <mat-checkbox
                  class="multi-field"
                  *ngIf="multi"
                  color="primary"
                  [(ngModel)]="returnFields[field.name]"
                  (click)="$event.stopPropagation()"
                  [ngClass]="{'code-font': showDBName}">
                  {{ showDBName ? field.name : field.displayName }}
                </mat-checkbox>
              </mat-list-item>
            </mat-list>
          </div>
        </ng-container>

        <!-- Other fields without subcategory -->
        <div *ngIf="displayDataBySubcategory[category.name]['other']?.length" class="tw-mb-4">
          <div
            *ngIf="getSubcategories(category.name).length > 0"
            class="tw-font-medium tw-text-sm tw-mb-2 tw-text-dark-light-dynamic">
            Other
          </div>
          <mat-list>
            <mat-list-item
              class="tw-h-6 tw-text-sm"
              (click)="toggleField(field.name)"
              *ngFor="let field of displayDataBySubcategory[category.name]['other'] | filter:filterText:'displayName'"
              [ngClass]="{'checked-item': multi && returnFields[field.name]}">
              <button
                *ngIf="!multi"
                mat-button
                class="fieldValue tw-text-link-color-dynamic tw-leading-6 tw-px-2"
                (click)="selectField(field.name)"
                [disabled]="isFieldDisabled(field.name)"
                [ngClass]="{'code-font': showDBName}">
                {{ showDBName ? field.name : field.displayName }}
              </button>
              <mat-checkbox
                class="multi-field"
                *ngIf="multi"
                color="primary"
                [(ngModel)]="returnFields[field.name]"
                (click)="$event.stopPropagation()"
                [ngClass]="{'code-font': showDBName}">
                {{ showDBName ? field.name : field.displayName }}
              </mat-checkbox>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="tw-px-4 tw-py-4">
  <button mat-stroked-button (click)="cancel()" class="dark:tw-text-gray-100">Cancel</button>
  <button
    *ngIf="multi"
    mat-flat-button
    (click)="saveSelections()"
    color="primary"
    class="tw-ml-auto">
    Save Selections <span *ngIf="multi">({{ getCheckedCount() }})</span>
  </button>
</mat-dialog-actions>
