<div class="container">
  <div class="actionContainer">
    <mat-card *ngIf="primaryRow">
      <mat-card-title
        >Primary: <span class="primaryName">{{primaryRow.name}}</span></mat-card-title
      >
      <button mat-raised-button color="warn" (click)="resetForm()">Reset</button>
    </mat-card>
    <mat-card>
      <mat-card-title>Search:</mat-card-title>
      <form [formGroup]="inputFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Company Name</mat-label>
          <input
            matInput
            placeholder="Company Name"
            formControlName="ref"
            [value]="inputFormGroup.value.ref" />
        </mat-form-field>
        <button
          type="submit"
          mat-raised-button
          [disabled]="!inputFormGroup.valid"
          color="primary"
          (click)="searchCompanyName()">
          Search
        </button>
        <button
          mat-raised-button
          [disabled]="!canSetAsPrimary()"
          color="primary"
          (click)="setAsPrimary()">
          Set as Primary
        </button>
        <button
          mat-raised-button
          [disabled]="!canCreateAlias()"
          color="primary"
          (click)="createAlias()">
          Create Alias
        </button>
        <button
          mat-raised-button
          [disabled]="!canDeleteAliasFrom()"
          color="warn"
          (click)="deleteAliasFrom()">
          Delete Alias From
        </button>
        <button
          mat-raised-button
          [disabled]="!canDeleteAliasTo()"
          color="warn"
          (click)="deleteAliasTo()">
          Delete all Aliases To
        </button>
      </form>
    </mat-card>
  </div>
  <div *ngIf="creatingAliases">
    <mat-progress-bar mode="indeterminate">Creating aliases...</mat-progress-bar>
  </div>
  <ag-grid-angular
    *ngIf="!searching && (rowData.length > 0)"
    style="height: 100%;"
    [ngClass]="{ 'ag-theme-alpine': !(darkModeService.darkMode$ | async), 'ag-theme-alpine-dark': darkModeService.darkMode$ | async }"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [getRowId]="getRowId"
    [components]="gridComponents"
    [defaultColDef]="defaultColDef"
    [tooltipShowDelay]="0"
    [tooltipHideDelay]="2000"
    rowSelection="multiple"
    suppressRowClickSelection="true"
    suppressCellSelection="true"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    (gridReady)="onGridReady($event)">
  </ag-grid-angular>
  <div *ngIf="searching" class="loading-container">
    <div class="loading">
      <mat-spinner diameter="60"></mat-spinner>
      <p class="loading-message">Searching...</p>
    </div>
  </div>
</div>
