import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISpace } from '@flatfile/angular-sdk';
import { SpaceService } from '@flatfile/angular-sdk';
import { workbookConfig } from '../workbook';
import FlatfileListener from '@flatfile/listener';
import { recordHook, FlatfileRecord } from '@flatfile/plugin-record-hook';
import api from '@flatfile/api';
import { ImportFlatfileJobRequest, LedgerContactsService } from 'ldt-ledger-service-api-v2';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../../shared/notification-service/notification.service';
import { AuthService } from '../../auth/service/auth.service';

@Component({
  selector: 'app-flatfile-uploader',
  template: `
    <div class="spaceWrapper">
      <flatfile-space [spaceProps]="spaceProps"></flatfile-space>
    </div>
  `,
})
export class FlatfileUploaderComponent {
  @Input() orgId: string;
  @Input() ledgerId: string;
  @Input() showSpace: boolean = false;
  @Output() closeSpaceEvent = new EventEmitter<void>();
  @Output() uploadCompleteEvent = new EventEmitter<void>();

  listener = FlatfileListener.create((listener) => {
    listener.filter({ job: 'workbook:submitAction' }, (configure) => {
      configure.on('job:ready', async ({ context: { jobId, workbookId } }) => {
        try {
          await api.jobs.complete(jobId);
          await api.jobs.ackOutcome(jobId);

          const req: ImportFlatfileJobRequest = {
            flatfile_workbook_id: workbookId,
          };
          this.ledgerContacts.importFlatfileJob(this.orgId, this.ledgerId, req).subscribe({
            next: async () => {
              this.notify.success(
                'Your contacts have been uploaded. It may take some time for them to appear in your ledger.'
              );
              this.uploadCompleteEvent.emit();
            },
            error: async () => {
              this.notify.error(
                'There was an error importing your contacts. Please try again later.'
              );
            },
          });
        } catch (error: any) {
          this.notify.error('There was an error importing your contacts. Please try again later.');
          this.closeSpace();
        }
      });
    });
    listener.use(
      recordHook('ledger_contacts', (record: FlatfileRecord) => {
        record.validate(
          'reference_id',
          (value) => !value || (typeof value === 'string' && !/^(LDLC|LDP).*/.test(value)),
          "Reference IDs may not begin with 'LDLC' or 'LDP'"
        );
        return record;
      })
    );
  });

  spaceProps: ISpace;

  constructor(
    private ledgerContacts: LedgerContactsService,
    private notify: NotificationService,
    private auth: AuthService,
    private spaceService: SpaceService
  ) {
    this.initSpaceProps();
    this.openSpace();
  }

  private initSpaceProps() {
    this.spaceProps = {
      name: 'Ledger upload',
      environmentId: environment.flatfileEnvironmentId,
      publishableKey: environment.flatfilePublishableKey,
      workbook: workbookConfig,
      listener: this.listener,
      closeSpace: {
        operation: 'submitAction',
        onClose: this.closeSpace.bind(this),
      },
      userInfo: {
        userId: this.auth.getUserValue.id || '',
        name: this.auth.getUserValue.name,
        companyId: this.auth.getSelectedOrgIdValue,
        companyName: this.auth.getSelectedOrg()?.name || '',
      },
      displayAsModal: true,
      iframeStyles: {
        'margin-left': '232px',
      },
    };
  }

  closeSpace() {
    this.closeSpaceEvent.emit();
  }

  openSpace() {
    this.spaceService.OpenEmbed();
  }
}
