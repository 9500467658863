import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface BadgeValue {
  value: string;
  displayValue: string;
  showImage?: boolean;
}

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styles: [
    `
    .badge-container {
      @apply tw-rounded-sm tw-leading-4 tw-py-1 tw-pl-2 tw-pr-1;
    }
  `,
  ],
})

// Dark-gray or Light-gray badge
export class BadgeComponent {
  @Input() value: string | BadgeValue;
  @Input() color: 'dark' | 'light' = 'dark'; // Default to dark
  @Output() remove = new EventEmitter<string>();

  get displayValue(): string {
    return typeof this.value === 'string' ? this.value : this.value.displayValue;
  }

  get showImage(): boolean {
    return typeof this.value !== 'string' && !!this.value.showImage;
  }

  /**
   * Emits an event to remove the badge.
   */
  removeBadge() {
    if (typeof this.value === 'string') {
      this.remove.emit(this.value);
      return;
    }

    this.remove.emit((this.value as BadgeValue).value);
  }
}
