// highcharts-themes.ts
export const lightTheme = {
  chart: {
    backgroundColor: 'var(--dark-white-dynamic-bg)',
    style: {
      fontFamily: 'SF UI Text, sans-serif',
    },
  },
  title: {
    style: {
      color: 'var(--dark-white-dynamic-text)',
    },
  },
  xAxis: {
    lineColor: 'var(--dark-white-dynamic-text)',
    labels: {
      style: {
        color: 'var(--dark-white-dynamic-text)',
      },
    },
  },
  yAxis: {
    gridLineColor: 'var(--dynamic-chart-grid-lines)',
    labels: {
      style: {
        color: 'var(--dark-white-dynamic-text)',
      },
    },
    title: {
      style: {
        color: 'var(--dark-white-dynamic-text)',
      },
    },
  },
  legend: {
    itemStyle: {
      color: 'var(--dark-white-dynamic-text)',
    },
    itemHoverStyle: {
      color: 'var(--dynamic-chart-legend-hover)',
    },
  },
  exporting: {
    // export buttons
    buttons: {
      contextButton: {
        symbolStroke: 'var(--dark-light-dynamic-text)', // Icon color
        symbolFill: 'var(--dark-white-dynamic-bg)', // Background for the symbol
        theme: {
          fill: 'var(--dark-white-dynamic-bg)', // Button background
          style: {
            color: 'var(--dark-light-dynamic-text)', // Text color
          },
          states: {
            hover: {
              fill: 'var(--dynamic-hover-bg)', // Hover background color
              style: {
                color: 'var(--dark-white-dynamic-text)', // Hover text color
              },
            },
          },
        },
      },
    },
  },
};

export const darkTheme = {
  chart: {
    backgroundColor: 'var(--dark-white-dynamic-bg)',
    style: {
      fontFamily: 'SF UI Text, sans-serif',
    },
  },
  title: {
    style: {
      color: 'var(--dark-white-dynamic-text)',
    },
  },
  xAxis: {
    lineColor: 'var(--dark-white-dynamic-text)',
    labels: {
      style: {
        color: 'var(--dark-white-dynamic-text)',
      },
    },
  },
  yAxis: {
    gridLineColor: 'var(--dynamic-chart-grid-lines)',
    labels: {
      style: {
        color: 'var(--dark-white-dynamic-text)',
      },
    },
    title: {
      style: {
        color: 'var(--dark-white-dynamic-text)',
      },
    },
  },
  legend: {
    itemStyle: {
      color: 'var(--dark-white-dynamic-text)',
    },
    itemHoverStyle: {
      color: 'var(--dynamic-chart-legend-hover)',
    },
  },
  exporting: {
    // export buttons
    buttons: {
      contextButton: {
        symbolStroke: 'var(--dark-light-dynamic-text)', // Icon color
        symbolFill: 'var(--dark-white-dynamic-bg)', // Background for the symbol
        theme: {
          fill: 'var(--dark-white-dynamic-bg)', // Button background
          style: {
            color: 'var(--dark-light-dynamic-text)', // Text color
          },
          states: {
            hover: {
              fill: 'var(--dynamic-hover-bg)', // Hover background color
              style: {
                color: 'var(--dark-white-dynamic-text)', // Hover text color
              },
            },
          },
        },
      },
    },
    // popup menu styles are in styles.scss, not here
  },
};
