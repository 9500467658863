<mat-dialog-content>
  <div class="notification">Service Account credentials now available</div>
  <div class="warning tw-text-orange-600">
    This is the only time the client secret will be revealed.
  </div>
  <table>
    <tr *ngFor="let item of data | keyvalue">
      <td class="fieldName">{{item.key}}</td>
      <td class="fieldValue">
        {{item.value}}
        <button ngxClipboard cbContent="{{item.value}}">
          <mat-icon class="tw-text-dark-light-dynamic">content_copy</mat-icon>
        </button>
      </td>
    </tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="close()">Close</button>
</mat-dialog-actions>
