import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Invitation, InvitationsService, UpdateInvitationRequest } from 'ldt-identity-service-api';
import { ColDef, GridApi } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { BtnCellRenderer } from 'src/app/shared/ag-grid-button/button-cell-renderer.component';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { AuthService } from '../service/auth.service';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss'],
})
export class InvitationsComponent implements OnInit {
  /// AG -GRID --------------
  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true,
  };
  defaultColDef: ColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    filterParams: this.simpleFilterParams,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  columnDefs: ColDef[] = [
    { field: 'id', headerName: 'ID', cellRenderer: 'loadingRenderer', maxWidth: 150 },
    { field: 'invitedBy' },
    { field: 'invitedByEmail' },
    { field: 'org.name' },
    { field: 'inviteeRole', maxWidth: 150, filter: true },
    { field: 'createdAt', sort: 'desc' },
    { field: 'expiresAt' },
    {
      headerName: 'Accept',
      filter: false,
      maxWidth: 150,
      sortable: false,
      cellRenderer: BtnCellRenderer,
      cellRendererParams: {
        onClick: this.acceptInvitation.bind(this),
        label: 'Accept',
      },
    },
    {
      headerName: 'Decline',
      filter: false,
      maxWidth: 150,
      sortable: false,
      cellRenderer: BtnCellRenderer,
      cellRendererParams: {
        onClick: this.declineInvitation.bind(this),
        label: 'Decline',
      },
    },
  ];
  rowData: Invitation[];
  rowSelection: string = 'multiple';

  private gridApi: GridApi;
  role: string;

  components = {
    loadingRenderer: function (params: any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
      }
    },
  };
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  inviteToken: string;

  constructor(
    private invService: InvitationsService,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private auth: AuthService,
    public darkModeService: DarkModeService
  ) {
    this.route.queryParams.subscribe((params) => {
      if ('inviteToken' in params) {
        this.inviteToken = params.inviteToken;
        this.auth.validateUserEmail(this.inviteToken);
      }
    });
  }

  orgs: any = [];

  ngOnInit() {
    this.refreshUserInvitations();
    if (this.inviteToken) {
      this.invService.getInvitationByToken(this.inviteToken).subscribe(
        (r) => {
          if (r.inviteeEmail != this.auth.email) {
            this.notify.error(
              'Invitation token not valid for this user. It can only be accepted by a user with the email address ' +
                r.inviteeEmail,
              30000
            );
          }
        },
        (err) => {
          if (err.status && err.status === 404) {
            this.notify.error('Invalid invitation token');
          } else if (err.status && err.status === 409) {
            this.notify.error('Invitation can no longer be accepted');
          } else {
            this.notify.error(
              'Oops. There was an error during your request. Please try again later.'
            );
          }
        }
      );
    }
    this.auth.$getOrgs.subscribe((r) => {
      this.orgs = this.auth.getOrgsValue || [];
    });
  }

  refreshUserInvitations() {
    this.invService.getUserInvitations(this.auth.getUserValue.id).subscribe(
      (r) => {
        this.rowData = r;
      },
      () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
        this.rowData = [];
      }
    );
  }

  acceptInvitation(e: any) {
    let inv: Invitation = e.rowData;
    let body: UpdateInvitationRequest = {
      resolve: UpdateInvitationRequest.ResolveEnum.Accept,
    };
    this.invService.updateInvitation(inv.id, inv.org.id, body).subscribe(
      (r) => {
        this.notify.success('Invitation accepted.');
        this.auth.refreshSession().subscribe((r) => {
          this.refreshUserInvitations();
        });
      },
      () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
      }
    );
  }

  declineInvitation(e: any) {
    let inv: Invitation = e.rowData;
    let body: UpdateInvitationRequest = {
      resolve: UpdateInvitationRequest.ResolveEnum.Decline,
    };
    this.invService.updateInvitation(inv.id, inv.org.id, body).subscribe(
      (r) => {
        this.notify.success('Invitation declined.');
        this.refreshUserInvitations();
      },
      () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
      }
    );
  }

  rowsSelected: boolean = false;
  onSelectionChanged(event: any) {
    var selectedRows = this.gridApi.getSelectedRows();
    this.rowsSelected = selectedRows.length !== 0;
  }
}
