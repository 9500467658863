<form [formGroup]="form">
  <mat-form-field appearance="outline" floatLabel="always" class="job-function-dropdown tw-w-full">
    <mat-label class="tw-text-gray-800 dark:tw-text-white">
      Job Function{{multiSelect ? 's': ''}}
    </mat-label>
    <mat-select
      formControlName="selectedFunctions"
      [multiple]="multiSelect"
      [value]="preSelectedJobFunctions"
      (closed)="onDropdownClosed()"
      panelClass="job-function-dropdown-panel"
      placeholder="All">
      <mat-select-trigger>
        {{ getCollapsedStringValue() }}
      </mat-select-trigger>

      <div *ngIf="multiSelect" class="tw-flex tw-justify-between tw-mb-2 tw-px-1 xl:tw-px-2">
        <button mat-button (click)="selectAll()" [disabled]="areAllSelected()">Select All</button>
        <button mat-button (click)="unselectAll()" [disabled]="areNoneSelected()">
          Unselect All
        </button>
      </div>

      <div class="tw-w-full md:tw-columns-2">
        <mat-option
          *ngFor="let item of availableJobFunctions"
          [value]="item"
          color="accent"
          class="tw-text-xs md:tw-text-sm tw-border-0 tw-border-solid tw-border-gray-300 tw-border-b">
          {{ item }}
        </mat-option>
      </div>
    </mat-select>
  </mat-form-field>
</form>
